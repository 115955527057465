<template>
  <v-container class="pa-0" fluid>
    <v-row class="title-container" dense no-gutters>
      <v-col style="display: flex; align-items: center">
        <h1 class="title-text">併用分析</h1>
        <update-badge type="month" style="margin-left: 15px" />
      </v-col>
      <v-spacer />
      <monthly-date-picker
        :start-date="startMonth"
        :end-date="endMonth"
        @update-period="updatePeriod"
      />
    </v-row>
    <v-row dense no-gutters class="card-container">
      <v-col>
        <chain-selector
          :initial-chain-id="chainId"
          :initial-prefecture-ids="prefectureIds.map((id) => Number(id))"
          :start-date="convertHyphenDelimiter(startMonth)"
          :end-date="convertHyphenDelimiter(endMonth)"
          :handle-update="
            (chain, prefectureIds) =>
              setParams({
                chainId: chain.id,
                prefectureIds: prefectureIds.map((id) => id.toString())
              })
          "
        />
      </v-col>
    </v-row>
    <v-row dense no-gutters class="card-container">
      <v-col style="position: relative">
        <v-card class="card" tile>
          <v-card-title class="pa-0 d-flex align-center card-title">
            <span>併用率ランキング（全体）</span>
            <AlertTooltip
              v-if="hasAlert && totalCombinationChains.length !== 0"
              class="ml-2"
              text="取得データボリュームが少なく、統計上の信頼性の低いデータが含まれています。該当箇所は、参考値としてご参照ください。"
            />
            <v-spacer />
            <ChartDescriptionTooltip
              menu-key="chainAnalytics"
              sub-menu-key="combination"
              chart-key="combinationShareMapRanking"
              class="mr-7"
            />
            <DownloadButton
              label="CSVデータ"
              :disabled="!chainId"
              :get-file-id="
                () =>
                  downloadCombinationAnalysisChart(
                    chainId,
                    prefectureIds.map((id) => Number(id)),
                    startMonth,
                    endMonth,
                    undefined
                  )
              "
              :csv-name="`チェーン分析_併用率ランキング_
              ${convertSlashDelimiter(startMonth)}-${convertSlashDelimiter(endMonth)}`"
            />
          </v-card-title>
          <div v-if="!chainId" class="unselected_card">
            チェーンを選択するとチャートが表示されます。
          </div>
          <div v-else>
            <loading-img v-if="totalLoading" :height="'570px'" />
            <no-data-chain-chart v-else-if="totalCombinationChains.length === 0" />
            <combination-table
              v-else
              :type="'total'"
              :base-chain="selectedChain"
              :items="totalCombinationChains"
              :sort-by="totalSortKey"
              :sort-desc="totalSortDesc"
              @change-sort-key="sortTotalData"
            />
          </div>
          <v-row class="mt-9 mb-0 pa-0">
            <v-col cols="6" class="pt-0 pb-0 pl-0 padding_right_15">
              <v-card-title class="pa-0 d-flex align-center card-title mb-4">
                <span>併用率ランキング（男性）</span>
                <AlertTooltip
                  v-if="hasAlert && maleCombinationChains.length !== 0"
                  class="ml-2"
                  text="取得データボリュームが少なく、統計上の信頼性の低いデータが含まれています。該当箇所は、参考値としてご参照ください。"
                />
              </v-card-title>
              <div v-if="!chainId" class="unselected_card">
                チェーンを選択するとチャートが表示されます。
              </div>
              <div v-else>
                <loading-img v-if="maleLoading" :height="'570px'" />
                <no-data-chain-chart v-else-if="maleCombinationChains.length === 0" />
                <combination-table
                  v-else
                  :type="'male'"
                  :base-chain="selectedChain"
                  :items="maleCombinationChains"
                  :sort-by="maleSortKey"
                  :sort-desc="maleSortDesc"
                  @change-sort-key="sortMaleData"
                />
              </div>
            </v-col>
            <v-col cols="6" class="pt-0 pb-0 pr-0 padding_left_15">
              <v-card-title class="pa-0 d-flex align-center card-title mb-4">
                <span>併用率ランキング（女性）</span>
                <AlertTooltip
                  v-if="hasAlert && femaleCombinationChains.length !== 0"
                  class="ml-2"
                  text="取得データボリュームが少なく、統計上の信頼性の低いデータが含まれています。該当箇所は、参考値としてご参照ください。"
                />
              </v-card-title>
              <div v-if="!chainId" class="unselected_card">
                チェーンを選択するとチャートが表示されます。
              </div>
              <div v-else>
                <loading-img v-if="femaleLoading" :height="'570px'" />
                <no-data-chain-chart v-else-if="femaleCombinationChains.length === 0" />
                <combination-table
                  v-else
                  :type="'female'"
                  :base-chain="selectedChain"
                  :items="femaleCombinationChains"
                  :sort-by="femaleSortKey"
                  :sort-desc="femaleSortDesc"
                  @change-sort-key="sortFemaleData"
                />
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import UpdateBadge from '@/commons/components/Elements/UpdateBadge.vue'
import MonthlyDatePicker from '@/commons/components/DatePicker/MonthlyDatePicker.vue'
import ChainSelector from '@/commons/components/ChainSelector/ChainSelector.vue'
import NoDataChainChart from '@/commons/components/Chart/NoDataChainChart.vue'
import loadingImg from '@/commons/components/loadingImg.vue'
import CombinationTable from '@/features/ChainAnalytics/combination/components/CombinationTable.vue'
import { Chain } from '@/commons/interfaces'
import { useStore } from 'vuex'
import { convertSlashDelimiter } from '@/commons/utils/dateUtil'
import useChainQueryParams from '@/commons/hooks/use-chain-query-params'
import usePeriodQueryParams from '@/commons/hooks/use-period-query-params'
import {
  getCombinationAnalysisChart,
  downloadCombinationAnalysisChart
} from '@/features/ChainAnalytics/combination/axios'
import {
  CombinationChainResponse,
  CombinationChainItem
} from '@/features/ChainAnalytics/combination/interfaces'
import { GENDER } from '@/commons/enums'
import { AxiosResponse } from 'axios'
import { notifyErrorMessage } from '@/plugins/notification'
import { useRoute } from 'vue-router'
import { getPeriodByRouterQueryMonthPeriod } from '@/commons/utils'
import { convertHyphenDelimiter } from '@/commons/utils/dateUtil'

const route = useRoute()
const store = useStore()
const chains = computed<Chain[]>(() => store.getters.chains)
const startMonth = computed<string>(() => store.state.startMonth)
const endMonth = computed<string>(() => store.state.endMonth)

const { chainId, prefectureIds, setParams } = useChainQueryParams()
const { setPeriodParams } = usePeriodQueryParams()
const selectedChain = computed<Chain | undefined>(() =>
  chains.value.find((chain) => chain.id === chainId.value)
)
const updatePeriod = ({ startMonth, endMonth }: { startMonth: string; endMonth: string }) => {
  setPeriodParams({ startMonth, endMonth })
}

type CombinationSortKey = undefined | 'chainType' | 'combinationRatio'

const totalCombinationChains = ref<CombinationChainItem[]>([])
const totalLoading = ref<boolean>(false)
const hasAlert = ref<boolean>(false)
const fetchTotalCombinationChains = async () => {
  totalLoading.value = true
  totalCombinationChains.value.splice(0, 0)
  try {
    totalCombinationChains.value = await getCombinationAnalysisChart(
      chainId.value,
      prefectureIds.value.map((id) => Number(id)),
      startMonth.value,
      endMonth.value,
      undefined
    ).then((res: AxiosResponse<CombinationChainResponse>) => {
      hasAlert.value = res.data.isAlert
      return res.data.combinationChains
    })
  } catch {
    notifyErrorMessage('併用率ランキング（全体）が表示出来ませんでした')
  } finally {
    totalLoading.value = false
  }
}
const totalSortKey = ref<CombinationSortKey>(undefined)
const totalSortDesc = ref<boolean>(false)
const sortTotalData = (sortKey: CombinationSortKey) => {
  if (totalSortKey.value === sortKey) totalSortDesc.value = !totalSortDesc.value
  else {
    totalSortKey.value = sortKey
    totalSortDesc.value = false
  }
  totalCombinationChains.value = totalCombinationChains.value.sort(
    (a: CombinationChainItem, b: CombinationChainItem) => {
      if (sortKey === 'chainType') {
        if (totalSortDesc.value) return a.storeType < b.storeType ? 1 : -1
        else return a.storeType > b.storeType ? 1 : -1
      } else {
        if (totalSortDesc.value) return a.combinationRatio < b.combinationRatio ? 1 : -1
        else return a.combinationRatio > b.combinationRatio ? 1 : -1
      }
    }
  )
}

const maleCombinationChains = ref<CombinationChainItem[]>([])
const maleLoading = ref<boolean>(false)
const fetchMaleCombinationChains = async () => {
  maleLoading.value = true
  maleCombinationChains.value.splice(0, 0)
  try {
    maleCombinationChains.value = await getCombinationAnalysisChart(
      chainId.value,
      prefectureIds.value.map((id) => Number(id)),
      startMonth.value,
      endMonth.value,
      GENDER.MALE.value
    ).then((res: AxiosResponse<CombinationChainResponse>) => res.data.combinationChains)
  } catch {
    notifyErrorMessage('併用率ランキング（男性）が表示出来ませんでした')
  } finally {
    maleLoading.value = false
  }
}
const maleSortKey = ref<CombinationSortKey>(undefined)
const maleSortDesc = ref<boolean>(false)
const sortMaleData = (sortKey: CombinationSortKey) => {
  if (maleSortKey.value === sortKey) maleSortDesc.value = !maleSortDesc.value
  else {
    maleSortKey.value = sortKey
    maleSortDesc.value = false
  }
  maleCombinationChains.value = maleCombinationChains.value.sort(
    (a: CombinationChainItem, b: CombinationChainItem) => {
      if (sortKey === 'chainType') {
        if (maleSortDesc.value) return a.storeType < b.storeType ? 1 : -1
        else return a.storeType > b.storeType ? 1 : -1
      } else {
        if (maleSortDesc.value) return a.combinationRatio < b.combinationRatio ? 1 : -1
        else return a.combinationRatio > b.combinationRatio ? 1 : -1
      }
    }
  )
}

const femaleCombinationChains = ref<CombinationChainItem[]>([])
const femaleLoading = ref<boolean>(false)
const fetchFemaleCombinationChains = async () => {
  femaleLoading.value = true
  femaleCombinationChains.value.splice(0, 0)
  try {
    femaleCombinationChains.value = await getCombinationAnalysisChart(
      chainId.value,
      prefectureIds.value.map((id) => Number(id)),
      startMonth.value,
      endMonth.value,
      GENDER.FEMALE.value
    ).then((res: AxiosResponse<CombinationChainResponse>) => res.data.combinationChains)
  } catch {
    notifyErrorMessage('併用率ランキング（女性）が表示出来ませんでした')
  } finally {
    femaleLoading.value = false
  }
}
const femaleSortKey = ref<CombinationSortKey>(undefined)
const femaleSortDesc = ref<boolean>(false)
const sortFemaleData = (sortKey: CombinationSortKey) => {
  if (femaleSortKey.value === sortKey) femaleSortDesc.value = !femaleSortDesc.value
  else {
    femaleSortKey.value = sortKey
    femaleSortDesc.value = false
  }
  femaleCombinationChains.value = femaleCombinationChains.value.sort(
    (a: CombinationChainItem, b: CombinationChainItem) => {
      if (sortKey === 'chainType') {
        if (femaleSortDesc.value) return a.storeType < b.storeType ? 1 : -1
        else return a.storeType > b.storeType ? 1 : -1
      } else {
        if (femaleSortDesc.value) return a.combinationRatio < b.combinationRatio ? 1 : -1
        else return a.combinationRatio > b.combinationRatio ? 1 : -1
      }
    }
  )
}

function fetchCombinationChains() {
  Promise.all([
    fetchTotalCombinationChains(),
    fetchMaleCombinationChains(),
    fetchFemaleCombinationChains()
  ])
}

if (route.query.period) {
  const period = getPeriodByRouterQueryMonthPeriod(route.query.period as string)
  if (!!period) store.commit('setMonth', period)
}

if (chainId.value) fetchCombinationChains()

watch([chainId, prefectureIds, startMonth, endMonth], () => {
  if (chainId.value) fetchCombinationChains()
})
</script>

<style lang="scss" scoped>
.padding_right_15{ padding-right: 15px;}
.padding_left_15{ padding-left: 15px;}
</style>