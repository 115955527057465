<template>
  <v-container class="pa-0" fluid>
    <v-row class="title-container" dense no-gutters>
      <v-col style="display: flex; align-items: center">
        <h1 class="title-text">曜日／時間別人数</h1>
        <UpdateBadge type="week" style="margin-left: 15px" />
      </v-col>
      <v-spacer />
      <DatePicker
        :start-date="startDate"
        :end-date="endDate"
        :compare-start-date="compareStartDate"
        :compare-end-date="compareEndDate"
        @update-period="(period) => setPeriodParams(period)"
      />
    </v-row>
    <v-row dense no-gutters class="card-container">
      <v-col>
        <ChainSelector
          :initial-chain-id="chainId"
          :initial-prefecture-ids="prefectureIds.map((id) => Number(id))"
          :start-date="startDate"
          :end-date="endDate"
          :handle-update="
            (chain, prefectureIds) =>
              setParams({
                chainId: chain.id,
                prefectureIds: prefectureIds.map((id) => id.toString())
              })
          "
        />
      </v-col>
    </v-row>
    <v-row dense no-gutters class="card-container">
      <v-col class="pb-0">
        <v-card height="'100%'" class="card">
          <v-card-title
            :class="
              !chainId || isNoHeatmapData
                ? 'card-title d-flex align-center pa-0'
                : 'card-title d-flex align-center pa-0 mb-0'
            "
          >
            <span>曜日／時間別ヒートマップ</span>
            <AlertTooltip
              v-if="heatmapAlert || comparisonHeatmapAlert"
              class="ml-2"
              text="取得データボリュームが少なく、統計上の信頼性の低いデータが含まれています。該当箇所は、参考値としてご参照ください。"
            />
            <v-spacer />
            <ChartDescriptionTooltip
              menu-key="chainAnalytics"
              sub-menu-key="trendVisitor"
              chart-key="timeDayHeatmap"
              class="mr-27px"
            />
            <DownloadButton
              label="CSVデータ"
              :disabled="!chainId"
              :get-file-id="downloadHeatmapCSV"
              :csv-name="`曜日／時間別ヒートマップ_${startDate}-${endDate}${
                isComparison ? `_${compareStartDate}-${compareEndDate}` : ''
              }`"
            />
          </v-card-title>
          <v-container fluid class="pa-0">
            <div v-show="!chainId" class="unselected_card">
              チェーンを選択するとチャートが表示されます。
            </div>
            <div v-show="chainId">
              <LoadingImg v-if="heatmapLoading" :height="'600px'" />
              <NoDataChart v-else-if="isNoHeatmapData" />
              <div v-else>
                <v-row v-if="isComparison" dense>
                  <v-col>
                    <HeatmapChart
                      :heatmap="heatmapData"
                      :legend="`● ${startDate} - ${endDate}`"
                      :is-alert="heatmapAlert"
                    />
                  </v-col>
                  <v-col>
                    <HeatmapChart
                      :heatmap="comparisonHeatmapData"
                      :legend="`●
                      ${compareStartDate} -
                      ${compareEndDate}`"
                      :is-alert="comparisonHeatmapAlert"
                    />
                  </v-col>
                </v-row>
                <HeatmapChart v-else :heatmap="heatmapData" :is-alert="heatmapAlert" />
              </div>
            </div>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense no-gutters class="card-container">
      <v-col>
        <v-card height="'650px'" class="card">
          <v-card-title class="pa-0 card-title d-flex align-center">
            <span>曜日別の平均人数</span>
            <alert-tooltip
              v-if="dayChartAlert || comparisonDayChartAlert"
              class="ml-2"
              text="取得データボリュームが少なく、統計上の信頼性の低いデータが含まれています。該当箇所は、参考値としてご参照ください。"
            />
            <v-spacer />
            <ChartDescriptionTooltip
              menu-key="chainAnalytics"
              sub-menu-key="trendVisitor"
              chart-key="dayOfWeekAverage"
              class="mr-27px"
            />
            <DownloadButton
              label="CSVデータ"
              :disabled="!chainId"
              :get-file-id="downloadDayChartCSV"
              :csv-name="`曜日別の平均人数_${startDate}-${endDate}${
                isComparison ? `_${compareStartDate}-${compareEndDate}` : ''
              }`"
            />
          </v-card-title>
          <v-container class="pa-0" fluid>
            <v-row class="my-0" dense>
              <v-col class="py-0">
                <div v-show="!chainId" class="unselected_card">
                  チェーンを選択するとチャートが表示されます。
                </div>
                <div v-show="chainId">
                  <LoadingImg v-if="dayChartLoading" :height="'550px'" />
                  <NoDataChart v-else-if="isNoDayChartData" />
                  <div v-else>
                    <ColumnChart
                      v-show="!isComparison"
                      :chart-data="dayChartData"
                      :legends="dayChartLegends"
                      :is-alert="dayChartAlert"
                      :is-stacked="true"
                    />
                    <ColumnChart
                      v-show="isComparison"
                      :chart-data="comparisonDayChartData"
                      :legends="comparisonDayChartLegends"
                      :is-alert="comparisonDayChartAlert"
                      :is-stacked="false"
                    />
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense no-gutters class="card-container">
      <v-col>
        <v-card height="'650px'" class="card">
          <v-card-title class="pa-0 card-title d-flex align-center">
            <span>時間別の平均人数</span>
            <alert-tooltip
              v-if="timeChartAlert || comparisonTimeChartAlert"
              class="ml-2"
              text="取得データボリュームが少なく、統計上の信頼性の低いデータが含まれています。該当箇所は、参考値としてご参照ください。"
            />
            <v-spacer />
            <ChartDescriptionTooltip
              menu-key="chainAnalytics"
              sub-menu-key="trendVisitor"
              chart-key="dayOfWeekAverage"
              class="mr-27px"
            />
            <DownloadButton
              label="CSVデータ"
              :disabled="!chainId"
              :get-file-id="downloadTimeChartCSV"
              :csv-name="`時間別の平均人数_${startDate}-${endDate}${
                isComparison ? `_${compareStartDate}-${compareEndDate}` : ''
              }`"
            />
          </v-card-title>
          <v-container class="pa-0" fluid>
            <v-row class="my-0" dense>
              <v-col class="py-0">
                <div v-show="!chainId" class="unselected_card">
                  チェーンを選択するとチャートが表示されます。
                </div>
                <div v-show="chainId">
                  <LoadingImg v-if="timeChartLoading" :height="'550px'" />
                  <NoDataChart v-else-if="isNoTimeChartData" />
                  <v-row v-else-if="isComparison">
                    <v-col cols="6">
                      <ColumnChart
                        :chart-data="timeChartData"
                        :legends="timeChartLegends"
                        :is-alert="timeChartAlert"
                        :is-stacked="false"
                        :colors="[COLOR.BLUE, COLOR.RED]"
                      />
                    </v-col>
                    <v-col cols="6">
                      <ColumnChart
                        :chart-data="comparisonTimeChartData"
                        :legends="comparisonTimeChartLegends"
                        :is-alert="comparisonTimeChartAlert"
                        :is-stacked="false"
                        :colors="[COLOR.BLUE, COLOR.RED]"
                      />
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col cols="12">
                      <ColumnChart
                        :chart-data="timeChartData"
                        :legends="timeChartLegends"
                        :is-alert="timeChartAlert"
                        :is-stacked="false"
                        :colors="[COLOR.BLUE, COLOR.RED]"
                      />
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import UpdateBadge from '@/commons/components/Elements/UpdateBadge.vue'
import HeatmapChart from '@/features/ChainAnalytics/trendVisitor/components/HeatmapChart.vue'
import {
  getWeekHourHeatMap,
  downloadWeekHourHeatMapCsv,
  downloadComparisonWeekHourHeatMapCsv,
  getVisitCountGraph,
  downloadVisitCountCsv,
  downloadVisitCountComparisonCsv
} from '@/features/ChainAnalytics/trendVisitor/axios'
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { VisitCountGraph, WeekHourHeatMapItem } from '@/features/ShopAnalytics/interfaces/response'
import useChainQueryParams from '@/commons/hooks/use-chain-query-params'
import usePeriodQueryParams from '@/commons/hooks/use-period-query-params'
import LoadingImg from '@/commons/components/loadingImg.vue'
import NoDataChart from '@/features/ShopAnalytics/components/Common/noDataChart.vue'
import ColumnChart from '@/features/ShopAnalytics/components/Common/columnChart.vue'
import * as notify from '@/plugins/notification'
import { PARTICLE_TYPE, SUB_TYPE, COLOR } from '@/commons/enums'
import {
  getLegends,
  processChartData,
  processComparisonChartData
} from '@/features/ShopAnalytics/utils/trendVisitor'
import ChainSelector from '@/commons/components/ChainSelector/ChainSelector.vue'
import DatePicker from '@/features/ShopAnalytics/components/Common/datePicker.vue'
import DownloadButton from '@/commons/components/Elements/DownloadButton.vue'
import ChartDescriptionTooltip from '@/commons/components/Elements/ChartDescriptionTooltip.vue'
import { useRoute } from 'vue-router'
import { getPeriodByRouterQueryPeriod } from '@/commons/utils'

/* --------------------------------------------------------------------------
  Vuex
 ---------------------------------------------------------------------------*/

const store = useStore()
const startDate = computed<string>(() => store.state.startDate)
const endDate = computed<string>(() => store.state.endDate)
const compareStartDate = computed<string>(() => store.state.compareStartDate)
const compareEndDate = computed<string>(() => store.state.compareEndDate)

/* --------------------------------------------------------------------------
  Vue Router 
 ---------------------------------------------------------------------------*/

const route = useRoute()

/* --------------------------------------------------------------------------
  期間比較フラグ
 ---------------------------------------------------------------------------*/

const isComparison = computed<boolean>(
  () => compareStartDate.value !== '' && compareEndDate.value !== ''
)

/* --------------------------------------------------------------------------
  選択済みチェーン・都道府県
 ---------------------------------------------------------------------------*/

const { chainId, prefectureIds, setParams } = useChainQueryParams()
const { setPeriodParams } = usePeriodQueryParams()

/* --------------------------------------------------------------------------
  曜日／時間別ヒートマップ
 ---------------------------------------------------------------------------*/

const heatmapLoading = ref<boolean>(false)
const heatmapData = ref<WeekHourHeatMapItem[]>([])
const heatmapAlert = computed<boolean>(() =>
  heatmapData.value.some((data) => data.alerts.length > 0)
)
const comparisonHeatmapData = ref<WeekHourHeatMapItem[]>([])
const comparisonHeatmapAlert = computed<boolean>(() =>
  comparisonHeatmapData.value.some((data) => data.alerts.length > 0)
)

const isNoHeatmapData = computed<boolean>(() => {
  return (
    heatmapData.value.length === 0 ||
    heatmapData.value.every(
      ({ monday, tuesday, wednesday, thursday, friday, saturday, sunday }) =>
        !monday.averageCount &&
        !monday.averageRatio &&
        !tuesday.averageCount &&
        !tuesday.averageRatio &&
        !wednesday.averageCount &&
        !wednesday.averageRatio &&
        !thursday.averageCount &&
        !thursday.averageRatio &&
        !friday.averageCount &&
        !friday.averageRatio &&
        !saturday.averageCount &&
        !saturday.averageRatio &&
        !sunday.averageCount &&
        !sunday.averageRatio
    )
  )
})

const fetchHeatmap = async () => {
  if (heatmapLoading.value) return

  const fetchings = [
    getWeekHourHeatMap(chainId.value, prefectureIds.value, startDate.value, endDate.value).then(
      (res) => {
        heatmapData.value = res.data.heatmap
      }
    ),
    ...(isComparison.value
      ? [
          getWeekHourHeatMap(
            chainId.value,
            prefectureIds.value,
            compareStartDate.value,
            compareEndDate.value
          ).then((res) => {
            comparisonHeatmapData.value = res.data.heatmap
          })
        ]
      : [])
  ]

  try {
    heatmapLoading.value = true
    await Promise.all(fetchings)
  } catch {
    notify.notifyErrorMessage('曜日/時間別ヒートマップが表示できませんでした。')
  } finally {
    heatmapLoading.value = false
  }
}

const downloadHeatmapCSV = () => {
  if (!isComparison.value) {
    return downloadWeekHourHeatMapCsv(
      chainId.value,
      prefectureIds.value,
      startDate.value,
      endDate.value
    )
  } else {
    return downloadComparisonWeekHourHeatMapCsv(chainId.value, prefectureIds.value, [
      { start: startDate.value, end: endDate.value },
      { start: compareStartDate.value, end: compareEndDate.value }
    ])
  }
}

/* --------------------------------------------------------------------------
  曜日別の平均人数
 ---------------------------------------------------------------------------*/

const dayChartLoading = ref<boolean>(false)
const dayChartResponse = ref<VisitCountGraph | undefined>(undefined)
const dayChartData = computed<(string | number)[][]>(() => {
  if (!dayChartResponse.value) return []
  return processChartData(
    PARTICLE_TYPE['DAY'],
    dayChartResponse.value,
    startDate.value,
    endDate.value
  )
})
const dayChartLegends = computed<{ text: string; color: string }[]>(() =>
  getLegends(dayChartData.value, false)
)
const dayChartAlert = computed<boolean>(() => {
  if (!dayChartResponse.value) return false
  return dayChartResponse.value.visitCounts.chartItems.some((item) => item.isAlert)
})
const comparisonDayChartResponse = ref<VisitCountGraph | undefined>(undefined)
const comparisonDayChartData = computed<(string | number)[][]>(() => {
  if (!dayChartResponse.value || !comparisonDayChartResponse.value) return []
  return processComparisonChartData(
    PARTICLE_TYPE['DAY'],
    dayChartResponse.value,
    startDate.value,
    endDate.value,
    comparisonDayChartResponse.value,
    compareStartDate.value,
    compareEndDate.value
  )
})
const comparisonDayChartLegends = computed<{ text: string; color: string }[]>(() =>
  getLegends(comparisonDayChartData.value, true)
)
const comparisonDayChartAlert = computed<boolean>(() => {
  if (!comparisonDayChartResponse.value) return false
  return comparisonDayChartResponse.value.visitCounts.chartItems.some((item) => item.isAlert)
})
const isNoDayChartData = computed<boolean>(() => {
  if (isComparison.value) {
    return (
      comparisonDayChartData.value.length <= 1 ||
      (!!comparisonDayChartResponse.value &&
        comparisonDayChartResponse.value?.visitCounts.chartItems.every(({ value }) => !value))
    )
  }
  return (
    dayChartData.value.length <= 1 ||
    (!!dayChartResponse.value &&
      dayChartResponse.value?.visitCounts.chartItems.every(({ value }) => !value))
  )
})

const fetchDayChart = async () => {
  if (dayChartLoading.value) return

  const fetchings = [
    getVisitCountGraph(
      chainId.value,
      prefectureIds.value,
      startDate.value,
      endDate.value,
      PARTICLE_TYPE['DAY'],
      SUB_TYPE['NONE']
    ).then((res) => {
      dayChartResponse.value = res.data
    }),
    ...(isComparison.value
      ? [
          getVisitCountGraph(
            chainId.value,
            prefectureIds.value,
            compareStartDate.value,
            compareEndDate.value,
            PARTICLE_TYPE['DAY'],
            SUB_TYPE['NONE']
          ).then((res) => {
            comparisonDayChartResponse.value = res.data
          })
        ]
      : [])
  ]

  try {
    dayChartLoading.value = true
    await Promise.all(fetchings)
  } catch {
    notify.notifyErrorMessage(
      `曜日別来店人数${isComparison.value ? '（期間比較）' : ''}が表示できませんでした。`
    )
  } finally {
    dayChartLoading.value = false
  }
}

const downloadDayChartCSV = () => {
  if (!isComparison.value) {
    return downloadVisitCountCsv(
      chainId.value,
      prefectureIds.value,
      startDate.value,
      endDate.value,
      PARTICLE_TYPE['DAY']
    )
  } else {
    return downloadVisitCountComparisonCsv(
      chainId.value,
      prefectureIds.value,
      [
        {
          start: startDate.value,
          end: endDate.value
        },
        {
          start: compareStartDate.value,
          end: compareEndDate.value
        }
      ],
      PARTICLE_TYPE['DAY']
    )
  }
}

/* --------------------------------------------------------------------------
  時間別の平均人数
 ---------------------------------------------------------------------------*/

const timeChartLoading = ref<boolean>(false)
const timeChartResponse = ref<VisitCountGraph | undefined>(undefined)
const timeChartData = computed<(string | number)[][]>(() => {
  if (!timeChartResponse.value) return []
  return processChartData(
    PARTICLE_TYPE['HOUR'],
    timeChartResponse.value,
    startDate.value,
    endDate.value
  )
})
const timeChartLegends = computed<{ text: string; color: string }[]>(() =>
  getLegends(timeChartData.value, false, PARTICLE_TYPE['HOUR'])
)
const timeChartAlert = computed<boolean>(() => {
  if (!timeChartResponse.value) return false
  return timeChartResponse.value.visitCounts.chartItems.some((item) => item.isAlert)
})
const comparisonTimeChartResponse = ref<VisitCountGraph | undefined>(undefined)
const comparisonTimeChartData = computed<(string | number)[][]>(() => {
  if (!comparisonTimeChartResponse.value) return []
  return processChartData(
    PARTICLE_TYPE['HOUR'],
    comparisonTimeChartResponse.value,
    compareStartDate.value,
    compareEndDate.value
  )
})
const comparisonTimeChartLegends = computed<{ text: string; color: string }[]>(() =>
  getLegends(comparisonTimeChartData.value, true, PARTICLE_TYPE['HOUR'])
)
const comparisonTimeChartAlert = computed<boolean>(() => {
  if (!comparisonTimeChartResponse.value) return false
  return comparisonTimeChartResponse.value.visitCounts.chartItems.some((item) => item.isAlert)
})
const isNoTimeChartData = computed<boolean>(() => {
  if (isComparison.value) {
    return (
      comparisonTimeChartData.value.length <= 1 ||
      (!!comparisonTimeChartResponse.value &&
        comparisonTimeChartResponse.value?.visitCounts.chartItems.every(({ value }) => !value))
    )
  }
  return (
    timeChartData.value.length <= 1 ||
    (!!timeChartResponse.value &&
      timeChartResponse.value?.visitCounts.chartItems.every(({ value }) => !value))
  )
})

const fetchTimeChart = async () => {
  if (timeChartLoading.value) return

  const fetchings = [
    getVisitCountGraph(
      chainId.value,
      prefectureIds.value,
      startDate.value,
      endDate.value,
      PARTICLE_TYPE['HOUR'],
      SUB_TYPE['NONE']
    ).then((res) => {
      timeChartResponse.value = res.data
    }),
    ...(isComparison.value
      ? [
          getVisitCountGraph(
            chainId.value,
            prefectureIds.value,
            compareStartDate.value,
            compareEndDate.value,
            PARTICLE_TYPE['HOUR'],
            SUB_TYPE['NONE']
          ).then((res) => {
            comparisonTimeChartResponse.value = res.data
          })
        ]
      : [])
  ]

  try {
    timeChartLoading.value = true
    await Promise.all(fetchings)
  } catch {
    notify.notifyErrorMessage(
      `時間別来店人数${isComparison.value ? '（期間比較）' : ''}が表示できませんでした。`
    )
  } finally {
    timeChartLoading.value = false
  }
}

const downloadTimeChartCSV = () => {
  if (!isComparison.value) {
    return downloadVisitCountCsv(
      chainId.value,
      prefectureIds.value,
      startDate.value,
      endDate.value,
      PARTICLE_TYPE['HOUR']
    )
  } else {
    return downloadVisitCountComparisonCsv(
      chainId.value,
      prefectureIds.value,
      [
        {
          start: startDate.value,
          end: endDate.value
        },
        {
          start: compareStartDate.value,
          end: compareEndDate.value
        }
      ],
      PARTICLE_TYPE['HOUR']
    )
  }
}

/* --------------------------------------------------------------------------
  created
 ---------------------------------------------------------------------------*/

if (route.query.period) {
  const basePeriod = getPeriodByRouterQueryPeriod(route.query.period as string)
  if (!!basePeriod) store.commit('setDate', basePeriod)
}

if (route.query.c_period) {
  const comparePeriod = getPeriodByRouterQueryPeriod(route.query.c_period as string)
  if (!!comparePeriod) store.commit('setCompareDate', comparePeriod)
}

if (chainId.value) {
  Promise.all([fetchHeatmap(), fetchDayChart(), fetchTimeChart()])
}

/* --------------------------------------------------------------------------
  watch
 ---------------------------------------------------------------------------*/

watch([chainId, prefectureIds], () => {
  if (chainId.value) {
    Promise.all([fetchHeatmap(), fetchDayChart(), fetchTimeChart()])
  }
})
</script>

<style lang="scss" scoped>
.mr-27px {
  margin-right: 27px;
}
</style>
