import { InjectionKey } from 'vue'
import { createStore, Store as baseStore, useStore as baseUseStore } from 'vuex'
import moment from 'moment'
import { AxiosResponse } from 'axios'
import {
  getStartDateOfWeek,
  getEndDateOfWeek,
  getStartDateOfMonth,
  getEndDateOfMonth,
  getStartOfWeek
} from '@/commons/utils/dateUtil'
import { Store } from '@/commons/interfaces/responses/store'
import { getStores, getStoreTypes } from '@/commons/axios/store'
import { StoresResponse } from '@/commons/interfaces/responses/store'
import { getStoresComparison } from '@/commons/axios/store'
import { ComparisonGroup, ComparisonGroupStore } from '@/features/StoreCompare/types'
import { COLOR } from '@/commons/enums'
import { FILTER_TYPE } from '@/features/RegionalShare/enums'
import { getCities, getPrefectures } from '@/features/Dashboard/axios'
import { City, Prefecture, Company } from '@/features/Dashboard/types'
import { UserType } from '@/features/Dashboard/enums'
import { getPeriod } from '@/commons/axios'
import { PeriodResponse } from '@/commons/interfaces'
import { getViewableCompanies, setViewableCompanies } from '@/commons/axios/company'
import { getChainsFromStores } from '@/commons/utils/chain'
import { getPrefectureMap, getPrefecturesWithStoreCount } from '@/commons/utils/prefecture'
import { PRODUCT_FEATURES } from '@/commons/types/Auth'
import { getCityMap } from '@/commons/utils/city'
import { GeoJSON } from '@/commons/types/GeoJSON'
import { getPrefectureGeo } from '@/commons/axios/prefectures'
import { getCitiesGeo } from '@/commons/axios/cities'
import { getTownsGeo } from '@/commons/axios/towns'
import { csvToGeoJson } from '@/commons/utils/geojson/csv-to-geojson'
import { MapBounds } from '@/commons/types/Mapbox'

export interface GlobalState {
  startDate: string
  endDate: string
  compareStartDate: string
  compareEndDate: string
  availablePeriod: PeriodResponse | undefined
  startMonth: string
  endMonth: string
  stores: Store[]
  storesLoading: boolean
  selectedStore: Store | null
  comparisonGroups: ComparisonGroup[]
  selectedComparisonGroup: ComparisonGroup | null
  comparisonGroupStoreVisibility: Map<ComparisonGroupStore, boolean>
  selectedChainId: string | null
  selectedChainIds: string[]
  selectedPrefectureIdsOfChain: number[]
  selectedPrefectureIdsOfChains: number[]
  chainIdToColorMap: Map<string, string>
  prefectures: Prefecture[]
  cities: City[]
  storeTypes: { id: number; name: string }[]
  regionalShareFilterType: (typeof FILTER_TYPE)[keyof typeof FILTER_TYPE]
  version: string
  viewCompany: Company | null
  viewableCompanies: Company[]
  userType: number
  descriptions: AxiosResponse | undefined
  prefecturesGeo: GeoJSON
  citiesGeo: GeoJSON
  townsGeo: GeoJSON
  geoLoading: boolean
  targetTownMapBounds: MapBounds | undefined
}

export const StateKey: InjectionKey<baseStore<GlobalState>> = Symbol()

const initialState = () => {
  return {
    startDate: '',
    endDate: '',
    compareStartDate: '',
    compareEndDate: '',
    availablePeriod: void 0 as PeriodResponse | undefined,
    startMonth: '', // format: YYYY/MM/DD
    endMonth: '', // format: YYYY/MM/DD
    stores: [] as Store[],
    storesLoading: true,
    selectedStore: null as Store | null,
    comparisonGroups: [] as ComparisonGroup[],
    selectedComparisonGroup: null as ComparisonGroup | null,
    comparisonGroupStoreVisibility: new Map() as Map<ComparisonGroupStore, boolean>,
    selectedChainId: null as string | null,
    selectedChainIds: [] as string[],
    selectedPrefectureIdsOfChain: [] as number[],
    selectedPrefectureIdsOfChains: [] as number[],
    chainIdToColorMap: new Map() as Map<string, string>,
    prefectures: [] as Prefecture[],
    cities: [] as City[],
    storeTypes: [] as { id: number; name: string }[],
    regionalShareFilterType: FILTER_TYPE.ALL as (typeof FILTER_TYPE)[keyof typeof FILTER_TYPE],
    version: '',
    viewCompany: null as Company | null,
    viewableCompanies: [] as Company[],
    userType: UserType.GENERAL as number,
    descriptions: void 0,
    productFeatures: [] as Array<PRODUCT_FEATURES>,
    prefecturesGeo: undefined as GeoJSON | undefined,
    citiesGeo: undefined as GeoJSON | undefined,
    townsGeo: undefined as GeoJSON | undefined,
    geoLoading: false,
    targetTownMapBounds: undefined as MapBounds | undefined
  }
}

export const store = createStore({
  state: initialState(),
  getters: {
    startDateOfWeek(state) {
      return getStartDateOfWeek(state.startDate)
    },
    endDateOfWeek(state) {
      return getEndDateOfWeek(state.endDate)
    },
    startDateOfMonth(state) {
      return getStartDateOfMonth(state.startDate)
    },
    endDateOfMonth(state) {
      return getEndDateOfMonth(state.endDate)
    },
    comparisonStartDateOfWeek(state) {
      return getStartDateOfWeek(state.compareStartDate)
    },
    comparisonEndDateOfWeek(state) {
      return getEndDateOfWeek(state.compareEndDate)
    },
    comparisonStartDateOfMonth(state) {
      return getStartDateOfMonth(state.compareStartDate)
    },
    comparisonEndDateOfMonth(state) {
      return getEndDateOfMonth(state.compareEndDate)
    },
    isCompare(state) {
      return state.compareStartDate.length !== 0 && state.compareEndDate.length !== 0
    },
    selectedComparisonGroupVisibleStores(state) {
      if (state.comparisonGroupStoreVisibility.size === 0) return []
      return Array.from(state.comparisonGroupStoreVisibility.entries())
        .filter((v) => v[1])
        .map((v) => v[0])
        .sort((a, b) => a.orderIndex - b.orderIndex)
    },
    chains(state) {
      return getChainsFromStores(state.stores)
    },
    // chainId から chain を高速で取得するための Map オブジェクト
    chainMap(state) {
      return new Map(getChainsFromStores(state.stores).map((chain) => [chain.id, chain]))
    },
    prefecturesWithStoreCount(state) {
      return getPrefecturesWithStoreCount(state.prefectures, state.stores)
    },
    prefectureMap(state) {
      return getPrefectureMap(state.prefectures)
    },
    cityMap(state) {
      return getCityMap(state.cities)
    },
    isHiroku(state) {
      return state.productFeatures.includes('HIROKU_MIERU')
    },
    isYoku(state) {
      return state.productFeatures.includes('YOKU_MIERU')
    },
    isComparePeriod(state) {
      return state.compareStartDate.length !== 0 && state.compareEndDate.length !== 0
    }
  },
  mutations: {
    initState(state) {
      Object.assign(state, initialState())
    },
    initStore(state) {
      state.selectedStore = null
    },
    initComparisonGroup(state) {
      state.selectedComparisonGroup = null
    },
    setDate(state, payload: { startDate: string; endDate: string }) {
      state.startDate = payload.startDate
      state.endDate = payload.endDate
    },
    setCompareDate(state, payload: { startDate: string; endDate: string }) {
      state.compareStartDate = payload.startDate
      state.compareEndDate = payload.endDate
    },
    setMonth(state, payload: { startMonth: string; endMonth: string }) {
      state.startMonth = payload.startMonth
      state.endMonth = payload.endMonth
    },
    setStore(state, payload: { store: Store }) {
      state.selectedStore = payload.store
    },
    setStores(state, payload: { stores: Store[] }) {
      state.stores = payload.stores
    },
    setStoresLoading(state, payload: { storesLoading: boolean }) {
      state.storesLoading = payload.storesLoading
    },
    setComparisonGroup(state, payload: { comparisonGroup: ComparisonGroup }) {
      state.selectedComparisonGroup = payload.comparisonGroup
    },
    setSelectedComparisonGroupVisibleStores(
      state,
      payload: { visibility: Map<ComparisonGroupStore, boolean> }
    ) {
      state.comparisonGroupStoreVisibility = payload.visibility
    },
    setComparisonGroups(state, payload: { comparisonGroups: ComparisonGroup[] }) {
      state.comparisonGroups = payload.comparisonGroups
    },
    setSelectedChainId(state, payload: { chainId: string }) {
      state.selectedChainId = payload.chainId
    },
    setSelectedChainIds(state, payload: { chainIds: string[] }) {
      state.selectedChainIds = payload.chainIds
    },
    setSelectedPrefectureIdsOfChain(state, payload: { prefectureIds: number[] }) {
      state.selectedPrefectureIdsOfChain = payload.prefectureIds
    },
    setSelectedPrefectureIdsOfChains(state, payload: { prefectureIds: number[] }) {
      state.selectedPrefectureIdsOfChains = payload.prefectureIds
    },
    setChainIdToColorMap(state, payload: { map: Map<string, string> }) {
      state.chainIdToColorMap = payload.map
    },
    setPrefectures(state, payload: { prefectures: Prefecture[] }) {
      state.prefectures = payload.prefectures
    },
    setCities(state, payload: { cities: City[] }) {
      state.cities = payload.cities
    },
    setStoreTypes(state, payload: { storeTypes: { id: number; name: string }[] }) {
      state.storeTypes = payload.storeTypes
    },
    setRegionalShareFilterType(
      state,
      payload: { type: (typeof FILTER_TYPE)[keyof typeof FILTER_TYPE] }
    ) {
      state.regionalShareFilterType = payload.type
    },
    setAvailablePeriod(state, payload: { period: PeriodResponse }) {
      state.availablePeriod = payload.period
    },
    setVersion(state, payload: { version: string }) {
      state.version = payload.version
    },
    setViewCompany(state, payload: { viewCompany: Company }) {
      state.viewCompany = payload.viewCompany
    },
    setViewableCompanies(state, payload: { viewableCompanies: Company[] }) {
      state.viewableCompanies = payload.viewableCompanies
    },
    setDescriptions(state, payload: { descriptions: any }) {
      state.descriptions = payload.descriptions
    },
    setUserType(state, payload: { userType: any }) {
      state.userType = payload.userType
    },
    setProductFeatures(state, payload: { productFeatures: Array<PRODUCT_FEATURES> }) {
      state.productFeatures = payload.productFeatures
    },
    setPrefecturesGeo(state, payload: { geo: GeoJSON }) {
      state.prefecturesGeo = payload.geo
    },
    setCitiesGeo(state, payload: { geo: GeoJSON }) {
      state.citiesGeo = payload.geo
    },
    setTownsGeo(state, payload: { geo: GeoJSON }) {
      state.townsGeo = payload.geo
    },
    removePrefecturesGeo(state) {
      state.prefecturesGeo = undefined
    },
    removeCitiesGeo(state) {
      state.citiesGeo = undefined
    },
    removeTownsGeo(state) {
      state.townsGeo = undefined
    },
    setGeoLoading(state, payload: { flag: boolean }) {
      state.geoLoading = payload.flag
    },
    setTargetTownMapBounds(state, payload: { point: MapBounds }) {
      state.targetTownMapBounds = payload.point
    }
  },
  actions: {
    async initDate({ state }) {
      // 来店人数推移, 曜日／時間別人数, ペルソナ特性 → 最終更新日から4週間
      state.startDate = moment(state.availablePeriod?.daily.end)
        .subtract(6, 'day')
        .subtract(3, 'week')
        .format('YYYY/MM/DD')
      state.endDate = moment(state.availablePeriod?.daily.end).format('YYYY/MM/DD')
    },
    async initMonth({ state }) {
      state.startMonth = moment(state.availablePeriod?.monthly.end)
        .startOf('month')
        .format('YYYY/MM/DD')
      state.endMonth = moment(state.availablePeriod?.monthly.end)
        .endOf('month')
        .format('YYYY/MM/DD')
    },
    async fetchStores({ commit }, payload?: { start: string; end: string }) {
      commit('setStoresLoading', { storesLoading: true })

      await getStores().then((res: AxiosResponse<StoresResponse>) => {
        commit('setStores', { stores: res.data.stores })
      })

      commit('setStoresLoading', { storesLoading: false })
    },
    async fetchComparisonGroups({ commit }) {
      await getStoresComparison().then((res) => {
        commit('setComparisonGroups', {
          comparisonGroups: res.data.comparisonGroups.map((c) => {
            const stores = c.stores.map((s) => {
              s.color = Object.values(COLOR)[s.orderIndex]
              return s
            })
            c.stores = stores
            return c
          }) as ComparisonGroup[]
        })
      })
    },
    async specifiedStore({ commit, state }, storeId: string) {
      const specifiedStore: Store | undefined = state.stores.find(
        (item: Store) => item.storeId === storeId
      )
      if (!specifiedStore) return
      commit('setStore', { store: specifiedStore })
    },
    async specifiedComparisonGroup({ commit, state }, groupId: string) {
      const specifiedComparisonGroup = state.comparisonGroups.find((item) => item.id === groupId)
      if (!specifiedComparisonGroup) return
      commit('setComparisonGroup', {
        comparisonGroup: specifiedComparisonGroup
      })
    },
    async fetchPrefectures({ commit }) {
      await getPrefectures().then((res) => {
        commit('setPrefectures', { prefectures: res.data.prefectures })
      })
    },
    async fetchCities({ commit }) {
      await getCities().then((res) => {
        commit('setCities', { cities: res.data.cities })
      })
    },
    async fetchStoreTypes({ commit }) {
      await getStoreTypes().then((res) => {
        commit('setStoreTypes', { storeTypes: res.data.storeTypes })
      })
    },
    async fetchPeriod({ commit }) {
      await getPeriod()
        .then((res) => {
          const period = res.data
          // NOTE: daily の有効期限の開始日が月曜でないケースがあるため、そのケースの時はその週の月曜になるよう変換
          period.daily.start = getStartOfWeek(res.data.daily.start)
          commit('setAvailablePeriod', { period: period })
        })
        .catch(() => undefined)
    },
    async fetchVersion({ commit }, version: string) {
      commit('setVersion', { version: version })
    },
    async fetchUserType({ commit }, userType: number) {
      commit('setUserType', { userType: userType })
    },
    async fetchDescriptions({ commit }, descriptions: any) {
      commit('setDescriptions', { descriptions: descriptions })
    },
    async fetchViewableCompanies({ commit }) {
      await getViewableCompanies()
        .then((res) => {
          const viewCompany = res.data.view_company
          commit('setViewCompany', {
            viewCompany: viewCompany
          })
          const viewableCompanies = res.data.viewable_companies
          commit('setViewableCompanies', {
            viewableCompanies: viewableCompanies
          })
        })
        .catch(() => undefined)
    },
    async setViewCompany({ commit }, company: Company) {
      await setViewableCompanies(company)
        .then((res) => {
          const viewCompany = res.data.view_company
          commit('setViewCompany', {
            viewCompany: viewCompany
          })
          const viewableCompanies = res.data.viewable_companies
          commit('setViewableCompanies', {
            viewableCompanies: viewableCompanies
          })
        })
        .catch(() => undefined)
    },
    async fetchProductFeatures({ commit }, productFeatures: Array<PRODUCT_FEATURES>) {
      if (!Array.isArray(productFeatures)) return
      commit('setProductFeatures', { productFeatures: productFeatures })
    },
    async fetchPrefecturesGeo({ commit }, prefectureId: string) {
      commit('setGeoLoading', { flag: true })
      const res = await getPrefectureGeo(prefectureId)
      const prefecturesGeo = await csvToGeoJson(res.data.url)
      await commit('setPrefecturesGeo', { geo: prefecturesGeo })
      commit('setGeoLoading', { flag: false })
    },
    async fetchCitiesGeo({ commit }, prefectureId: string) {
      commit('setGeoLoading', { flag: true })
      const res = await getCitiesGeo(prefectureId)
      const citiesGeo = await csvToGeoJson(res.data.url)
      await commit('setCitiesGeo', { geo: citiesGeo })
      commit('setGeoLoading', { flag: false })
    },
    async fetchTownsGeo({ commit }, prefectureId: string) {
      commit('setGeoLoading', { flag: true })
      const res = await getTownsGeo(prefectureId)
      const townsGeo = await csvToGeoJson(res.data.url)
      await commit('setTownsGeo', { geo: townsGeo })
      commit('setGeoLoading', { flag: false })
    },
    async removePrefecturesGeo({ commit }) {
      commit('removePrefecturesGeo')
    },
    async removeCitiesGeo({ commit }) {
      commit('removeCitiesGeo')
    },
    async removeTownsGeo({ commit }) {
      commit('removeTownsGeo')
    }
  },
  modules: {}
})

export default store

export function useStore() {
  return baseUseStore(StateKey)
}
