<template>
  <form class="filter-form">
    <div class="start-contents">
      <span class="description">エリアを絞り込み</span>
      <CustomSelector
        v-model="selectedPrefecture"
        class="mr"
        :items="prefectureItems"
        placeholder="都道府県を選択"
        aria-label="都道府県選択"
        size="md"
        height="34px"
        width="140px"
        :disabled="!props.selectedPrefectureIds.length"
      />
      <div class="chevron mr">
        <img src="@/assets/svg/chevron-right.svg" />
      </div>
      <div class="select-button-wrap mr">
        <button
          ref="cityButtonRef"
          type="button"
          class="select-button"
          aria-label="市区町村選択"
          :disabled="!selectedPrefecture"
          @click="() => (open = !open)"
        >
          <span v-if="selectedCityIds.length === 0">市区町村を選択（５つまで）</span>
          <span v-else class="limit-text">
            {{ selectedCities.map(({ name }) => name).join(', ') }}
          </span>
          <img class="icon" src="@/assets/svg/triangle-down.svg" />
        </button>
        <transition name="fade">
          <div v-show="open" ref="contentsRef" class="contents">
            <!-- HACK: watcherが想定以上に発火してしまい初期値が入らない不具合があるためコンポーネント化を中断 -->
            <div class="city-selector">
              <header>
                <div class="title">
                  市区町村を選択<span class="sub-text">（５つまで選択可）</span>
                </div>
              </header>
              <form>
                <div>
                  <div class="cities">
                    <div
                      v-for="city in cities.filter(
                        ({ prefectureId }) => prefectureId === selectedPrefecture?.value
                      )"
                      :key="city.cityId"
                      class="checkbox-label"
                    >
                      <input
                        :id="city.cityId"
                        v-model="selectedCityIds"
                        type="checkbox"
                        :value="city.cityId"
                        :disabled="isCitySelectionLimitReached(city.cityId)"
                      />
                      <label
                        :for="city.cityId"
                        :class="{ 'label-disabled': isCitySelectionLimitReached(city.cityId) }"
                      >
                        {{ city.name }}
                      </label>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </transition>
      </div>
      <CustomButton
        type="button"
        height="32px"
        width="80px"
        :disabled="isApplyDisabled"
        @click="handleApply"
      >
        適用
      </CustomButton>
    </div>
    <button type="button" class="reset" :disabled="!selectedPrefecture" @click="handleReset">
      絞り込み解除
    </button>
  </form>
</template>

<script setup lang="ts">
import CustomButton from '@/commons/components/Elements/CustomButton.vue'
import CustomSelector, { Item } from '@/commons/components/Elements/CustomSelector.vue'
import { City, Prefecture } from '@/commons/interfaces'
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'

// 選択できる市区町村の上限数
const CITY_SELECT_LIMIT = 5

/* --------------------------------------------------------------------------
  props
 ---------------------------------------------------------------------------*/

const props = withDefaults(
  defineProps<{
    selectedPrefectureIds: number[]
    onApply: (args: { prefectureId?: number; cityIds?: string[] }) => void
    onResetFilterValue: () => void
    filteredPrefectureId?: number
    filteredCityIds?: string[]
  }>(),
  {
    selectedPrefectureIds: () => [],
    onApply: () => undefined,
    onResetFilterValue: () => undefined,
    filteredPrefectureId: undefined,
    filteredCityIds: undefined
  }
)

/* --------------------------------------------------------------------------
  Vuex
 ---------------------------------------------------------------------------*/

const store = useStore()
const prefectures = computed<Prefecture[]>(() => store.state.prefectures)
const cities = computed<City[]>(() => store.state.cities)
const cityMap = computed<Record<string, City>>(() => store.getters.cityMap)

/* --------------------------------------------------------------------------
  core logic
 ---------------------------------------------------------------------------*/

const open = ref<boolean>(false)
const prefectureItems = computed<Item[]>(() =>
  prefectures.value
    .filter((prefecture) => {
      if (props.selectedPrefectureIds.length === 0) return true
      return props.selectedPrefectureIds.includes(prefecture.prefectureId)
    })
    .map(({ prefectureId, name }) => ({ text: name, value: prefectureId }))
)
const selectedPrefecture = ref<Item | undefined>()
const selectedCityIds = ref<string[]>([])
const selectedCities = computed<City[]>(() => selectedCityIds.value.map((id) => cityMap.value[id]))

// DOM 埋め込み用 ref (ポップオーバーを要素外クリックで閉じる際に使用)
const cityButtonRef = ref<null | HTMLButtonElement>(null)
const contentsRef = ref<null | HTMLDivElement>(null)

// 適用ボタンの disabled
const isApplyDisabled = computed<boolean>(() => !selectedPrefecture.value)

// 市区町村の選択は５件まで
const isCitySelectionLimitReached = (cityId: string) =>
  !selectedCityIds.value.includes(cityId) && selectedCityIds.value.length >= CITY_SELECT_LIMIT

// ダイアログを閉じるためのコールバック関数
const handleClose = (event: MouseEvent) => {
  if (
    cityButtonRef.value &&
    !cityButtonRef.value.contains(event.target as Node) &&
    contentsRef.value &&
    !contentsRef.value.contains(event.target as Node)
  ) {
    open.value = false
  }
}

// 絞り込み解除のコールバック関数
const handleReset = () => {
  selectedPrefecture.value = undefined
  selectedCityIds.value = []
  resetFilterValue()
  handleApply()
}

// 適用ボタンのコールバック関数
const handleApply = () => {
  if (typeof selectedPrefecture.value?.value !== 'number') {
    props.onApply({})
    return
  }
  props.onApply({ prefectureId: selectedPrefecture.value.value, cityIds: selectedCityIds.value })
}

// Filterで選択されている都道府県・市区町村の値をリセットするコールバック関数
const resetFilterValue = () => {
  props.onResetFilterValue()
}

onMounted(() => {
  window.addEventListener('click', handleClose)
})

onBeforeUnmount(() => {
  window.removeEventListener('click', handleClose)
})

/* --------------------------------------------------------------------------
  watch
 ---------------------------------------------------------------------------*/

watch(prefectureItems, () => {
  if (
    !prefectureItems.value.map((item) => item.value).includes(selectedPrefecture.value?.value ?? '')
  ) {
    selectedPrefecture.value = undefined
  }
})

watch(selectedPrefecture, () => {
  selectedCityIds.value = []
})

watch(
  () => [props.filteredPrefectureId, props.filteredCityIds],
  () => {
    if (!props.filteredPrefectureId && !props.filteredCityIds) {
      handleReset()
    }
  },
  { deep: true }
)

/* --------------------------------------------------------------------------
  created
 ---------------------------------------------------------------------------*/

const created = async () => {
  if (!prefectures.value.length) await store.dispatch('fetchPrefectures')
  if (!cities.value.length) await store.dispatch('fetchCities')
}

created()
</script>

<style scoped lang="scss">
.filter-form {
  display: flex;
  flex: 1;
  align-items: center;
  padding-left: 21px;
  padding-right: 20px;
  height: 64px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
}

.start-contents {
  display: flex;
  flex: 1;
  align-items: center;

  .description {
    margin-right: 19px;
    font: normal normal bold 13px/20px Noto Sans CJK JP;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
  }

  .select-button-wrap {
    position: relative;
  }

  .select-button {
    display: flex;
    justify-content: space-between;
    height: 34px;
    width: 320px;
    align-items: center;
    border: 1px solid #cccccc;
    border-radius: 4px;
    padding: 0 15px;
    text-align: left;
    font: normal normal normal 14px/40px Noto Sans JP;
    letter-spacing: 0px;
    color: #222222;
    background: #ffffff 0% 0% no-repeat padding-box;
    opacity: 1;
  }

  .select-button:disabled {
    background: #e5e5e5;
    border-color: #e5e5e5;
    pointer-events: none;
    color: #999999;
  }

  .limit-text {
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.contents {
  position: absolute;
  top: 40px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  background: #ffffff 0% 0% no-repeat padding-box;
  width: 100%;
  height: 480px;
  padding: 26px 29px;
  overflow-y: scroll;
  z-index: 6;

  .city-selector {
    width: 100%;

    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .title {
        color: var(--unnamed-color-222222);
        text-align: right;
        font: normal normal bold 15px/28px Noto Sans JP;
        letter-spacing: 0px;

        .sub-text {
          color: var(---666666);
          text-align: right;
          font: normal normal normal 15px/28px Noto Sans JP;
          letter-spacing: 0px;
        }
      }

      .reset-filter-button {
        color: var(---4d99d0（年代2）);
        text-align: right;
        font: normal normal normal 14px/30px Noto Sans JP;
        letter-spacing: 0px;
        color: #4d99d0;
      }
    }

    form {
      .prefecture {
        margin-top: 39px;
        height: 40px;
        padding: 10px 11px;
        text-align: left;
        font: normal normal bold 14px/20px Noto Sans JP;
        letter-spacing: 0px;
        color: #222222;
        background: #f5f5f5 0% 0% no-repeat padding-box;
        opacity: 1;
      }

      .cities {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 25px;
        width: 100%;
        margin-top: 20px;
      }

      .checkbox-label {
        display: flex;
        gap: 10px;
        text-align: left;
        font: normal normal normal 14px/20px Noto Sans JP;
        letter-spacing: 0px;
        color: #000000;

        input {
          cursor: pointer;
          width: 16px;
          height: 16px;
          transform: translateY(2px);
        }

        label {
          cursor: pointer;
        }

        .label-disabled {
          cursor: default;
          color: #999999;
        }
      }
    }

    .button-wrapper {
      margin-top: 46px;
    }
  }
}

.reset {
  font: normal normal normal 13px/30px Noto Sans CJK JP;
  letter-spacing: 0px;
  color: #4d99d0;
  opacity: 1;
}

.reset:disabled {
  color: #cccccc;
  cursor: default;
}

.mr {
  margin-right: 10.43px;
}
</style>
