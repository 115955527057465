<template>
  <div class="map_container">
    <v-overlay
      contained
      :model-value="loading"
      class="align-center justify-center"
      style="z-index: 4"
      :persistent="true"
      :no-click-animation="true"
    >
      <loading-img />
    </v-overlay>
    <SearchAutoComplete
      class="map-search-bar"
      width="480px"
      list-width="150%"
    />
    <DisplayCountPulldown class="map-display-count-pulldown" />
    <div
      v-if="!centerPoint"
      class="map"
    >
      <div class="map-no-store-overlay">
        <span>基準となる店舗を選択してください</span>
      </div>
    </div>
    <no-data-chart v-else-if="!loading && emptyRegionalShareMapData" />
    <MglMap
      v-else
      class="map"
      :access-token="mapAccessToken"
      :map-style="mapStyle"
      :center="centerPoint"
      :zoom="zoom"
      :attribution-control="false"
      :logo-position="'bottom-right'"
      :drag-rotate="false"
      :touch-zoom-rotate="false"
      :pitch-with-rotate="false"
      @idle="idleEvent"
    >
      <MglMarker
        v-if="centerPoint"
        :coordinates="centerPoint"
      >
        <template #marker>
          <div>
            <img src="@/assets/svg/center-pin.svg">
          </div>
        </template>
      </MglMarker>
      <!-- 基準店舗 -->
      <MglGeojsonLayer
        v-if="baseStoreLayer"
        ref="baseStore"
        :source-id="baseSourceId"
        :source="baseStoreSource"
        :layer-id="baseLayerId"
        :layer="baseStoreLayer"
        :clear-source="true"
        @mouseenter="mouseOverLayer"
      />
      <!-- 周辺店舗 -->
      <MglGeojsonLayer
        v-if="compStoreLayer"
        ref="compStore"
        :source-id="compSourceId"
        :source="compStoreSource"
        :layer-id="compLayerId"
        :layer="compStoreLayer"
        :clear-source="true"
        @mousemove="mouseOverLayer"
      />
      <MglGeojsonLayer
        v-if="compStoreMarkerLayer"
        ref="compStoreMarker"
        :source-id="compSourceMarkerId"
        :source="compStoreMarkerSource"
        :layer-id="compLayerMarkerId"
        :layer="compStoreMarkerLayer"
        :clear-source="true"
      />
      <!-- データ補足範囲 -->
      <MglGeojsonLayer
        v-if="radiusLayer"
        ref="radiusRef"
        :source-id="radiusSource.data.id"
        :source="radiusSource"
        :layer-id="radiusLayer.id"
        :layer="radiusLayer"
        :clear-source="true"
      />
      <MglAttributionControl v-if="storePoint" />
      <MglNavigationControl
        v-if="storePoint"
        :show-compass="false"
        position="top-right"
      />
      <MglScaleControl position="bottom-left" />
      <!--
        NOTE: anchorとshowedは反転して適用される。
        NOTE: 実際にはanchorは右上に表示、showedがfalseの時に表示されているので注意
      -->
      <MglPopup
        anchor="bottom-left"
        :showed="hidePopup"
        :coordinates="popupCoordinates"
        :close-button="false"
        :close-on-click="false"
      >
        <RegionalShareMapPopup
          :title="popupStoreName"
          :store-type="popupStoreType"
          :store-type-text="popupStoreTypeText"
          :store-visit-count="popupStoreVisitCount"
          :on-mouseover="mouseenterPopup"
          :on-mouseout="mouseleavePopup"
          @close-popup="closePopup"
          @change-store="changeStore"
          @move-store="moveStore"
        />
      </MglPopup>
    </MglMap>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, computed, nextTick, onUpdated, watch } from "vue";
import {
  MglMap,
  MglMarker,
  MglGeojsonLayer,
  MglAttributionControl,
  MglNavigationControl,
  MglScaleControl,
  MglPopup,
} from "@/vendor/vue-mapbox/main";
import SearchAutoComplete from "@/commons/components/StoreSelector/SearchAutoComplete.vue";
import DisplayCountPulldown from "@/features/RegionalShare/components/DisplayCountPulldown.vue";
import LoadingImg from "@/commons/components/loadingImg.vue";
import { STORE_TYPE } from "@/commons/enums";
import { MapBoxFeatures } from "@/features/RegionalShare/interfaces/components";
import router from "@/router";
import crossIcon from "@/assets/svg/cross.svg";
import { MAP_ACCESS_TOKEN, MAP_STYLE } from "@/config";
import RegionalShareMapPopup from "@/features/RegionalShare/components/RegionalShareMapPopup.vue";
import { debounce } from "lodash";
import NoDataChart from "@/features/ShopAnalytics/components/Common/noDataChart.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "RegionalShareMap",
  components: {
    RegionalShareMapPopup,
    MglMap,
    MglMarker,
    MglGeojsonLayer,
    MglAttributionControl,
    MglNavigationControl,
    MglScaleControl,
    SearchAutoComplete,
    MglPopup,
    LoadingImg,
    DisplayCountPulldown,
    NoDataChart,
  },
  props: {
    loading: {
      type: Boolean,
    },
    storePoint: {
      type: Object as PropType<{ lat: number; lng: number } | undefined>, default: undefined,
    },
    radius: {
      type: Number as PropType<number>, default: 0,
    },
    maxValue: {
      type: Number,
      default: 0,
    },
    baseFeatures: {
      type: Array as PropType<MapBoxFeatures[]>, default: () => { return [] },
    },
    compFeatures: {
      type: Array as PropType<MapBoxFeatures[]>, default: () => { return [] },
    },
  },
  setup(props) {
    const baseSourceId = "baseStoreSourceId";
    const baseLayerId = "baseStoreLayerId";
    const compSourceId = "compStoreSourceId";
    const compLayerId = "compStoreLayerId";
    const compSourceMarkerId = "compStoreMarkerSourceId";
    const compLayerMarkerId = "compStoreMarkerLayerId";

    let initializedMap = false;

    const store = useStore();
    const zoom = ref(14);
    const radiusSource = ref();
    const radiusLayer = ref();
    const baseStoreLayer = ref();
    const baseStoreSource = ref();
    const compStoreSource = ref();
    const compStoreLayer = ref();
    const hidePopup = ref(true);
    const storeTypes = STORE_TYPE;
    const popupCoordinates = ref([0, 0]);
    const popupStoreId = ref();
    const popupStoreName = ref();
    const popupStoreType = ref(0);
    const popupStoreTypeText = ref();
    const popupStoreVisitCount = ref(0);
    const compStoreMarkerSource = ref();
    const compStoreMarkerLayer = ref();
    const isHoveringOnPopup = ref(false);
    const isHoveringOnStore = ref(false);
    const compStoreMarker = ref();
    const baseStore = ref();
    const compStore = ref();
    const radiusRef = ref();

    const centerPoint = computed(() => {
      return props.storePoint
        ? [props.storePoint.lng, props.storePoint.lat]
        : undefined;
    });
    const emptyRegionalShareMapData = computed((): boolean => {
      return props.baseFeatures.length === 0;
    });

    onUpdated(() => {
      nextTick(() => {
        if (!compStoreMarker.value) return;

        const map = compStoreMarker.value.map;
        if (!initializedMap) {
          const crossImg = new Image(25, 25);
          crossImg.onload = () => map.addImage("cross", crossImg);
          crossImg.src = crossIcon;

          // 円・ポップアップの領域外にマウスが存在する時にポップアップを削除する処理を追加
          map.on("mouseenter", "baseStoreLayerId", () => {
            isHoveringOnStore.value = true;
          });
          map.on(
            "mouseleave",
            "baseStoreLayerId",
            // NOTE: 円とポップアップの境目で mouseleave が発火するため debounce で遅延実行
            debounce(() => {
              isHoveringOnStore.value = false;
              if (!isHoveringOnPopup.value && !isHoveringOnStore.value) closePopup();
            }, 50)
          );
          map.on("mouseenter", "compStoreLayerId", () => {
            isHoveringOnStore.value = true;
          });
          map.on(
            "mouseleave",
            "compStoreLayerId",
            // NOTE: 円とポップアップの境目で mouseleave が発火するため debounce で遅延実行
            debounce(() => {
              isHoveringOnStore.value = false;
              if (!isHoveringOnPopup.value && !isHoveringOnStore.value) closePopup();
            }, 50)
          );

          initializedMap = true;
        }
      });
    });

    const mouseenterPopup = () => {
      isHoveringOnPopup.value = true;
      hidePopup.value = false;
    };

    const mouseleavePopup = () => {
      isHoveringOnPopup.value = false;
      if (!isHoveringOnPopup.value && !isHoveringOnStore.value)
        closePopup();
    };
    const idleEvent = (event: any) => {
      if (!baseStoreSource.value && props.baseFeatures.length !== 0)
        createBaseStore();
      event.map.resize();
    };
    const mouseOverLayer = (e: any) => {
      popupStoreId.value = e.mapboxEvent.features[0].properties.storeId;
      popupStoreName.value = e.mapboxEvent.features[0].properties.storeName;
      popupStoreType.value = e.mapboxEvent.features[0].properties.storeType;
      popupStoreTypeText.value = storeTypes.toLocalString(
        e.mapboxEvent.features[0].properties.storeType
      );
      popupStoreVisitCount.value = Number((
        e.mapboxEvent.features[0].properties.visitCount * 100
      ).toFixed(1));
      popupCoordinates.value = [
        e.mapboxEvent.features[0].properties.longitude,
        e.mapboxEvent.features[0].properties.latitude,
      ];
      nextTick(() => {
        // 遅らせないと何故か表示した瞬間に画面最下部にスクロールする現象が起こる
        hidePopup.value = false;
      });
    };
    const closePopup = () => {
      hidePopup.value = true;
      popupStoreId.value = undefined;
      popupCoordinates.value = [0, 0];
      popupStoreName.value = undefined;
      popupStoreType.value = 0;
      popupStoreTypeText.value = undefined;
      popupStoreVisitCount.value = 0;
    };

    const changeStore = () => {
      store.dispatch("specifiedStore", popupStoreId.value);
    };
    const moveStore = () => {
      router.push({
        name: "ShopAnalyticsVisitor",
        params: { id: popupStoreId.value },
      });
    };
    const updateBaseStore = () => {
      if (baseStore.value) resetBaseStore();
      else createBaseStore();
    };
    const createBaseStore = () => {
      baseStoreSource.value = {
        type: "geojson",
        data: {
          id: baseSourceId,
          type: "FeatureCollection",
          features: props.baseFeatures,
        },
      };
      baseStoreLayer.value = {
        id: baseLayerId,
        type: "circle",
        source: baseStoreSource.value,
        paint: {
          "circle-pitch-scale": "viewport",
          "circle-radius": [
            "interpolate",
            ["linear"],
            ["get", "visitCount"],
            0,
            10,
            props.maxValue,
            100,
          ],
          "circle-color": "#519ACE",
          "circle-opacity": 0.2,
          "circle-stroke-color": "#519ACE",
          "circle-stroke-width": 2,
        },
      };
    };
    const resetBaseStore = () => {
      hidePopup.value = true;
      try {
        const map = baseStore.value.map;
        map.removeSource(baseSourceId);
        if (map.getLayer(baseLayerId)) {
          map.removeLayer(baseLayerId);
          map.removeSource(baseLayerId);
        }
      } catch (error) {
        console.error(error);
        throw new Error("Failed to delete layer.");
      }
      baseStoreSource.value = null;
      baseStoreLayer.value = null;
      (async () => {
        await new Promise((resolve) => setTimeout(resolve, 0));
        createBaseStore();
      })();
    };
    const updateCompStore = () => {
      if (compStore.value) resetCompStore();
      else createCompStore();
    };
    const createCompStore = () => {
      compStoreSource.value = {
        type: "geojson",
        data: {
          id: compSourceId,
          type: "FeatureCollection",
          features: props.compFeatures,
        },
      };
      compStoreLayer.value = {
        id: compLayerId,
        type: "circle",
        source: compStoreSource.value,
        paint: {
          "circle-pitch-scale": "viewport",
          "circle-radius": [
            "interpolate",
            ["linear"],
            ["get", "visitCount"],
            0,
            10,
            props.maxValue,
            100,
          ],
          "circle-color": [
            "match",
            ["get", "storeType"],
            storeTypes.SUPER_MARKET.value,
            "#E47075",
            storeTypes.DRUG_STORE.value,
            "#8DB9A4",
            storeTypes.HOME_CENTER.value,
            "#D8B47F",
            storeTypes.CVS_STORE.value,
            "#9278c3",
            "#ffffff",
          ],
          "circle-opacity": 0.2,
          "circle-stroke-color": [
            "match",
            ["get", "storeType"],
            storeTypes.SUPER_MARKET.value,
            "#E47075",
            storeTypes.DRUG_STORE.value,
            "#8DB9A4",
            storeTypes.HOME_CENTER.value,
            "#D8B47F",
            storeTypes.CVS_STORE.value,
            "#9278c3",
            "#ffffff",
          ],
          "circle-stroke-width": 2,
        },
      };
      compStoreMarkerSource.value = {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: props.compFeatures.map((compFeature: MapBoxFeatures) => {
            return {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [
                  compFeature.properties.longitude,
                  compFeature.properties.latitude,
                ],
              },
              properties: {
                icon: "cross",
              },
            };
          }),
        },
      };
      compStoreMarkerLayer.value = {
        id: compLayerMarkerId,
        type: "symbol",
        source: compStoreMarkerSource.value,
        layout: {
          "icon-image": "{icon}",
        },
      };
    };
    const resetCompStore = () => {
      hidePopup.value = true;
      try {
        const map = compStore.value.map;
        map.removeSource(compSourceId);
        if (map.getLayer(compLayerId)) {
          map.removeLayer(compLayerId);
          map.removeSource(compLayerId);
        }
      } catch (error) {
        console.error(error);
        throw new Error("Failed to delete layer.");
      }
      compStoreSource.value = null;
      compStoreLayer.value = null;

      try {
        const map = compStoreMarker.value.map;
        if (map.getLayer(compLayerMarkerId))
          map.removeLayer(compLayerMarkerId);
        if (map.getSource(compLayerMarkerId))
          map.removeSource(compLayerMarkerId);
      } catch (error) {
        console.error(error);
        throw new Error("Failed to delete layer.");
      }
      compStoreMarkerSource.value = null;
      compStoreMarkerLayer.value = null;

      (async () => {
        await new Promise((resolve) => setTimeout(resolve, 0));
        createCompStore();
      })();
    };
    const updateRadius = () => {
      if (radiusRef.value) resetRadius();
      else createRadius();
    };
    const createRadius = () => {
      radiusSource.value = {
        type: "geojson",
        data: {
          id: "radiusSource",
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "Polygon",
                coordinates: [createCirclePolygon(props.radius)],
              },
            },
          ],
        },
      };
      radiusLayer.value = {
        id: "radiusLayer",
        type: "line",
        source: radiusSource.value,
        layout: {},
        paint: {
          "line-width": 4,
          "line-color": "#519ace",
        },
      };
    };
    const resetRadius = () => {
      if (!radiusSource.value) return;
      try {
        const map = radiusRef.value.map;
        map.removeSource(radiusSource.value.data.id);
        if (map.getLayer(radiusLayer.value.id)) {
          map.removeLayer(radiusLayer.value.id);
          map.removeSource(radiusLayer.value.id);
        }
      } catch (error) {
        console.error(error);
        throw new Error("Failed to delete layer.");
      }

      radiusSource.value = null;
      radiusLayer.value = null;
      (async () => {
        await new Promise((resolve) => setTimeout(resolve, 0));
        createRadius();
      })();
    };
    const createCirclePolygon = (rad: number) => {
      const points = 64;
      const coords = {
        latitude: props.storePoint?.lat ?? 0,
        longitude: props.storePoint?.lng ?? 0,
      };

      const radiusPerKm = rad;
      const distanceX =
        radiusPerKm / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
      const distanceY = radiusPerKm / 110.574;

      const ret = [];
      let theta, x, y;
      for (let i = 0; i < points; i++) {
        theta = (i / points) * (2 * Math.PI);
        x = distanceX * Math.cos(theta);
        y = distanceY * Math.sin(theta);

        ret.push([coords.longitude + x, coords.latitude + y]);
      }
      ret.push(ret[0]);
      return ret;
    };

    watch(() => props.baseFeatures, () => {
      updateBaseStore();
    });

    watch(() => props.compFeatures, () => {
      updateCompStore();
    });

    watch(() => props.radius, () => {
      updateRadius();
    });

    watch(() => props.storePoint, () => {
      updateRadius();
    });

    return {
      mapAccessToken: MAP_ACCESS_TOKEN,
      mapStyle: MAP_STYLE,
      zoom,
      radiusSource,
      radiusLayer,
      baseSourceId,
      baseLayerId,
      baseStoreLayer,
      baseStoreSource,
      compSourceId,
      compLayerId,
      compStoreSource,
      compStoreLayer,
      hidePopup,
      popupCoordinates,
      popupStoreId,
      popupStoreName,
      popupStoreType,
      popupStoreTypeText,
      popupStoreVisitCount,
      compSourceMarkerId,
      compLayerMarkerId,
      compStoreMarkerSource,
      compStoreMarkerLayer,
      isHoveringOnPopup,
      isHoveringOnStore,
      initializedMap,
      centerPoint,
      emptyRegionalShareMapData,  
      mouseenterPopup,
      mouseleavePopup,
      idleEvent,
      mouseOverLayer,
      closePopup,
      changeStore,
      moveStore,
      compStore,
      baseStore,
      compStoreMarker,
      radiusRef,
    };
  },
});
</script>

<style lang="scss" scoped>
.map_container {
  width: 100%;
  position: relative;

  .map-search-bar {
    position: absolute;
    top: 20px;
    left: 29px;
    z-index: 3;
  }

  .map-display-count-pulldown {
    position: absolute;
    top: 70px;
    left: 0;
    z-index: 2;
  }
}
.map {
  height: 750px;
}
.map-no-store-overlay {
  position: absolute;
  background: #eeeeee;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
