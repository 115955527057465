<template>
  <v-data-table
    class="combination-table"
    :items="props.items"
  >
    <template #headers>
      <!-- ヘッダー -->
      <tr class="table-header" :class="props.type === 'total' ? 'total' : 'gender'">
        <th class="rank-head">順位</th>
        <th class="name-head">チェーン名</th>
        <th class="type-head">
          <sort-button
            :show-status="{
              up: props.sortBy !== 'chainType' || !props.sortDesc,
              down: props.sortBy !== 'chainType' || props.sortDesc
            }"
            @sort-action="emits('change-sort-key', 'chainType')"
          >
            <span class="sort-lavel">業種</span>
          </sort-button>
        </th>
        <th class="ratio-head">
          <sort-button
            :show-status="{
              up: props.sortBy !== 'combinationRatio' || !props.sortDesc,
              down: props.sortBy !== 'combinationRatio' || props.sortDesc
            }"
            @sort-action="emits('change-sort-key', 'combinationRatio')"
          >
            <span class="sort-lavel">併用率</span>
          </sort-button>
        </th>
      </tr>
      <!-- 固定行(基準チェーン) -->
      <tr v-if="props.type === 'total'" class="base-chain">
        <th class="column-head base-head rank">基準チェーン</th>
        <th class="column-name name">
          {{ props.baseChain.name }}
        </th>
        <th class="column-type type">
          <span
            :class="{
              supermarket: props.baseChain.storeType === STORE_TYPE.SUPER_MARKET.value,
              drugstore: props.baseChain.storeType === STORE_TYPE.DRUG_STORE.value,
              homecenter: props.baseChain.storeType === STORE_TYPE.HOME_CENTER.value,
              cvsstore: props.baseChain.storeType === STORE_TYPE.CVS_STORE.value
            }"
          >
            ●
          </span>
          {{ STORE_TYPE.toLocalString(props.baseChain.storeType) }}
        </th>
        <th class="column-ratio ratio">ー</th>
      </tr>
    </template>
    <template #body="items">
      <tr
        v-for="item in items.items"
        class="table-body"
        :class="props.type === 'total' ? 'total' : 'gender'"
      >
        <th class="rank">
          {{ item.rank }}
        </th>
        <th class="name">
          {{ item.name }}
        </th>
        <th class="type">
          <span
            :class="{
              supermarket: item.storeType === STORE_TYPE.SUPER_MARKET.value,
              drugstore: item.storeType === STORE_TYPE.DRUG_STORE.value,
              homecenter: item.storeType === STORE_TYPE.HOME_CENTER.value,
              cvsstore: item.storeType === STORE_TYPE.CVS_STORE.value
            }"
          >
            ●
          </span>
          {{ STORE_TYPE.toLocalString(item.storeType) }}
        </th>
        <template v-if="item.combinationRatio == null">
          <th class="column-ratio ratio">ー</th>
        </template>
        <template v-else>
          <th class="ratio">
            <div :style="getBackgroundBarStyle(item.combinationRatio, props.type, maxValue)">
              <span class="ratio-text">{{ formatVisitRateValue(item.combinationRatio) }}%</span>
            </div>
          </th>
        </template>
      </tr>
    </template>
    <template #bottom />
  </v-data-table>
</template>

<script setup lang="ts">
import SortButton from '@/commons/components/Elements/SortButton.vue'
import { ref, watch } from 'vue'
import { Chain } from '@/commons/interfaces'
import { CombinationChainItem } from '@/features/ChainAnalytics/combination/interfaces'
import { STORE_TYPE } from '@/commons/enums'

const props = withDefaults(
  defineProps<{
    type: 'total' | 'male' | 'female'
    baseChain: Chain
    items: CombinationChainItem[]
    sortBy: undefined | 'chainType' | 'combinationRatio'
    sortDesc: boolean
  }>(),
  {
    type: 'total',
    baseChain: undefined,
    items: () => [],
    sortBy: undefined,
    sortDesc: false
  }
)

const emits = defineEmits(['change-sort-key'])
const maxValue = ref<number>(1)

const getMaxValue = () => {
  let _maxValue = 0

  if (props.items && props.items.length > 0) {
    const maxObj = props.items.reduce((maxObj, currentObj) => {
      return currentObj.combinationRatio > maxObj.combinationRatio ? currentObj : maxObj
    }, props.items[0])

    _maxValue = maxObj.combinationRatio
  }

  maxValue.value = _maxValue
}

const getBackgroundBarStyle = (
  value: number,
  type: 'total' | 'male' | 'female',
  maxValue: number
) => {
  let color = '#eeeeee'
  if (type === 'male') color = '#DEEDF8'
  else if (type === 'female') color = '#F8E5E5'
  return {
    width: `${(value / maxValue) * 100}%`,
    height: '26px',
    'line-height': '26px',
    background: `${color} 0% 0% no-repeat padding-box`
  }
}
const formatVisitRateValue = (rawValue: number): string => {
  /**
   * 割合が0~1の範囲で渡されるのでこれを100倍して0~100の範囲に変換
   * 小数点以下2桁の部分を四捨五入したいので10倍して四捨五入し、10で割る
   * 小数点以下1桁だけ欲しいのでtoFixedする
   */
  return (Math.round(rawValue * 100 * 10) / 10).toFixed(1)
}

watch(
  () => props.items,
  () => {
    getMaxValue()
  },
  { immediate: true, deep: true }
)
</script>

<style>
.combination-table .v-table__wrapper {
  overflow-x: hidden;
}
</style>
<style scoped lang="scss">
.v-data-table :deep(table) {
  border-collapse: collapse !important;
  border: 1px solid #ccc !important;
}
.v-data-table :deep(td) {
  border-collapse: collapse !important;
  border: 1px solid #ccc !important;
}
.v-data-table :deep(th) {
  border-collapse: collapse !important;
  border: 1px solid #ccc !important;
  user-select: text !important;
}
.combination-table {
  font: normal normal bold 12px/36px Noto Sans JP;

  th {
    border: 1px solid #cccccc;
  }
  .total {
    .rank-head,
    .rank {
      min-width: 110px;
      width: 110px;
      max-width: 110px;
    }
    .name-head,
    .name {
      min-width: 333px;
      width: 333px;
      max-width: 333px;
    }
    .type-head,
    .type {
      min-width: 200px;
      width: 200px;
      max-width: 200px;
    }
    .ratio-head,
    .ratio {
      min-width: 200px;
      width: 200px;
      max-width: 200px;
    }
  }
  .gender {
    th {
      padding: 0px 7px;
    }
    .rank-head,
    .rank {
      min-width: 40px;
      width: 40px;
      max-width: 40px;
    }
    .name-head,
    .name {
      min-width: 170px;
      width: 170px;
      max-width: 170px;
    }
    .type-head,
    .type {
      min-width: 95px;
      width: 95px;
      max-width: 95px;
    }
    .ratio-head,
    .ratio {
      min-width: 103px;
      width: 103px;
      max-width: 103px;
    }
  }

  .table-header {
    th {
      background-color: #f5f5f5;
      font: normal normal bold 12px/36px Noto Sans JP;
      height: 36px !important;
      line-height: 2em;
    }
    .rank-head {
      text-align: center;
    }
  }
  .base-chain {
    background: #f4fbff 0% 0% no-repeat padding-box;

    th {
      height: 36px !important;
    }

    .base-head {
      background-color: #4d99d0;
      color: #ffffff !important;
      font-weight: bold;
      padding: 0px 8px !important;
      text-align: center;
    }
    .column-ratio {
      text-align: center;
    }
  }
  .table-body {
    th {
      line-height: 2em;
      height: 36px !important;
    }
    .rank,
    .ratio {
      text-align: center;
    }
    .rank {
      background-color: #f5f5f5;
      height: 36px !important;
    }
    .ratio {
      position: relative;
      text-align: center;

      .ratio-text {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0%);
      }
    }
  }
}
.name {
  font-weight: bold !important;
}
.sort_button {
  width: 6.78px;
  height: 12px;
  margin-right: 8.4px;
}
.supermarket {
  color: #e47075;
}
.drugstore {
  color: #87b8a1;
}
.homecenter {
  color: #cb9e56;
}
.cvsstore {
  color: #9278c3;
}
</style>
