<template>
  <v-card class="card">
    <v-card-title
      :class="
        !chainId || isEmpty
          ? 'pa-0 d-flex align-center behavioral-dna-card-title behavioral-dna-card-title-margin'
          : 'pa-0 d-flex align-center behavioral-dna-card-title'
      "
    >
      <span>行動DNA</span>
      <p style="margin-bottom: 0; font-size: 12px; color: #666">
        棒グラフをクリックすると詳細を閲覧できます。
      </p>
      <alert-tooltip
        v-if="hasAlertInBehavioralDnaData"
        class="ml-2"
        text="取得データボリュームが少なく統計上の信頼性が低い日が含まれています。該当日は参考値としてご参照ください。"
      />
      <v-spacer />
      <chart-description-tooltip
        menu-key="chainAnalytics"
        sub-menu-key="persona"
        chart-key="behavioralDna"
        class="mr-27px"
      />
      <download-button
        label="CSVデータ"
        :disabled="!chainId"
        :get-file-id="getL2FileId"
        :csv-name="l2CSVName"
      />
    </v-card-title>
    <v-container
      :class="
        !chainId || isEmpty ? 'pa-0' : 'pa-0 mb-4'
      "
      fluid
    >
      <v-row
        class="my-0"
        dense
      >
        <v-col class="pa-0">
          <div
            v-show="!chainId"
            class="unselected_card unselected_dna_card"
          >
            チェーンを選択するとチャートが表示されます。
          </div>
          <div v-show="chainId">
            <LoadingImg
              v-if="chartLoading"
              :height="'720px'"
            />
            <no-data-chart v-else-if="isEmpty" />
            <div v-else>
              <BehavioralDNAChart
                v-if="!isCompare"
                :chart-data="chartData"
                :height="720"
                :min="min"
                :max="max"
                @select-column="onClickBarChart"
              />
              <BehavioralDNACompareChart
                v-else
                :chart-data="chartData"
                :height="720"
                :min="min"
                :max="max"
                @select-column="onClickBarChart"
              />
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row
        v-if="!isCompare"
        class="my-0"
        dense
      >
        <v-col class="pa-0">
          <div class="my-0 explanation-of-alerts">
            <v-spacer />
            <alert-caption
              v-if="hasAlertInBehavioralDnaData"
              variants="square"
            />
          </div>
        </v-col>
      </v-row>
      <v-row
        v-if="isCompare"
        class="my-0"
        dense
      >
        <v-col>
          <div class="my-0 explanation-of-alerts">
            <v-spacer />
            <alert-caption
              v-if="hasAlertInBehavioralDnaData"
              variants="two_squares"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div
      v-if="selectChart"
      class="divider"
    />
    <v-card-title
      v-show="selectChart"
      class="pa-0 behavioral-dna-level-three-card-title"
    >
      <span>{{ levelThreeTitle }}</span>
      <alert-tooltip
        v-if="hasAlertInSubBehavioralDnaData"
        class="ml-2"
        text="取得データボリュームが少なく統計上の信頼性が低い日が含まれています。該当日は参考値としてご参照ください。"
      />
      <v-spacer />
      <download-button
        label="CSVデータ"
        :disabled="!chainId"
        :get-file-id="getL3FileId"
        :csv-name="l3CSVName"
      />
    </v-card-title>
    <v-container
      class="pa-0"
      fluid
    >
      <v-row
        class="my-0"
        dense
      >
        <v-col class="pa-0">
          <v-row
            v-show="chainId"
            class="ma-0"
          >
            <BehavioralDNAChart
              v-if="selectChart && !isCompare"
              :chart-data="levelThree"
              :height="levelThreeBehavioralDNAChartHeight"
              :min="min"
              :max="max"
              style="margin-bottom: 10px !important"
            />
            <BehavioralDNACompareChart
              v-if="selectChart && isCompare"
              :chart-data="levelThree"
              :height="levelThreeBehavioralDNAChartHeight"
              :min="min"
              :max="max"
              style="margin-bottom: 10px !important"
            />
          </v-row>
        </v-col>
      </v-row>
      <v-row
        v-if="!isCompare"
        class="my-0"
        dense
      >
        <v-col class="pa-0">
          <div class="my-0 explanation-of-alerts">
            <v-spacer />
            <alert-caption
              v-if="hasAlertInSubBehavioralDnaData && selectChart"
              variants="square"
            />
          </div>
        </v-col>
      </v-row>
      <v-row
        v-if="isCompare"
        class="my-0"
        dense
      >
        <v-col class="pa-0">
          <div class="my-0 explanation-of-alerts">
            <v-spacer />
            <alert-caption
              v-if="hasAlertInSubBehavioralDnaData && selectChart"
              variants="two_squares"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import NoDataChart from "@/commons/components/Chart/NoDataChart.vue";
import LoadingImg from "@/commons/components/loadingImg.vue";
import BehavioralDNAChart from "@/features/ChainAnalytics/persona/components/BehavioralDNAChart.vue";
import BehavioralDNACompareChart from "@/features/ChainAnalytics/persona/components/BehavioralDNACompareChart.vue";
// interface
import { AxiosResponse } from "axios";
import { SelectionChart } from "@/features/ChainAnalytics/persona/interfaces/component";
import {
  CompareThirdCategory,
  ThirdCategory,
  CompareBehavioralDnaValue
} from "@/features/ChainAnalytics/persona/interfaces/response";
// util
import {
  processBehavioralDnaThirdCategoryChartData,
  processComparisonBehavioralDnaThirdCategoryChartData,
} from "@/features/ShopAnalytics/utils/persona";
import useChainQueryParams from '@/commons/hooks/use-chain-query-params'
const { chainId } = useChainQueryParams()

import { useStore } from "vuex";
const store = useStore()
const isCompare = computed<boolean>(() => store.getters.isCompare)

const props = withDefaults(
  defineProps<{
    chartData: (string | number)[][],
    chartLoading: boolean,
    thirdCategories: {
      thirdCategory: ThirdCategory[] | CompareThirdCategory[],
      firstCategoryName: string,
      secondCategoryName: string,
    }[],
    isEmpty: boolean,
    getL2FileId: () => Promise<AxiosResponse>,
    getL3FileId: () => Promise<AxiosResponse>,
    l2CSVName: string,
    l3CSVName: string,
  }>(), {
    chartData: () => [],
    chartLoading:  false,
    thirdCategories: () => [],
    isEmpty: false,
    getL2FileId: undefined,
    getL3FileId: undefined,
    l2CSVName: "",
    l3CSVName: ""
  }
)

const selectChart = ref<null | SelectionChart>(null)
const levelThree = ref<any[][]>([])

const thirdCategoriesValues = computed<number[]>(() => {
  return props.thirdCategories.flatMap((c) => {
    if (!isCompare.value) {
      return c.thirdCategory.flatMap((t) =>
        Object.values(t.deviationValue)
      );
    }
    else
      return c.thirdCategory.flatMap((t) => {
        Object.values(t.deviationValue).flatMap(
          (dv: [CompareBehavioralDnaValue, CompareBehavioralDnaValue]) => {
            dv.flatMap((v: CompareBehavioralDnaValue) => v.value)
          }
        )
      });
  });
})
const min = computed<number>(() => {
  return Math.min(
    ...([...props.chartData.flat(), ...thirdCategoriesValues.value].filter(
      (value) => typeof value === "number"
    ) as number[])
  );
})
const max = computed<number>(() => {
  return Math.max(
    ...([...props.chartData.flat(), ...thirdCategoriesValues.value].filter(
      (value) => typeof value === "number"
    ) as number[])
  );
})
const levelThreeTitle = computed<string>(() => {
  if (!!props.thirdCategories && !!selectChart.value) {
    return `${
      props.thirdCategories[selectChart.value.row].firstCategoryName
    } > ${props.thirdCategories[selectChart.value.row].secondCategoryName}`;
  } else {
    return "";
  }
})
const hasAlertInBehavioralDnaData = computed<boolean>(() => {
  if (props.chartData.length === 0) return false;

  if (isCompare.value) {
    return props.chartData.some(
      (value) =>
        value[3] === "#bbb" ||
        value[6] === "#ccc" ||
        value[9] === "#bbb" ||
        value[12] === "#ccc" ||
        value[15] === "#bbb" ||
        value[18] === "#ccc"
    );
  } else {
    return props.chartData.some(
      (value) =>
        value[3] === "#bbb" || value[6] === "#bbb" || value[9] === "#bbb"
    );
  }
})
const hasAlertInSubBehavioralDnaData = computed<boolean>(() => {
  if (levelThree.value.length === 0) return false;

  if (isCompare.value) {
    return levelThree.value.some(
      (value) =>
        value[3] === "#bbb" ||
        value[6] === "#ccc" ||
        value[9] === "#bbb" ||
        value[12] === "#ccc" ||
        value[15] === "#bbb" ||
        value[18] === "#ccc"
    );
  } else {
    return levelThree.value.some(
      (value) =>
        value[3] === "#bbb" || value[6] === "#bbb" || value[9] === "#bbb"
    );
  }
})
const levelThreeBehavioralDNAChartHeight = computed<number>(() => {
  if (levelThree.value.length <= 0) return 0;
  return levelThree.value.length * 34 + 80;
})

function onClickBarChart(e: SelectionChart) {
  // 左右中央でそれぞれ独立しているので、同じ行数のチャートが選択された場合もサブチャートを非表示にする
  if (!e || e?.row === selectChart.value?.row) {
    selectChart.value = null;
    return;
  }

  if (e.row === null) {
    selectChart.value = null;
    return;
  }

  selectChart.value = e;
  if (!isCompare.value) {
    levelThree.value = processBehavioralDnaThirdCategoryChartData(
      levelThreeTitle.value,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      props.thirdCategories[selectChart.value.row].thirdCategory,  
    );
  } else {
    levelThree.value = processComparisonBehavioralDnaThirdCategoryChartData(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      props.thirdCategories[selectChart.value.row].thirdCategory,
      store.state.startDate,
      store.state.endDate,
      store.state.compareStartDate,
      store.state.compareEndDate
    );
  }
}
function resetSelectChart() {
  selectChart.value = null;
}

watch(isCompare, () => {selectChart.value = null})
defineExpose({ resetSelectChart })
</script>

<style scoped>
.behavioral-dna-card-title {
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
}

.behavioral-dna-card-title span {
  font-size: 16px;
  font-weight: bold;
  line-height: 30px;
  margin-right: 20px;
}

.behavioral-dna-card-title-margin {
  margin-bottom: 30px;
}

.behavioral-dna-level-three-card-title {
  display: flex;
  align-items: center;
  height: 30px;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 20px;
}

.behavioral-dna-level-three-card-title span {
  font-size: 14px;
  font-weight: bold;
  line-height: 30px;
}

.explanation-of-alerts {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #666;
}

.divider {
  border-bottom: 5px solid #eee;
  margin: 0 -30px 0 -30px;
}

.unselected_dna_card {
  height: 720px;
}

.mr-27px {
  margin-right: 27px;
}
</style>
