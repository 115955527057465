<template>
  <v-card height="'100%'" class="chain-selector-card">
    <v-container fluid class="pa-0">
      <div class="chain-selector-card-title">
        <span>分析したいチェーンを選んでください。</span>
        <PrefectureDialog
          :prefectures="filteredPrefectures.filter((prefecture) => prefecture.storeCount > 0)"
          :filter="searchFilters"
          :handle-filter="handleFilter"
        />
      </div>
      <LoadingImg v-if="isLoading" height="44px" />
      <div v-else>
        <AutoComplete
          :initial-word="selectedChain?.name ?? ''"
          :chains="chainsWithOpenStores"
          :handle-update-chain="handleUpdateChain"
        />
        <div v-if="selectedChain" class="selected-chain">
          <span class="dot">●</span>
          {{ selectedChain.name }}
          <StoreDialog
            :stores="
              selectedChain.stores.filter((store) =>
                searchFilters.prefectureIds.includes(store.prefectureId)
              )
            "
          />
        </div>
      </div>
    </v-container>
  </v-card>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import AutoComplete from './AutoComplete.vue'
import { Chain } from '@/commons/interfaces'
import { Store } from '@/commons/interfaces/responses/store'
import PrefectureDialog from './PrefectureDialog.vue'
import { ChainFilter, OpenCloseDict } from './types'
import StoreDialog from './StoreDialog.vue'
import { getPrefecturesWithStoreCount } from '@/commons/utils/prefecture'
import { Prefecture, PrefectureWithStoreCount } from '@/features/Dashboard/types'
import { useStore } from 'vuex'
import LoadingImg from '../loadingImg.vue'
import { isEqual, cloneDeep } from 'lodash'

/* --------------------------------------------------------------------------
  props
 ---------------------------------------------------------------------------*/

const props = withDefaults(
  defineProps<{
    initialChainId?: string | null
    initialPrefectureIds?: number[]
    startDate: string
    endDate: string
    shouldUpdateVuex?: boolean
    handleUpdate: (chain: Chain, prefectureIds: number[]) => void
  }>(),
  {
    initialChainId: undefined,
    initialPrefectureIds: (): number[] => [],
    startDate: '',
    endDate: '',
    shouldUpdateVuex: true,
    handleUpdate: () => undefined
  }
)

/* --------------------------------------------------------------------------
  Vuex
 ---------------------------------------------------------------------------*/

const store = useStore()
const chains = computed<Chain[]>(() => store.getters.chains)
const prefectures = computed<Prefecture[]>(() => store.state.prefectures)

/* --------------------------------------------------------------------------
  core logic
 ---------------------------------------------------------------------------*/

// loading
const isLoading = ref<boolean>(true)

// 選択済みのチェーン
const selectedChain = ref<Chain | null>(null)

// 検索条件フィルター
const searchFilters = ref<ChainFilter>({ prefectureIds: [] })

// 店舗一覧と都道府県から各都道府県の店舗数を追加した都道府県データ
const filteredPrefectures = computed<PrefectureWithStoreCount[]>(() => {
  if (!selectedChain.value) return []
  return getPrefecturesWithStoreCount(prefectures.value, selectedChain.value.stores)
})

// 指定期間に開店している店舗を返しサジェストの内容・選択済みチェーンを更新
const chainsWithOpenStores = computed<Chain[]>(() => {
  const startDate = new Date(props.startDate)
  const endDate = new Date(props.endDate)

  // チェーンごとに店舗をフィルタリング
  const filteredChains = chains.value.map((chain) => {
    const openStores = filterOpenStores(chain.stores, startDate, endDate)
    return { ...chain, stores: openStores }
  })

  return filteredChains
})

// チェーン更新関数
const handleUpdateChain = (chain: Chain) => {
  selectedChain.value = chain
  searchFilters.value.prefectureIds = chain.prefectureIds
  props.handleUpdate(chain, chain.prefectureIds)

  if (props.shouldUpdateVuex) {
    store.commit('setSelectedChainId', {
      chainId: chain.id
    })
    store.commit('setSelectedPrefectureIdsOfChain', {
      prefectureIds: chain.prefectureIds
    })
  }
}

// フィルタリング関数
const handleFilter = (filter: ChainFilter) => {
  searchFilters.value.prefectureIds = filter.prefectureIds
  if (selectedChain.value) {
    props.handleUpdate(selectedChain.value, searchFilters.value.prefectureIds)
  }

  if (props.shouldUpdateVuex) {
    store.commit('setSelectedPrefectureIdsOfChain', {
      prefectureIds: filter.prefectureIds
    })
  }
}

// 日付が範囲内に収まるかどうかを判定
const isDateInRange = (dateRange: OpenCloseDict, startDate: Date, endDate: Date) => {
  const lowerDate = dateRange.lower ? new Date(dateRange.lower) : null
  const upperDate = dateRange.upper ? new Date(dateRange.upper) : null

  return (
    (lowerDate === null || lowerDate <= endDate) && (upperDate === null || upperDate >= startDate)
  )
}

// 特定のチェーンの店舗が指定期間内で開店しているかを判定
const filterOpenStores = (stores: Store[], startDate: Date, endDate: Date) => {
  return stores.filter((store) => {
    return store.openCloseDates.some((dateRange) => isDateInRange(dateRange, startDate, endDate))
  })
}

// 選択済みチェーンの内容を更新
const updateSelectedChain = () => {
  if (selectedChain.value) {
    const selectedChainInFilteredChains = chainsWithOpenStores.value.find(
      (chain) => chain.id === selectedChain.value?.id
    )

    if (
      selectedChainInFilteredChains &&
      !isEqual(selectedChain.value.stores, selectedChainInFilteredChains.stores)
    ) {
      selectedChain.value = selectedChainInFilteredChains
    }
  }
}

/* --------------------------------------------------------------------------
  created
 ---------------------------------------------------------------------------*/

const handleCreated = async () => {
  isLoading.value = true

  try {
    // チェーン(生成元の店舗)が未取得の場合は店舗を取得
    if (!chains.value.length) await store.dispatch('fetchStores')

    // 都道府県が未取得の場合は都道府県を取得
    if (!prefectures.value.length) await store.dispatch('fetchPrefectures')

    // チェーンの初期値が設定されている場合は選択済みチェーンを更新
    if (props.initialChainId) {
      selectedChain.value = chains.value.find((chain) => chain.id === props.initialChainId) ?? null
    }

    // 都道府県の初期値が設定されている場合は選択済みの都道府県を更新
    if (props.initialPrefectureIds.length > 0) {
      searchFilters.value = { ...searchFilters.value, prefectureIds: props.initialPrefectureIds }
    }

    // Vuex を更新する場合は初期値のチェーンと都道府県を更新
    if (props.shouldUpdateVuex) {
      if (props.initialChainId) {
        store.commit('setSelectedChainId', { chainId: props.initialChainId })
      }

      if (props.initialPrefectureIds) {
        store.commit('setSelectedPrefectureIdsOfChain', {
          prefectureIds: props.initialPrefectureIds
        })
      }
    }

    updateSelectedChain()
  } finally {
    isLoading.value = false
  }
}

handleCreated()

/* --------------------------------------------------------------------------
  watch
 ---------------------------------------------------------------------------*/

watch(
  () => chainsWithOpenStores.value,
  () => {
    updateSelectedChain()
  }
)
</script>

<style scoped>
.chain-selector-card {
  padding: 23px 30px 29px !important;
}

.chain-selector-card-title {
  margin-bottom: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  width: 100%;
  font-size: 15px;
  font-weight: bold;
}

.chain-selector-card-title a {
  color: #4d99d0;
}

.selected-chain {
  height: 42px;
  width: fit-content;
  margin-top: 12px;
  padding: 10px 25px 13px 13px;
  font-size: 14px;
  font-weight: bold;
  text-align: 25px;
  color: #333;
  background: #f5f5f5;
  border-radius: 6px;
  display: flex;
  align-items: center;
}
.dot {
  margin-right: 4px;
  color: #de5a69;
}
</style>
