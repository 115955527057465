<template>
  <v-container
    class="pa-0"
    fluid
  >
    <div class="inner-container">
      <div class="title">
        FAQ・お問い合わせ
      </div>
      <div class="description">
        お問い合わせの前に、まずはよくあるご質問をご確認ください。
      </div>
      <div class="content">
        <div class="faq-content">
          <expansion-panel class="content-panels">
            <template #title>
              <span class="panel-label-head">
                Q.&nbsp;
                <span class="panel-label-title">
                  分析したい店舗が登録されていない、閉店や休店が反映されていない
                </span>
              </span>
            </template>
            <template #content>
              追加したい店舗や閉店・休店を反映したい店舗がございましたら、店舗名と住所、ご希望内容（追加・閉店・休店・移転など）を下記お問い合わせフォームよりお知らせください。<br>
              また、店舗数が多い場合はその旨をご連絡ください。店舗登録用のファイルをお送りいたしますので、店舗情報を記入いただき、ご返送くださいますようよろしくお願いいたします。
            </template>
          </expansion-panel>
          <expansion-panel class="content-panels">
            <template #title>
              <span class="panel-label-head">
                Q.
                <span class="panel-label-title">
                  データが更新されるタイミングはいつですか？
                </span>
              </span>
            </template>
            <template #content>
              来店人数推移、曜日/時間別推移、ペルソナ特性につきましては毎週月曜日に前々週の日曜日までのデータとなるよう更新を行なっております。<br>
              来店特性、商圏分析、店舗別シェア、併用分析、トレンドにつきましては月次で更新を行なっており、毎月8日に前月を含む過去13ヶ月間のデータに更新されます。
            </template>
          </expansion-panel>
          <expansion-panel class="content-panels">
            <template #title>
              <span class="panel-label-head">
                Q.
                <span class="panel-label-title"> ユーザーを追加したい </span>
              </span>
            </template>
            <template #content>
              ユーザーの追加をご希望の際は、下記手順にて新規ユーザーをご登録ください。<br>
              ご契約いただいているアカウント数までは、ご自身で招待して追加いただけます。<br>
              <br>
              1.「ユーザー設定」＞「ユーザー一覧」＞「＋ユーザーの新規追加」より追加ご希望のユーザー情報（部署名・氏名・メールアドレス）を入力し、「招待メールを送信」をクリックします。<br>
              2.招待メールに従ってパスワードを設定し、ユーザーの登録を完了させます。
            </template>
          </expansion-panel>
          <expansion-panel class="content-panels">
            <template #title>
              <span class="panel-label-head">
                Q.
                <span class="panel-label-title">
                  メールアドレスを変更したい
                </span>
              </span>
            </template>
            <template #content>
              メールアドレスの変更はできません。
              メールアドレスの変更をご希望される場合は、現在ご使用いただいているアカウントを停止し、新規アカウントを発行する形でのご対応となります。<br>
              メールアドレス変更をご希望の際は、下記手順にてご対応ください。<br>
              <br>
              1.「ユーザー設定」＞「ユーザー一覧」より現在使用されているアカウントを削除します。<br>
              2.「＋ユーザーの新規追加」より、ユーザー情報（部署名・氏名・メールアドレス）を入力し、「招待メールを送信」をクリックします。<br>
              3.招待メールに従ってパスワードを設定し、ユーザーの登録を完了させます。<br>
            </template>
          </expansion-panel>
          <expansion-panel class="content-panels">
            <template #title>
              <span class="panel-label-head">
                Q.
                <span class="panel-label-title"> ショッパーみえーるのプランについて知りたい </span>
              </span>
            </template>
            <template #content>
              ショッパーみえーるは、全国の店舗やチェーンを自由に検索し分析できるダッシュボードサービスです。<br>
              プランは、以下の2つあります。<br>
              ・店舗ごとの詳細な商圏・来店人数などが分析できる「よくみえーるプラン」<br>
              ・チェーン×地域やエリア別消費者トレンドがわかる「ひろくみえーるプラン」<br>
              <br>
              また、業態（スーパー、ドラッグストア、ホームセンター）とエリア（関東、北海道 ・東北、中部・甲信越、関西、中国・四国、四国・沖縄、全国一括）を選択いただき、企業ごとのお申し込みをいただいています。<br>
            </template>
          </expansion-panel>
          <expansion-panel class="content-panels">
            <template #title>
              <span class="panel-label-head">
                Q.
                <span class="panel-label-title"> メニュープランや契約業態、エリアなどを変更したい </span>
              </span>
            </template>
            <template #content>
              変更されたい内容を担当営業、またはお問い合わせフォームよりご連絡をください。<br>
              改めてプランのご説明をご要望の場合もその旨ご連絡ください。<br>
            </template>
          </expansion-panel>
        </div>
        <div class="contact-form-content">
          <span class="contact-form-head">上記で解決しない場合、フォームよりお問い合わせください。</span>
          <a
            class="contact-form-link"
            href="https://mkt.unerry.co.jp/form/inquirymieru"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div class="contact-form-link-box">
              <span class="contact-form-link-text">
                「ショッパーみえーる」お問い合わせフォーム
              </span>
              <img src="@/assets/svg/external-link.svg">
            </div>
          </a>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ExpansionPanel from "@/commons/components/ExpansionPanel.vue";
export default defineComponent({
  name: "FaqView",
  components: {
    ExpansionPanel,
  },
});
</script>

<style lang="scss" scoped>
.inner-container {
  margin: 30px 0px;
  overflow: hidden;
  color: #222222;
  padding: 47px 60px 60px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 3px #00000029;

  .title {
    text-align: left;
    font: normal normal 700 24px/1 Noto Sans JP!important;
    margin-bottom: 40px;
    letter-spacing: .0125em!important;
  }
  .description {
    text-align: left;
    font: normal normal normal 14px/1 Noto Sans JP;
    margin-bottom: 30px;
  }
  .content {
    text-align: left;
    font: normal normal normal 13px/1 Noto Sans JP;

    .faq-content {
      margin-bottom: 60px;

      .content-panels {
        margin-bottom: 20px;

        .panel-label-head {
          font: normal normal bold 18px/1 Noto Sans JP;
          color: #4d99d0;
          display: flex;
          align-items: center;
        }
        .panel-label-title {
          font: normal normal bold 14px/1 Noto Sans JP;
          color: #222222;
        }
        .panel-content {
          font: normal normal normal 13px/1 Noto Sans JP;
        }
      }
    }
    .contact-form-content {
      width: 100%;
      height: 220px;
      background: #e9eff2 0% 0% no-repeat padding-box;
      border-radius: 4px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .contact-form-head {
        font: normal normal bold 14px/30px Noto Sans JP;
        color: #222222;
        margin-bottom: 20px;
      }
      .contact-form-link {
        text-decoration-line: none;

        .contact-form-link-box {
          margin: 0 auto;
          width: 480px;
          height: 60px;
          background: #0e182e 0% 0% no-repeat padding-box;
          box-shadow: 1px 1px 0px #00000033;
          border-radius: 4px;

          display: flex;
          align-items: center;

          .contact-form-link-text {
            font: normal normal bold 15px/24px Noto Sans JP;
            color: #ffffff;

            margin-left: 83px;
            margin-right: 51px;
          }
        }
      }
    }
  }
}
</style>
