<template>
  <div class="map-container">
    <v-overlay
      contained
      :model-value="loading"
      class="align-center justify-center"
      style="z-index: 4"
      :persistent="true"
      :no-click-animation="true"
    >
      <LoadingImg
        v-if="loading"
        :height="'600px'"
      />
    </v-overlay>
    <div
      v-if="!initialCenterPoint"
      class="map-no-store-overlay"
    >
      <span>基準となる条件を選択してください</span>
    </div>
    <MglMap
      v-else
      ref="bizAreaMap"
      class="map-height"
      :access-token="mapAccessToken"
      :map-style="mapStyle"
      :center="initialCenterPoint"
      :zoom="zoom"
      :attribution-control="false"
      :logo-position="'bottom-right'"
      :drag-rotate="false"
      :touch-zoom-rotate="false"
      :pitch-with-rotate="false"
      @load="initMap"
      @idle="idleEvent"
    >
      <!-- 全店舗 -->
      <MglMarker
        v-for="store in selectedStores"
        :key="store.id"
        :coordinates="[store.longitude, store.latitude]"
        @click="clickStoreMarker(store)"
        @mouseenter="showStorePopup(store)"
        @mouseleave="isStoreMarkerMouseEnter = false"
      >
        <template #marker>
          <img
            class="marker-img"
            :src="getStoreMarkerImageName(store)"
          >
        </template>
        <MglPopup
          :showed="storePopupShowedMap[store.id] && !loading"
          :coordinates="storePopupCoordinates"
          :close-button="false"
          :close-on-click="false"
          :offset="10"
        >
          <BizAreaStorePopup
            :title="storePopupName"
            :store-color="storePopupStoreColor"
            :store-type-text="storePopupStoreTypeText"
            @close-popup="closeStorePopup"
            @change-store="changeStore"
          />
        </MglPopup>
      </MglMarker>
      <!-- 表示目安範囲 -->
      <MglGeojsonLayer
        v-if="viewRadiusLayer"
        ref="viewRadius"
        :source-id="viewRadiusSource?.data.id"
        :source="viewRadiusSource"
        :layer-id="viewRadiusLayer.id"
        :layer="viewRadiusLayer"
        :clear-source="true"
      />
      <!-- データ補足範囲 -->
      <MglGeojsonLayer
        v-if="radiusLayer"
        ref="radius"
        :source-id="radiusSource?.data.id"
        :source="radiusSource"
        :layer-id="radiusLayer.id"
        :layer="radiusLayer"
        :clear-source="true"
      />
      <!-- 商圏マップ -->
      <MglGeojsonLayer
        v-if="bizAreaLayer"
        ref="bizArea"
        :source-id="sourceId"
        :source="bizAreaSource"
        :layer-id="layerId"
        :layer="bizAreaLayer"
        :clear-source="true"
        @click="showOrCloseMapPopup"
      />
      <MglPopup
        :showed="hideMapPopupShowed && !loading"
        :coordinates="mapPopupCoordinates"
        anchor="bottom-left"
        :close-button="false"
        :close-on-click="false"
      >
        <BizAreaMapPopup
          :area-name="mapPopupAreaName"
          :store-name="mapPopupStoreName"
          :store-color="mapPopupStoreColor"
          :share="mapPopupVisitRatio"
          @close-popup="closeMapPopup"
        />
      </MglPopup>
      <MglAttributionControl />
      <MglNavigationControl
        :show-compass="false"
        position="top-right"
      />
      <MglScaleControl position="top-left" />
      <custom-circle
        v-model:v-radius="vRadius"
        @set-v-radius="updateViewRadius"
      />
    </MglMap>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import {
  MglMap,
  MglMarker,
  MglGeojsonLayer,
  MglAttributionControl,
  MglNavigationControl,
  MglScaleControl,
  MglPopup,
} from "@/vendor/vue-mapbox/main";
import CustomCircle from "@/features/ShopAnalytics/components/BizArea/customCircle.vue";
import LoadingImg from "@/commons/components/loadingImg.vue";

import { ComparisonMapBoxFeatures } from "@/features/StoreCompare/bizArea/interfaces/response";
import { ComparisonGroupStore } from "@/features/StoreCompare/types";
import { COLOR, STORE_TYPE } from "@/commons/enums";
import { MAP_ACCESS_TOKEN, MAP_STYLE } from "@/config";
import BizAreaStorePopup from "@/features/StoreCompare/bizArea/components/BizAreaStorePopup.vue";
import BizAreaMapPopup from "@/features/StoreCompare/bizArea/components/BizAreaMapPopup.vue";
import { Store } from "@/commons/interfaces/responses/store";

import radioImgRedSelect from "@/assets/svg/StoreCompare/BizArea/radiobox-red-select.svg";
import radioImgRedNoSelect from "@/assets/svg/StoreCompare/BizArea/radiobox-red-no-select.svg";
import radioImgBuleSelect from "@/assets/svg/StoreCompare/BizArea/radiobox-blue-select.svg";
import radioImgBuleNoSelect from "@/assets/svg/StoreCompare/BizArea/radiobox-blue-no-select.svg";
import radioImgBrownSelect from "@/assets/svg/StoreCompare/BizArea/radiobox-brown-select.svg";
import radioImgBrownNoSelect from "@/assets/svg/StoreCompare/BizArea/radiobox-brown-no-select.svg";
import radioImgGreenSelect from "@/assets/svg/StoreCompare/BizArea/radiobox-green-select.svg";
import radioImgGreenNoSelect from "@/assets/svg/StoreCompare/BizArea/radiobox-green-no-select.svg";
import radioImgOrangeSelect from "@/assets/svg/StoreCompare/BizArea/radiobox-orange-select.svg";
import radioImgOrangeNoSelect from "@/assets/svg/StoreCompare/BizArea/radiobox-orange-no-select.svg";
import radioImgPurpleSelect from "@/assets/svg/StoreCompare/BizArea/radiobox-purple-select.svg";
import radioImgPurpleNoSelect from "@/assets/svg/StoreCompare/BizArea/radiobox-purple-no-select.svg";

const radioImgPath: Record<string, Record<string, string>> = {
  select: {
    red: radioImgRedSelect,
    blue: radioImgBuleSelect,
    brown: radioImgBrownSelect,
    green: radioImgGreenSelect,
    orange: radioImgOrangeSelect,
    purple: radioImgPurpleSelect,
  },
  noSelect: {
    red: radioImgRedNoSelect,
    blue: radioImgBuleNoSelect,
    brown: radioImgBrownNoSelect,
    green: radioImgGreenNoSelect,
    orange: radioImgOrangeNoSelect,
    purple: radioImgPurpleNoSelect,
  },
}

type Source = {
  id?: string;
  type: string;
  data: {
    id?: string;
    type: string;
    features: {
      type: string;
      geometry: {
        type: string;
        coordinates: number[] | undefined;
      };
      properties?: {
        icon: string;
        store: Store;
      };
    }[];
  };
  cluster: boolean;
  clusterMaxZoom: number;
  clusterRadius: number;
};

type Layer = {
  id: string | undefined;
  type: string;
  source: Source | undefined;
  layout?: any;
  paint?: any;
  filter: any;
};

export default defineComponent({
  name: "BizAreaStoreCompareMap",
  components: {
    BizAreaStorePopup,
    BizAreaMapPopup,
    LoadingImg,
    MglMap,
    MglMarker,
    MglGeojsonLayer,
    MglAttributionControl,
    MglNavigationControl,
    MglScaleControl,
    MglPopup,
    CustomCircle,
  },
  props: {
    baseStore: {
      type: Object as PropType<ComparisonGroupStore | undefined>, default: undefined,
    },
    baseStorePoint: {
      type: Array as PropType<number[] | undefined>, default: undefined,
    },
    selectedStores: {
      type: Array as PropType<ComparisonGroupStore[]>, default: () =>{return []},
    },
    radius: {
      type: Number as PropType<number>, required: true,
    },
    viewRadius: {
      type: Number as PropType<number>, required: true,
    },
    featureCollection: {
      type: Array as PropType<ComparisonMapBoxFeatures[]>,
      default() {
        return [];
      },
    },
    maxRatio: {
      type: Number,
      default:() => {
        return 1;
      },
    },
    loading: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  emits: ["pushRoute"],
  data() {
    return {
      colors: COLOR,
      mapAccessToken: MAP_ACCESS_TOKEN,
      mapStyle: MAP_STYLE,
      zoom: 11.75,
      initialCenterPoint: undefined as number[] | undefined,
      initializedMap: false,
      storePopupStoreId: undefined,
      storePopupCoordinates: [0, 0] as number[],
      storePopupName: "",
      storePopupStoreColor: "",
      storePopupStoreType: 0,
      storePopupStoreTypeText: undefined,
      storePopupShowedMap: {} as { [key: string]: boolean },
      isStoreMarkerMouseEnter: false,
      mapPopupCoordinates: [0, 0] as number[],
      mapPopupAreaName: "",
      mapPopupStoreName: "",
      mapPopupStoreColor: "",
      mapPopupVisitRatio: 0.0,
      hideMapPopupShowed: true,
      storeTypes: STORE_TYPE,
      radiusSource: null as Source | null,
      radiusLayer: null as Layer | null,
      viewRadiusSource: null as Source | null,
      viewRadiusLayer: null as Layer | null,
      sourceId: "bizAreaSourceId",
      layerId: "bizAreaLayerId",
      bizAreaLayer: null,
      bizAreaSource: null,
      map: null,
    };
  },
  computed: {
    vRadius: {
      get() {
        return (this as any).viewRadius;
      },
      set(newVal: [number | null]) {
        (this as any).$emit("update:viewRadius", newVal);
      },
    },
    // NOTE: 監視用
    selectedComparisonGroup() {
      return this.$store.state.selectedComparisonGroup;
    },
  },
  watch: {
    selectedStores(newVal: ComparisonGroupStore[]) {
      const me = this as any;
      newVal.forEach((value) => {
        me.storePopupShowedMap[value.id] = false;
      });
    },
    baseStore() {
      const me = this as any;
      if (me.viewRadiusSource) me.updateViewRadius();
    },
    featureCollection: {
      handler() {
        const me = this as any;
        me.updateBizArea();
        me.updateRadius();
      },
      deep: true,
    },
    baseStorePoint(newVal: number[]) {
      const me = this as any;
      if (me.map) {
        me.map.flyTo({
          // These options control the ending camera position: centered at
          // the target, at zoom level 9, and north up.
          center: newVal,
          zoom: Math.max(me.map.getZoom(), me.zoom),
          bearing: 0,
          curve: 1,
          essential: true,
        });
      }
      if (!me.initialCenterPoint) me.initialCenterPoint = me.baseStorePoint;
    },
    selectedComparisonGroup() {
      const me = this as any;
      Object.keys(me.storePopupShowedMap).forEach(
        (key) => (me.storePopupShowedMap[key] = false)
      );
    },
  },
  updated() {
    this.$nextTick(function () {
      const me = this as any;
      if (!me.initialCenterPoint && this.baseStorePoint) {
        me.initialCenterPoint = this.baseStorePoint;
      }
      if (!me.initialCenterPoint) {
        if (me.map)
          me.map.flyTo({
            center: this.baseStorePoint,
            zoom: Math.max(me.map.getZoom(), me.zoom),
            bearing: 0,
            curve: 1,
            essential: true,
          });
        if (!me.initialCenterPoint) me.initialCenterPoint = me.baseStorePoint;
      }
    });
  },
  methods: {
    initMap(event: any) {
      this.map = event.map;
    },
    idleEvent() {
      const me = this as any;
      if (!me.bizAreaSource && me.featureCollection.length > 0)
        me.createBizArea();
    },
    updateBizArea() {
      const me = this as any;
      if (me.$refs.bizArea) me.resetBizArea();
      else me.createBizArea();
    },
    createBizArea() {
      (async () => {
        await new Promise((resolve) => setTimeout(resolve, 0));
        const me = this as any;
        me.bizAreaSource = {
          type: "geojson",
          data: {
            id: me.sourceId,
            type: "FeatureCollection",
            features: me.featureCollection,
          },
        };
        // 通常のレイヤー
        me.bizAreaLayer = {
          id: me.layerId,
          type: "fill",
          source: me.bizAreaSource,
          layout: {},
          paint: {
            "fill-color": [
              "match",
              ["get", "maxColor"],
              COLOR.RED,
              COLOR.RED,
              COLOR.BLUE,
              COLOR.BLUE,
              COLOR.GREEN,
              COLOR.GREEN,
              COLOR.ORANGE,
              COLOR.ORANGE,
              COLOR.PURPLE,
              COLOR.PURPLE,
              COLOR.BROWN,
              COLOR.BROWN,
              COLOR.GRAY,
            ],
            "fill-opacity": 0.6,
          },
        };
        if (me.map && me.featureCollection.length > 0) {
          await new Promise((resolve) => setTimeout(resolve, 0));
          // 処理順の関係でデータ補足範囲と距離目安の円がエリアの下に描画されるケースがあるため最上位に呼び出す
          me.map.moveLayer("radiusLayer");
          me.map.moveLayer("viewRadiusLayer");
        }
      })();
    },
    resetBizArea() {
      const me = this as any;
      try {
        me.map.removeSource(me.sourceId);
        if (me.map.getLayer(me.layerId)) {
          me.map.removeLayer(me.layerId);
          me.map.removeSource(me.layerId);
        }
      } catch (error) {
        console.error(error);
        throw new Error("Failed to delete layer.");
      }
      me.bizAreaSource = null;
      me.bizAreaLayer = null;
    },
    updateRadius() {
      const me = this as any;
      if (me.$refs.radius) me.resetRadius();
      else me.createRadius();
    },
    createRadius() {
      const me = this as any;
      me.radiusSource = {
        type: "geojson",
        data: {
          id: "radiusSource",
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "Polygon",
                coordinates: [me.createCirclePolygon(me.radius)],
              },
            },
          ],
        },
      };
      me.radiusLayer = {
        id: "radiusLayer",
        type: "line",
        source: me.radiusSource,
        layout: {},
        paint: {
          "line-width": 2,
          "line-color": "#666",
          "line-dasharray": [1, 2],
        },
      };
    },
    resetRadius() {
      const me = this as any;
      if (!me.radiusSource) return;
      try {
        me.map.removeSource(me.radiusSource.data.id);
        if (me.map.getLayer(me.radiusLayer.id)) {
          me.map.removeLayer(me.radiusLayer.id);
          me.map.removeSource(me.radiusLayer.id);
        }
      } catch (error) {
        console.error(error);
        throw new Error("Failed to delete layer.");
      }

      me.radiusSource = null;
      me.radiusLayer = null;

      if (me.radius) {
        (async () => {
          // NOTE: ここで非同期処理を止めないとGeojsonLayerコンポーネントがエラーを吐くので止めている
          await new Promise((resolve) => setTimeout(resolve, 0));
          me.createRadius();
        })();
      }
    },
    updateViewRadius() {
      const me = this as any;
      if (me.$refs.viewRadius) me.resetViewRadius();
      else me.createViewRadius();
      // クエリパラメータ付与
      this.$emit("pushRoute");
    },
    createViewRadius() {
      const me = this as any;
      me.viewRadiusSource = {
        type: "geojson",
        data: {
          id: "viewRadiusSource",
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "Polygon",
                coordinates: [me.createCirclePolygon(me.viewRadius)],
              },
            },
          ],
        },
      };
      me.viewRadiusLayer = {
        id: "viewRadiusLayer",
        type: "line",
        source: me.viewRadiusSource,
        layout: {},
        paint: {
          "line-width": 4,
          "line-color": "#ff7f78",
        },
      };
    },
    resetViewRadius() {
      const me = this as any;
      try {
        me.map.removeSource(me.viewRadiusSource.data.id);
        if (me.map.getLayer(me.viewRadiusLayer.id)) {
          me.map.removeLayer(me.viewRadiusLayer.id);
          me.map.removeSource(me.viewRadiusLayer.id);
        }
      } catch (error) {
        console.error(error);
        throw new Error("Failed to delete layer.");
      }

      me.viewRadiusSource = null;
      me.viewRadiusLayer = null;
      if (me.viewRadius) {
        (async () => {
          // NOTE: ここで非同期処理を止めないとGeojsonLayerコンポーネントがエラーを吐くので止めている
          await new Promise((resolve) => setTimeout(resolve, 0));
          me.createViewRadius();
        })();
      }
    },
    createCirclePolygon(rad: number) {
      const me = this as any;
      const points = 64;
      const coords = {
        latitude: me.baseStorePoint[1],
        longitude: me.baseStorePoint[0],
      };

      const radiusPerKm = rad;
      const distanceX =
        radiusPerKm / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
      const distanceY = radiusPerKm / 110.574;

      const ret = [];
      let theta, x, y;
      for (let i = 0; i < points; i++) {
        theta = (i / points) * (2 * Math.PI);
        x = distanceX * Math.cos(theta);
        y = distanceY * Math.sin(theta);

        ret.push([coords.longitude + x, coords.latitude + y]);
      }
      ret.push(ret[0]);
      return ret;
    },
    getStoreMarkerImageName(store: ComparisonGroupStore) {
      const select = store.id == this.baseStore?.id ? "select" : "noSelect";
      return radioImgPath[select][this.getColorNameFromHex(store.color!)];
    },
    getColorNameFromHex(colorHex: string): string {
      switch (colorHex) {
        case COLOR.RED:
          return "red";
        case COLOR.BLUE:
          return "blue";
        case COLOR.GREEN:
          return "green";
        case COLOR.ORANGE:
          return "orange";
        case COLOR.PURPLE:
          return "purple";
        case COLOR.BROWN:
          return "brown";
        default:
          return "";
      }
    },
    clickStoreMarker(store: ComparisonGroupStore) {
      const me = this as any;
      me.$emit("switchBaseStore", { storeId: store.id });
    },
    changeStore() {
      const me = this as any;
      me.$emit("switchBaseStore", { storeId: me.storePopupStoreId });
      me.closeStorePopup();
      me.closeMapPopup();
    },
    showStorePopup(store: ComparisonGroupStore) {
      const me = this as any;
      me.storePopupStoreId = store.id;
      me.storePopupCoordinates = [store.longitude, store.latitude];
      me.storePopupName = store.name;
      me.storePopupStoreColor = store.color;
      me.storePopupStoreType = store.storeType;
      me.storePopupStoreTypeText = me.storeTypes.toLocalString(store.storeType);
      Object.keys(me.storePopupShowedMap).forEach(
        (key) => (me.storePopupShowedMap[key] = false)
      );
      me.storePopupShowedMap[store.id] = true;
      me.isStoreMarkerMouseEnter = true;
    },
    closeStorePopup() {
      const me = this as any;
      Object.keys(me.storePopupShowedMap).forEach(
        (key) => (me.storePopupShowedMap[key] = false)
      );
      me.storePopupStoreId = undefined;
      me.storePopupName = "";
      me.storePopupStoreColor = "";
      me.storePopupStoreType = 0;
      me.storePopupStoreTypeText = undefined;
    },
    showMapPopup(e: any) {
      const me = this as any;
      me.hideMapPopupShowed = true;
      me.mapPopupCoordinates = [
        e.mapboxEvent.features[0].properties.longitude,
        e.mapboxEvent.features[0].properties.latitude,
      ];
      me.mapPopupAreaName = e.mapboxEvent.features[0].properties.areaName;
      me.mapPopupStoreName = e.mapboxEvent.features[0].properties.maxStoreName;
      me.mapPopupStoreColor = e.mapboxEvent.features[0].properties.maxColor;
      me.mapPopupVisitRatio =
        e.mapboxEvent.features[0].properties.maxVisitRatio;
      me.hideMapPopupShowed = false;
    },
    closeMapPopup() {
      const me = this as any;
      me.hideMapPopupShowed = true;
      me.mapPopupAreaName = "";
      me.mapPopupStoreName = "";
      me.mapPopupStoreColor = "";
      me.mapPopupVisitRatio = 0.0;
      me.mapPopupCoordinates = [0, 0];
    },
    showOrCloseMapPopup(e: any) {
      const me = this as any;
      me.hideMapPopupShowed && !me.isStoreMarkerMouseEnter ? me.showMapPopup(e) : me.closeMapPopup();
    },
  },
});
</script>

<style scoped lang="scss">
.map-container {
  height: 600px;
  position: relative;
  .map-no-store-overlay {
    position: absolute;
    background: #eeeeee;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.marker-img {
  box-shadow: 1px 1px 1px #00000029;
  border-radius: 100px;
}
</style>
