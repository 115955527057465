<template>
  <v-dialog v-model="showDialogValue" width="1040" max-height="90vh" class="dialog-content-outer">
    <div class="dialog-content">
      <div v-if="isYoku" class="dialog-content-block dialog-content-margin">
        <RouterLink class="div-button"
          :to="getRouteLocationRaw('Dashboard')"
          @click="() => (showDialogValue = false)"
        >
          <div class="link-head mb-0">
            <div class="page-title">
              <span>ダッシュボード</span>
              <span class="page-description"
                >定期的に分析する「お気に入り店舗の条件」や「近隣の比較店舗」を事前登録できます</span
              >
            </div>
            <div class="link-chevron">
              <img src="@/assets/svg/chevron-right.svg" />
            </div>
          </div>
        </RouterLink>
      </div>
      <div v-if="isYoku" class="dialog-content-block dialog-content-margin">
        <div class="link-head">
          <div class="page-title">
            <span>店舗分析</span>
            <span class="page-description"
              >店舗を一つ選択し、さまざまな角度から分析することができます</span
            >
          </div>
        </div>
        <v-container class="pa-0" fluid>
          <v-row class="page-link">
            <v-col cols="3" class="py-0 pl-0 column-1">
              <RouterLink
                :to="getRouteLocationRaw('ShopAnalyticsVisitor')"
                @click="() => (showDialogValue = false)"
              >
                来店人数
              </RouterLink>
            </v-col>
            <v-col cols="3" class="py-0 column-2">
              <RouterLink
                :to="getRouteLocationRaw('ShopAnalyticsTrendVisitor')"
                @click="() => (showDialogValue = false)"
              >
                曜日/時間別人数
              </RouterLink>
            </v-col>
            <v-col cols="3" class="py-0 column-3">
              <RouterLink
                :to="getRouteLocationRaw('ShopAnalyticsPersona')"
                @click="() => (showDialogValue = false)"
              >
                ペルソナ特性
              </RouterLink>
            </v-col>
            <v-col cols="3" class="py-0 pr-0 column-4">
              <RouterLink
                :to="getRouteLocationRaw('ShopAnalyticsVisitEngagement')"
                @click="() => (showDialogValue = false)"
              >
                来店特性
              </RouterLink>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="pa-0" fluid>
          <v-row class="page-link">
            <v-col cols="3" class="py-0 pl-0 column-1">
              <RouterLink
                :to="getRouteLocationRaw('ShopAnalyticsBizArea')"
                @click="() => (showDialogValue = false)"
              >
                商圏分析
              </RouterLink>
            </v-col>
            <v-col cols="3" class="py-0 column-2">
              <RouterLink
                :to="getRouteLocationRaw('ShopAnalyticsCombination')"
                @click="() => (showDialogValue = false)"
              >
                併用分析
              </RouterLink>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div v-if="isYoku" class="dialog-content-block dialog-content-margin">
        <div class="link-head">
          <div class="page-title">
            <span>店舗比較</span>
            <span class="page-description"
              >「店舗比較リスト」に事前に登録された店舗同士を比較して分析できます</span
            >
          </div>
        </div>
        <v-container class="pa-0" fluid>
          <v-row class="page-link">
            <v-col cols="3" class="py-0 pl-0 column-1">
              <RouterLink
                :to="getRouteLocationRaw('StoreCompareVisitor')"
                @click="() => (showDialogValue = false)"
              >
                来店人数
              </RouterLink>
            </v-col>
            <v-col cols="3" class="py-0 column-2">
              <RouterLink
                :to="getRouteLocationRaw('StoreCompareTrendVisitor')"
                @click="() => (showDialogValue = false)"
              >
                曜日/時間別人数
              </RouterLink>
            </v-col>
            <v-col cols="3" class="py-0 column-3">
              <RouterLink
                :to="getRouteLocationRaw('StoreComparePersona')"
                @click="() => (showDialogValue = false)"
              >
                ペルソナ特性
              </RouterLink>
            </v-col>
            <v-col cols="3" class="py-0 pr-0 column-4">
              <RouterLink
                :to="getRouteLocationRaw('StoreCompareVisitEngagement')"
                @click="() => (showDialogValue = false)"
              >
                来店特性
              </RouterLink>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="pa-0" fluid>
          <v-row class="page-link">
            <v-col cols="3" class="py-0 pl-0 column-1">
              <RouterLink
                :to="getRouteLocationRaw('StoreCompareBizArea')"
                @click="() => (showDialogValue = false)"
              >
                商圏分析
              </RouterLink>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div v-if="isYoku" class="dialog-content-block dialog-content-margin">
        <RouterLink class="div-RouterLink" :to="getRouteLocationRaw('StoreArea')">
          <div class="link-head mb-0">
            <div class="page-title">
              <span>店舗別シェア</span>
              <span class="page-description"
                >店舗を一つ選択すると、指定サイズの商圏における店舗別の集客シェアが把握できます</span
              >
            </div>
            <div class="link-chevron">
              <img src="@/assets/svg/chevron-right.svg" />
            </div>
          </div>
        </RouterLink>
      </div>
      <div v-if="isHiroku" class="dialog-content-block dialog-content-margin">
        <div class="link-head">
          <div class="page-title">
            <span>チェーン分析</span>
            <span class="page-description"
              >チェーンを一つ選択し、さまざまな角度から分析することができます</span
            >
          </div>
        </div>
        <v-container class="pa-0" fluid>
          <v-row class="page-link">
            <v-col cols="3" class="py-0 pl-0 column-1">
              <RouterLink
                :to="getRouteLocationRaw('ChainAnalyticsVisitor')"
                @click="() => (showDialogValue = false)"
              >
                来店人数
              </RouterLink>
            </v-col>
            <v-col cols="3" class="py-0 column-2">
              <RouterLink
                :to="getRouteLocationRaw('ChainAnalyticsTrendVisitor')"
                @click="() => (showDialogValue = false)"
              >
                曜日/時間別人数
              </RouterLink>
            </v-col>
            <v-col cols="3" class="py-0 column-3">
              <RouterLink
                :to="getRouteLocationRaw('ChainAnalyticsPersona')"
                @click="() => (showDialogValue = false)"
              >
                ペルソナ特性
              </RouterLink>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="pa-0" fluid>
          <v-row class="page-link">
            <v-col cols="3" class="py-0 pl-0 column-1">
              <RouterLink
                :to="getRouteLocationRaw('ChainAnalyticsVisitEngagement')"
                @click="() => (showDialogValue = false)"
              >
                来店特性
              </RouterLink>
            </v-col>
            <v-col cols="3" class="py-0 column-2">
              <RouterLink
                :to="getRouteLocationRaw('ChainAnalyticsBizArea')"
                @click="() => (showDialogValue = false)"
              >
                商圏分析
              </RouterLink>
            </v-col>
            <v-col cols="3" class="py-0 column-3">
              <RouterLink
                :to="getRouteLocationRaw('ChainAnalyticsCombination')"
                @click="() => (showDialogValue = false)"
              >
                併用分析
              </RouterLink>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div v-if="isHiroku" class="dialog-content-block dialog-content-margin">
        <div class="link-head">
          <div class="page-title">
            <span>チェーン比較</span>
            <span class="page-description">チェーン同士を比較して分析できます</span>
          </div>
        </div>
        <v-container class="pa-0" fluid>
          <v-row class="page-link">
            <v-col cols="3" class="py-0 pl-0 column-1">
              <RouterLink
                :to="getRouteLocationRaw('ChainCompareVisitor')"
                @click="() => (showDialogValue = false)"
              >
                来店人数
              </RouterLink>
            </v-col>
            <v-col cols="3" class="py-0 column-2">
              <RouterLink
                :to="getRouteLocationRaw('ChainCompareTrendVisitor')"
                @click="() => (showDialogValue = false)"
              >
                曜日/時間別人数
              </RouterLink>
            </v-col>
            <v-col cols="3" class="py-0 column-3">
              <RouterLink
                :to="getRouteLocationRaw('ChainComparePersona')"
                @click="() => (showDialogValue = false)"
              >
                ペルソナ特性
              </RouterLink>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="pa-0" fluid>
          <v-row class="page-link">
            <v-col cols="3" class="py-0 pl-0 column-1">
              <RouterLink
                :to="getRouteLocationRaw('ChainCompareVisitEngagement')"
                @click="() => (showDialogValue = false)"
              >
                来店特性
              </RouterLink>
            </v-col>
            <v-col cols="3" class="py-0 column-2">
              <RouterLink
                :to="getRouteLocationRaw('ChainCompareBizArea')"
                @click="() => (showDialogValue = false)"
              >
                商圏分析
              </RouterLink>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div v-if="isHiroku" class="dialog-content-block dialog-content-margin">
        <div class="link-head">
          <div class="page-title">
            <span>トレンド</span>
            <span class="page-description">選択したエリア内の居住者がよく行く店舗・チェーン、行動DNA（行動嗜好性）がわかります</span>
          </div>
        </div>
        <v-container class="pa-0" fluid>
          <v-row class="page-link">
            <v-col cols="3" class="py-0 pl-0 column-1">
              <RouterLink
                :to="getRouteLocationRaw('TrendStore')"
                @click="() => (showDialogValue = false)"
              >
                居住者に人気の店舗
              </RouterLink>
            </v-col>
            <v-col cols="3" class="py-0 column-2">
              <RouterLink
                :to="getRouteLocationRaw('TrendChain')"
                @click="() => (showDialogValue = false)"
              >
                居住者に人気のチェーン
              </RouterLink>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="dialog-content-block">
        <div class="link-head">
          <div class="page-title">
            <span>その他メニュー</span>
            <span class="page-description"
              >ユーザー情報の変更や、サービス利用に関する情報はこちらをご覧ください</span
            >
          </div>
        </div>
        <v-container class="pa-0" fluid>
          <v-row class="page-link">
            <v-col cols="3" class="py-0 pl-0 column-1">
              <RouterLink
                :to="getRouteLocationRaw('NewsList')"
                @click="() => (showDialogValue = false)"
              >
                お知らせ
              </RouterLink>
            </v-col>
            <v-col cols="3" class="py-0 column-2">
              <RouterLink
                :to="getRouteLocationRaw('UserSetting')"
                @click="() => (showDialogValue = false)"
              >
                ユーザー設定
              </RouterLink>
            </v-col>
            <v-col cols="3" class="py-0 column-3">
              <RouterLink
                :to="getRouteLocationRaw('Manual')"
                @click="() => (showDialogValue = false)"
              >
                マニュアル
              </RouterLink>
            </v-col>
            <v-col cols="3" class="py-0 pr-0 column-4">
              <RouterLink :to="getRouteLocationRaw('FAQ')" @click="() => (showDialogValue = false)">
                FAQ・お問い合わせ
              </RouterLink>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="pa-0" fluid>
          <v-row class="page-link">
            <v-col cols="3" class="py-0 pl-0 column-1">
              <button @click="logout">ログアウト</button>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent, ref, watch, computed } from 'vue'
import { logout as authLogout } from '@/commons/axios/auth'
import { notifyErrorMessage } from '@/plugins/notification'
import { convertHyphenDelimiter } from '@/commons/utils/dateUtil'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { RouteLocationRaw } from 'vue-router'
import { RouterLink } from 'vue-router'

export default defineComponent({
  name: 'DialogMenu',
  props: {
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close'],
  setup(props, { emit }) {
    const router = useRouter()
    const showDialogValue = ref(false)

    const store = useStore()
    const isYoku = computed(() => store.getters.isYoku)
    const isHiroku = computed(() => store.getters.isHiroku)
    const selectedChainId = computed<string>(() => store.state.selectedChainId)
    const selectedChainIds = computed<string[]>(() => store.state.selectedChainIds)
    const selectedPrefectureIdsOfChain = computed<number[]>(
      () => store.state.selectedPrefectureIdsOfChain
    )
    const selectedPrefectureIdsOfChains = computed<number[]>(
      () => store.state.selectedPrefectureIdsOfChains
    )

    const logout = () => {
      authLogout()
        .then(() => {
          window.dataLayer.push(function (this: any) {
            this.reset()
          })
          router.push({ name: 'Login' })
        })
        .catch(() => {
          notifyErrorMessage('ログアウトできませんでした。')
        })
    }
    const getRouteLocationRaw = (name: string): RouteLocationRaw => {
      const { start, end } = {
        start: convertHyphenDelimiter(store.state.startDate),
        end: convertHyphenDelimiter(store.state.endDate)
      }
      const { startMonth, endMonth } = {
        startMonth: convertHyphenDelimiter(store.state.startMonth),
        endMonth: convertHyphenDelimiter(store.state.endMonth)
      }
      const storeId = store.state.selectedStore ? store.state.selectedStore.storeId : null
      const comparisonGroupId = store.state.selectedComparisonGroup
        ? store.state.selectedComparisonGroup.id
        : null

      switch (name) {
        case 'ShopAnalyticsVisitor':
          return {
            name: name,
            params: { id: storeId },
            query: {
              period: `${start}_${end}`,
              unit: 'date',
              subIndicator: 'none'
            }
          }
        case 'ShopAnalyticsTrendVisitor':
          return {
            name: name,
            params: { id: storeId },
            query: {
              period: `${start}_${end}`
            }
          }
        case 'ShopAnalyticsPersona':
          return {
            name: name,
            params: { id: storeId },
            query: {
              period: `${start}_${end}`
            }
          }
        case 'ShopAnalyticsVisitEngagement':
          return {
            name: name,
            params: { id: storeId }
          }
        case 'ShopAnalyticsBizArea':
          return {
            name: name,
            params: { id: storeId },
            query: {
              period: `${startMonth}_${endMonth}`,
              radius: '5',
              v_radius: '5',
              unit: 'town'
            }
          }
        case 'ShopAnalyticsCombination':
          return {
            name: name
          }
        case 'StoreCompareVisitor':
          return {
            name: name,
            params: {
              id: comparisonGroupId
            },
            query: {
              period: `${start}_${end}`,
              unit: 'date'
            }
          }
        case 'StoreCompareTrendVisitor':
          return {
            name: name,
            params: {
              id: comparisonGroupId
            },
            query: {
              period: `${start}_${end}`
            }
          }
        case 'StoreComparePersona':
          return {
            name: name,
            params: {
              id: comparisonGroupId
            },
            query: {
              period: `${start}_${end}`
            }
          }
        case 'StoreCompareVisitEngagement':
          return {
            name: name,
            params: {
              id: comparisonGroupId
            },
            query: {
              period: `${startMonth}_${endMonth}`,
            }
          }
        case 'StoreCompareBizArea':
          return {
            name: name,
            params: {
              id: comparisonGroupId
            },
            query: {
              period: `${startMonth}_${endMonth}`,
              radius: '5',
              v_radius: '5',
              unit: 'town'
            }
          }
        case 'ChainAnalyticsVisitor':
          return {
            name: name,
            params: {
              id: storeId
            },
            query: {
              period: `${start}_${end}`,
              unit: 'date',
              subIndicator: 'none',
              chainId: selectedChainId.value,
              prefectureIds: selectedPrefectureIdsOfChain.value
            }
          }
        case 'ChainAnalyticsTrendVisitor':
          return {
            name: name,
            query: {
              period: `${start}_${end}`,
              chainId: selectedChainId.value,
              prefectureIds: selectedPrefectureIdsOfChain.value
            }
          }
        case 'ChainAnalyticsPersona':
          return {
            name: name,
            query: {
              period: `${start}_${end}`,
              chainId: selectedChainId.value,
              prefectureIds: selectedPrefectureIdsOfChain.value
            }
          }
        case 'ChainAnalyticsVisitEngagement':
          return {
            name: name,
            query: {
              chainId: selectedChainId.value,
              prefectureIds: selectedPrefectureIdsOfChain.value
            }
          }
        case 'ChainAnalyticsBizArea':
          return {
            name: name,
            query: {
              period: `${startMonth}_${endMonth}`,
              radius: '5',
              v_radius: '5',
              unit: 'town',
              chainId: selectedChainId.value,
              prefectureIds: selectedPrefectureIdsOfChain.value
            }
          }
        case 'ChainAnalyticsCombination':
          return {
            name: name,
            query: {
              chainId: selectedChainId.value,
              prefectureIds: selectedPrefectureIdsOfChain.value
            }
          }
        case 'ChainCompareVisitor':
          return {
            name: name,
            query: {
              period: `${start}_${end}`,
              unit: 'date',
              chainIds: selectedChainIds.value,
              prefectureIds: selectedPrefectureIdsOfChains.value
            }
          }
        case 'ChainCompareTrendVisitor':
          return {
            name: name,
            query: {
              period: `${start}_${end}`,
              chainIds: selectedChainIds.value,
              prefectureIds: selectedPrefectureIdsOfChains.value
            }
          }
        case 'ChainComparePersona':
          return {
            name: name,
            query: {
              period: `${start}_${end}`,
              chainIds: selectedChainIds.value,
              prefectureIds: selectedPrefectureIdsOfChains.value
            }
          }
        case 'ChainCompareVisitEngagement':
          return {
            name: name,
            query: {
              period: `${startMonth}_${endMonth}`,
              chainIds: selectedChainIds.value,
              prefectureIds: selectedPrefectureIdsOfChains.value
            }
          }
        case 'ChainCompareBizArea':
          return {
            name: name,
            query: {
              period: `${startMonth}_${endMonth}`,
              radius: '5',
              v_radius: '5',
              unit: 'town',
              chainIds: selectedChainIds.value,
              prefectureIds: selectedPrefectureIdsOfChains.value
            }
          }
        default:
          return { name: name }
      }
    }

    watch(
      () => props.showDialog,
      (newValue: boolean) => {
        showDialogValue.value = newValue
      }
    )

    watch(showDialogValue, (newValue: boolean) => {
      if (!newValue) {
        emit('close')
      }
    })

    return {
      showDialogValue,
      isYoku,
      isHiroku,
      logout,
      getRouteLocationRaw,
      RouterLink
    }
  }
})
</script>

<style lang="scss">
.dialog-content-outer .v-overlay__content {
  overflow-y: scroll !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 6px;
}
</style>

<style lang="scss" scoped>
.dialog-content {
  padding: 49px 40px 52px 40px;

  .dialog-content-block {
    text-align: left;

    .link-head {
      width: 100%;
      height: 52px;
      background: #eeeeee 0% 0% no-repeat padding-box;
      padding: 14px 18px 14px 20px;
      margin-bottom: 10px;
      align-items: center;
      display: flex;

      .page-title {
        font: normal normal bold 16px/1 Noto Sans JP;
        color: #222222;
        text-align: left;
        float: left;
        width: 100%;
        display: flex;
        align-items: center;

        .page-description {
          margin-left: 8px;
          font: normal normal normal 13px/1 Noto Sans JP;
          color: #666666;
          text-align: left;
        }
      }

      .link-chevron {
        float: right;
      }
    }
    .page-link {
      margin: 8px 0px;

      a,
      button {
        display: flex inline;
        align-self: center;
        max-width: 234px;
        width: 100%;
        height: 44px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #cccccc;
        border-radius: 4px;
        font: normal normal normal 14px/1 Noto Sans JP;
        color: #444444;
        padding: 12px 18px;
        text-align: left;
        text-decoration: none;
      }

      .column-1,
      .column-2,
      .column-3 {
        padding-right: 4px;
      }

      .column-2,
      .column-3,
      .column-4 {
        padding-left: 4px;
      }
    }
    .div-button {
      width: 100%;
    }
  }
  .dialog-content-margin {
    margin-bottom: 30px;
  }
}
.dialog-content a {
  text-decoration: none;
}
</style>
