<template>
  <v-card height="'650px'" class="card">
    <v-card-title class="pa-0 card-title d-flex align-center">
      <span style="margin-right: 20px">行動DNA</span>
      <p style="margin-bottom: 0; font-size: 12px; color: #666">
        折れ線グラフをクリックすると詳細を閲覧できます。
      </p>
      <AlertTooltip
        v-if="chartAlert"
        class="ml-2"
        text="取得データボリュームが少なく統計上の信頼性が低い日が含まれています。該当日は参考値としてご参照ください。"
      />
      <v-spacer />
      <ChartDescriptionTooltip
        menu-key="chainCompare"
        sub-menu-key="persona"
        chart-key="behavioralDna"
        class="mr-27px"
      />
      <DownloadButton
        label="CSVデータ"
        :disabled="props.selectedChainIds.length === 0"
        :get-file-id="
          () =>
            downloadBehavioralDNACSV(
              props.selectedChainIds,
              props.selectedPrefectureIds.map((id) => id.toString()),
              startDate,
              endDate,
              2
            )
        "
        :csv-name="`チェーン比較_行動DNA_Level2_${convertSlashDelimiter(
          startDate
        )}-${convertSlashDelimiter(endDate)}`"
      />
    </v-card-title>
    <v-container class="pa-0" fluid>
      <v-row class="my-0" dense>
        <v-col class="py-0">
          <div v-show="props.selectedChainIds.length === 0" class="unselected_card">
            チェーンを選択するとチャートが表示されます。
          </div>
          <div v-show="props.selectedChainIds.length > 0">
            <LoadingImg v-if="chartLoading" :height="`${200 * props.selectedChainIds.length}px`" />
            <NoDataChart v-else-if="isNoData" />
            <div v-else>
              <BehavioralDNAChart
                :total-chart-data="chartData.total"
                :male-chart-data="chartData.male"
                :female-chart-data="chartData.female"
                :third-category="chartData.thirdCategory"
                :legends="chartLegends"
                :get-l3-file-id="
                  () =>
                    downloadBehavioralDNACSV(
                      props.selectedChainIds,
                      props.selectedPrefectureIds.map((id) => id.toString()),
                      startDate,
                      endDate,
                      3
                    )
                "
                :l3-c-s-v-name="`チェーン比較_行動DNA_Level3_${convertSlashDelimiter(
                  startDate
                )}-${convertSlashDelimiter(endDate)}`"
                :colors="chartColors"
                :hasl1l2-alert="chartAlert"
              />
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script setup lang="ts">
import AlertTooltip from '@/commons/components/Elements/AlertTooltip.vue'
import ChartDescriptionTooltip from '@/commons/components/Elements/ChartDescriptionTooltip.vue'
import DownloadButton from '@/commons/components/Elements/DownloadButton.vue'
import LoadingImg from '@/commons/components/loadingImg.vue'
import { Chain, ChainWithColor } from '@/commons/interfaces'
import NoDataChart from '@/features/ShopAnalytics/components/Common/noDataChart.vue'
import { ref, watch, computed } from 'vue'
import { useStore } from 'vuex'
import * as notify from '@/plugins/notification'
import { convertSlashDelimiter } from '@/commons/utils/dateUtil'
import { getBehavioralDnaChart } from '@/features/ChainAnalytics/persona/axios'
import { processBehavioralDNAChart, processLevelThreeOfBehavioralDNAChart } from '../utils/process'
import { BehavioralDNAChartData, BehavioralDNAResponse } from '../types'
import { downloadBehavioralDNACSV } from '../axios'
import BehavioralDNAChart from '@/features/StoreCompare/persona/components/BehavioralDNAChart.vue'
import { isEqualSorted } from '@/commons/utils/chain'

/* --------------------------------------------------------------------------
  props
 ---------------------------------------------------------------------------*/

const props = withDefaults(
  defineProps<{
    startDate: string,
    endDate: string,
    selectedChainIds?: string[]
    selectedPrefectureIds?: number[]
  }>(),
  {
    selectedChainIds: (): string[] => [],
    selectedPrefectureIds: (): number[] => []
  }
)

/* --------------------------------------------------------------------------
  Vuex
 ---------------------------------------------------------------------------*/

const store = useStore()
const startDate = computed<string>(() => store.state.startDate)
const endDate = computed<string>(() => store.state.endDate)
const chainMap = computed<Map<string, Chain>>(() => store.getters.chainMap)
const chainIdToColorMap = computed<Map<string, string>>(() => store.state.chainIdToColorMap)

/* --------------------------------------------------------------------------
  core
 ---------------------------------------------------------------------------*/

const selectedChains = computed<ChainWithColor[]>(() =>
  props.selectedChainIds.reduce((acc: ChainWithColor[], id: string): ChainWithColor[] => {
    const chain = chainMap.value.get(id)
    if (!chain) return acc
    return [...acc, { ...chain, color: chainIdToColorMap.value.get(id) ?? '#AAA' }]
  }, [])
)
const chartLoading = ref<boolean>(true)
const chartResponses = ref<Map<ChainWithColor, BehavioralDNAResponse>>(new Map())
const chartData = computed<BehavioralDNAChartData>(() => {
  const graphs = Array.from(chartResponses.value.entries()).map(([chain, { visitCounts }]) => ({
    chain,
    visitCounts
  }))
  const level2Processed = processBehavioralDNAChart(graphs)
  const level3Processed = processLevelThreeOfBehavioralDNAChart(graphs)

  return {
    total: level2Processed.total,
    male: level2Processed.male,
    female: level2Processed.female,
    thirdCategory: level3Processed ?? { total: [], male: [], female: [] }
  }
})
const chartAlert = computed<boolean>(() => {
  if (chartResponses.value.size === 0) return false
  return Array.from(chartResponses.value.values()).some(({ visitCounts }) =>
    visitCounts.some(({ secondCategory }) => !secondCategory.deviationValue)
  )
})
const chartLegends = computed<{ text: string; color: string }[]>(() => {
  if (selectedChains.value.length === 0) return []
  return selectedChains.value.map((chain) => ({ text: chain.name, color: chain.color }))
})
const chartColors = computed<string[]>(() => {
  if (selectedChains.value.length === 0) return []
  return selectedChains.value
    .slice()
    .sort((a, b) => a.orderIndex - b.orderIndex)
    .map(({ color }) => color)
})
const isNoData = computed<boolean>(() => {
  if (chartResponses.value.size === 0) return true
  return Array.from(chartResponses.value.values()).every(({ visitCounts }) =>
    visitCounts.every(({ secondCategory }) => !secondCategory.deviationValue)
  )
})

const fetchChart = async () => {
  if (
    !props.selectedChainIds.length ||
    !props.selectedPrefectureIds.length ||
    chainIdToColorMap.value.size !== props.selectedChainIds.length
  )
    return

  const fetchings = selectedChains.value.map((chain) =>
    getBehavioralDnaChart(
      chain.id,
      startDate.value,
      endDate.value,
      props.selectedPrefectureIds
    ).then((res) => {
      chartResponses.value.set(chain, res.data)
    })
  )

  try {
    chartLoading.value = true
    chartResponses.value.clear()
    await Promise.all(fetchings)
  } catch {
    notify.notifyErrorMessage('行動DNAチャートが表示できませんでした。')
  } finally {
    chartLoading.value = false
  }
}

const isSelectedChainAndPrefecture = () => {
  return props.selectedChainIds.length > 0 && props.selectedPrefectureIds.length > 0
}

/* --------------------------------------------------------------------------
  created
 -------------------------------------------------------------------------- */

fetchChart()

/* --------------------------------------------------------------------------
  watch
 ---------------------------------------------------------------------------*/

watch(
  () => [props.selectedChainIds, props.selectedPrefectureIds, props.startDate, props.endDate],
  (newVal, oldVal) => {
    if (isSelectedChainAndPrefecture() && !isEqualSorted(newVal, oldVal)) {
      fetchChart()
    }
  },
  { deep: true }
)
watch(chainIdToColorMap, (newVal, oldVal) => {
  if (isSelectedChainAndPrefecture()) {
    fetchChart()
  }
})
</script>

<style lang="scss" scoped>
.mr-27px {
  margin-right: 27px;
}
</style>
