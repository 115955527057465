<template>
  <div class="module">
    <div class="selectors">
      <CustomSelector
        class="select-button"
        v-model="selectedPrefecture"
        :items="prefectureItems"
        placeholder="都道府県を選択"
        aria-label="都道府県選択"
        size="md"
        height="44px"
        :width="`${selectorWidth}px`"
        @click="handleClickSelectorButton(GRANULARITY.PREFECTURE)"
      />
      <div class="chevron">
        <img src="@/assets/svg/chevron-right.svg" />
      </div>
      <button
        ref="municipalitiesButtonRef"
        class="select-button"
        aria-label="市区町村選択"
        :disabled="!selectedPrefecture"
        @click="handleClickSelectorButton(GRANULARITY.MUNICIPALITIES)"
      >
        <span v-if="selectedCityIds.length === 0">市区町村を選択</span>
        <span v-else class="limit-text">
          {{ selectedCities.map(({ name }) => name).join(', ') }}
        </span>
        <img class="icon" src="@/assets/svg/triangle-down.svg" />
      </button>
      <div class="chevron">
        <img src="@/assets/svg/chevron-right.svg" />
      </div>
      <button
        ref="townAndAreaButtonRef"
        class="select-button"
        aria-label="町丁目選択"
        :disabled="selectedCityIds.length === 0"
        @click="handleClickSelectorButton(GRANULARITY.TOWN_AND_AREA)"
      >
        <span v-if="selectedTownIds.length === 0">町丁目を選択</span>
        <span v-else class="limit-text">
          {{ selectedTowns.map(({ name }) => name).join(', ') }}
        </span>
        <img class="icon" src="@/assets/svg/triangle-down.svg" />
      </button>
    </div>
    <transition name="fade">
      <div ref="contentsRef" v-show="open && mode !== GRANULARITY.PREFECTURE" class="contents">
        <!-- HACK: watcherが想定以上に発火してしまい初期値が入らない不具合があるためコンポーネント化を中断 -->
        <div class="municipalities-selector" v-show="mode === GRANULARITY.MUNICIPALITIES">
          <header>
            <div class="title">市区町村を選択<span class="sub-text">（複数選択可）</span></div>
          </header>
          <form>
            <div>
              <div class="prefecture">{{ selectedPrefecture?.text }}</div>

              <div class="cities">
                <div
                  v-for="city in cities.filter(
                    ({ prefectureId }) => prefectureId === selectedPrefecture?.value
                  )"
                  :key="city.cityId"
                  class="checkbox-label"
                >
                  <input type="checkbox" :id="city.cityId" :value="city.cityId" v-model="selectedCityIds" />
                  <label :for="city.cityId">
                    {{ city.name }}
                  </label>
                </div>
              </div>
            </div>
          </form>
        </div>
        <!-- HACK: watcherが想定以上に発火してしまい初期値が入らない不具合があるためコンポーネント化を中断 -->
        <div class="municipalities-selector" v-show="mode === GRANULARITY.TOWN_AND_AREA">
          <header>
            <div class="title">町丁目を選択<span class="sub-text">（複数選択可）</span></div>
          </header>
          <form>
            <div v-for="cityId in selectedCityIds" :key="cityId">
              <div class="prefecture">{{ cityMap[cityId]['name'] }}</div>

              <div class="cities">
                <div
                  v-for="town in towns.filter((town) => town.cityId === cityId)"
                  :key="town.townId"
                  class="checkbox-label"
                >
                  <input type="checkbox" :id="town.townId" :value="town.townId" v-model="selectedTownIds" />
                  <label :for="town.townId">
                    {{ town.name }}
                  </label>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </transition>
    <div class="selected">
      <div>
        [選択中のエリア]
        <span v-if="!selectedPrefecture" class="selected-bold">なし</span>
        <span
          v-else
          class="selected-bold"
        >
          ●{{ selectedPrefecture?.text }}
        </span>
      </div>
      <div v-if="selectedTownIds.length === 0">
        <span v-for="city in selectedCities" :key="city.cityId" class="selected-bold">
          ◎{{ city.name }}&nbsp;
        </span>
      </div>
      <div v-else>
        <div v-for="city in selectedCities" :key="city.cityId">
          <span class="selected-bold">◎{{ city.name }}</span>
          ：<span v-if="selectedTowns.filter((town) => town.cityId === city.cityId).length > 0">
            {{
              selectedTowns
                .filter((town) => town.cityId === city.cityId)
                .map(({ name }) => name)
                .join(', ')
            }}
          </span>
          <span v-else>なし</span>
        </div>
      </div>
    </div>
    <div class="actions">
      <button class="reset-button" @click="handleResetSelectedIds">エリアの選択を解除</button>
      <CustomButton
        v-if="type == TREND_TYPE.STORE"
        width="220px"
        size="md"
        :disabled="selectedCityIds.length == 0"
        @click="props.onClickAnalytics([Number(selectedPrefecture?.value)], selectedCityIds, selectedTownIds)"
      >
        分析する
      </CustomButton>
      <CustomButton
        v-else
        width="220px"
        size="md"
        :disabled="!selectedPrefecture"
        @click="props.onClickAnalytics([Number(selectedPrefecture?.value)], selectedCityIds, selectedTownIds)"
      >
        分析する
      </CustomButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import CustomSelector, { Item } from '@/commons/components/Elements/CustomSelector.vue'
import { useStore } from 'vuex'
import { City, Prefecture } from '@/features/Dashboard/types'
import { Town } from '@/commons/interfaces'
import { GRANULARITY } from '@/commons/enums'
import { getTowns } from '@/commons/axios/towns'
import { TREND_TYPE } from '../enums'

/* --------------------------------------------------------------------------
  props
 ---------------------------------------------------------------------------*/

const props = withDefaults(
  defineProps<{
    onClickAnalytics: (prefectureIds: number[], cityIds: string[], townIds: string[]) => void
    type: (typeof TREND_TYPE)[keyof typeof TREND_TYPE]
  }>(),
  {
    onClickAnalytics: () => undefined,
    type: TREND_TYPE.STORE
  }
)

/* --------------------------------------------------------------------------
  Vuex
 ---------------------------------------------------------------------------*/

const store = useStore()
const prefectures = computed<Prefecture[]>(() => store.state.prefectures)
const cities = computed<City[]>(() => store.state.cities)
const cityMap = computed<Record<string, City>>(() => store.getters.cityMap)

/* --------------------------------------------------------------------------
  core logic
 ---------------------------------------------------------------------------*/

// DOM 埋め込み用 ref (ポップオーバーを要素外クリックで閉じる際に使用)
const municipalitiesButtonRef = ref<null | HTMLButtonElement>(null)
const townAndAreaButtonRef = ref<null | HTMLButtonElement>(null)
const contentsRef = ref<null | HTMLDivElement>(null)

const prefectureItems = computed<Item[]>(() =>
  prefectures.value.map(({ prefectureId, name }) => ({ text: name, value: prefectureId }))
)
const selectedPrefecture = ref<Item | undefined>()
const selectorWidth = window.innerWidth * 0.9 / 3;

const open = ref<boolean>(false)
const mode = ref<(typeof GRANULARITY)[keyof typeof GRANULARITY]>(0)
const selectedCityIds = ref<string[]>([])
const selectedTownIds = ref<string[]>([])

// 選択された市区町村にぶら下がる町丁目を格納する。町丁目のみサイズが大きいため Vuex で管理しないので ref で持ち回る。
const towns = ref<Town[]>([])

const selectedCities = computed<City[]>(() => selectedCityIds.value.map((id) => cityMap.value[id]))

const selectedTowns = computed<Town[]>(() =>
  towns.value.filter(({ townId }) => selectedTownIds.value.includes(townId))
)

const handleResetSelectedIds = () => {
  selectedPrefecture.value = undefined
  selectedCityIds.value = []
  selectedTownIds.value = []
}

const handleClickSelectorButton = (targetMode: (typeof GRANULARITY)[keyof typeof GRANULARITY]) => {
  // 開いている状態かつ押したボタンが現在開いているモードと同じ場合は閉じ、それ以外はすべて開く
  open.value = !(open.value && mode.value === targetMode)
  mode.value = targetMode

  // 町丁目セレクターが開かれたタイミングで市区町村にぶら下がる町丁目マスタを取得する
  if (open.value && mode.value === GRANULARITY.TOWN_AND_AREA) {
    getTowns(selectedCityIds.value).then((res) => {
      towns.value = res.data.towns
    })
  }
}

const handleClose = (event: MouseEvent) => {
  if (
    municipalitiesButtonRef.value &&
    !municipalitiesButtonRef.value.contains(event.target as Node) &&
    townAndAreaButtonRef.value &&
    !townAndAreaButtonRef.value.contains(event.target as Node) &&
    contentsRef.value &&
    !contentsRef.value.contains(event.target as Node)
  ) {
    open.value = false
  }
}

onMounted(() => {
  window.addEventListener('click', handleClose)
})

onBeforeUnmount(() => {
  window.removeEventListener('click', handleClose)
})

/* --------------------------------------------------------------------------
  created
 ---------------------------------------------------------------------------*/

import { useRoute } from 'vue-router';
const route = useRoute()
const handleCreated = async () => {
  // 各エリアが未取得の場合は取得
  if (!prefectures.value.length) await store.dispatch('fetchPrefectures')
  if (!cities.value.length) await store.dispatch('fetchCities')

  // クエリパラメーターのエリアID群が不正な形式の場合初期値は設定しない
  if (!isRegularAreaIdGroup()) return

  if (typeof route.query.prefectureIds === 'string') {
    const initalPrefecture = prefectures.value.find((prefecture: Prefecture) => prefecture.prefectureId === Number(route.query.prefectureIds))
    if (initalPrefecture) selectedPrefecture.value = { text: initalPrefecture?.name, value: initalPrefecture?.prefectureId }
  }
  if (typeof route.query.cityIds === 'string') {
    selectedCityIds.value = [route.query.cityIds]
  } else if (Array.isArray(route.query.cityIds)) {
    selectedCityIds.value = route.query.cityIds.map(a => String(a))
  }
  if (route.query.cityIds && route.query.townIds) {
    let queryCityIds: string[] = []
    if (typeof route.query.cityIds === 'string') {
      queryCityIds = [route.query.cityIds]
    } else if (Array.isArray(route.query.cityIds)) {
      queryCityIds = route.query.cityIds.map(a => String(a))
    }
    await getTowns(queryCityIds).then((res) => {
      towns.value = res.data.towns
    })

    if (typeof route.query.townIds === 'string') {
      selectedTownIds.value = [route.query.townIds]
    } else if (Array.isArray(route.query.townIds)) {
      selectedTownIds.value = route.query.townIds.map(a => String(a))
    }
  }
}
const isRegularAreaIdGroup = () => {
  if (
    (!route.query.prefectureIds && !route.query.cityIds && !route.query.townIds) ||
    (route.query.prefectureIds && !route.query.cityIds && !route.query.townIds)
  ) return true
  if (
    route.query.prefectureIds &&
    !route.query.cityIds &&
    route.query.townIds
  ) return false

  let prefectureId = '', cityIds: string[] = [], townIds: string[] = []
  if (typeof route.query.prefectureIds === 'string') {
    if (route.query.prefectureIds.length === 1) {
      prefectureId = '0' + route.query.prefectureIds
    } else {
      prefectureId = route.query.prefectureIds
    }
  }
  if (route.query.cityIds) {
    if (typeof route.query.cityIds === 'string') cityIds = [route.query.cityIds]
    if (Array.isArray(route.query.cityIds)) cityIds = route.query.cityIds.map(a => String(a))
  }
  if (route.query.townIds) {
    if (typeof route.query.townIds === 'string') townIds = [route.query.townIds]
    if (Array.isArray(route.query.townIds)) townIds = route.query.townIds.map(a => String(a))
  }

  const prefectureIdRegex = new RegExp('0[1-9]|[1-4][0-9]')
  if (!prefectureIdRegex.test(prefectureId)) return false
  const cityIdRegex = new RegExp('0[1-9]|[1-4][0-9]-\d{3}')
  for (const cityId of cityIds) {
    if (!cityIdRegex.test(cityId)) return false
  }
  if (townIds.length >= 1) {
    const townIdRegex = new RegExp('0[1-9]|[1-4][0-9]-\d{3}-\d{5}')
    for (const townId of townIds) {
      if (!townIdRegex.test(townId)) return false
    }
  }

  for (const cityId of cityIds) {
    if (prefectureId !== cityId.substring(0, 2)) return false
  }
  if (townIds.length >= 1) {
    for (const townId of townIds) {
      if (prefectureId !== townId.substring(0, 2)) return false

      let isInclude = false
      for (const cityId of cityIds) {
        isInclude = cityId.substring(3, 6) === townId.substring(3, 6)
        if (isInclude) break
      }
      if (!isInclude) return false
    }
  }

  return true
}

handleCreated()
/**
 * watch
 */
watch(selectedPrefecture, () => {
  if (store.state.citiesGeo) store.dispatch('removeCitiesGeo')
  if (store.state.townsGeo) store.dispatch('removeTownsGeo')
  store.dispatch('fetchPrefecturesGeo', selectedPrefecture.value?.value.toString())

  selectedCityIds.value = selectedCityIds.value.filter((id) =>
    cities.value
      .filter((city) => selectedPrefecture.value?.value === city.prefectureId)
      .some(({ cityId }) => cityId === id)
  )
  selectedTownIds.value = selectedTownIds.value.filter((id) =>
    towns.value
      .filter((town) => selectedCityIds.value.includes(town.cityId))
      .some(({ townId }) => townId === id)
  )
})
// NOTE: 市区町村と町丁目のポリゴンは都道府県内でまとめて取得するため、都道府県選択後の1件目を選択した時のみ取得を実行
watch(selectedCityIds, (newVal, oldVal) => {
  if (
    !newVal ||
    newVal.length === 0 ||
    oldVal.length >= 1 ||
    store.state.citiesGeo
  ) return
  store.dispatch('fetchCitiesGeo', selectedPrefecture.value?.value.toString())
})
watch(selectedTownIds, (newVal, oldVal) => {
  if (
    !newVal ||
    newVal.length === 0 ||
    oldVal.length >= 1 ||
    store.state.townsGeo
  ) return
  store.dispatch('fetchTownsGeo', selectedPrefecture.value?.value.toString())
})
</script>

<style scoped lang="scss">
.module {
  position: relative;
}

.selectors {
  margin-top: 10px;
  display: flex;
  width: 100%;
  align-items: center;
}

.select-button {
  display: flex;
  justify-content: space-between;
  width: calc(90% / 3);
  height: 44px;
  flex: 1;
  align-items: center;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 0 15px;
  text-align: left;
  font: normal normal normal 14px/40px Noto Sans JP;
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;
}

.select-button:disabled {
  background: #eeeeee 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-radius: 4px;
  opacity: 1;
}

.limit-text {
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chevron {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
}

.contents {
  position: absolute;
  top: 50px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  background: #ffffff 0% 0% no-repeat padding-box;
  width: 100%;
  height: 480px;
  padding: 26px 29px;
  overflow-y: scroll;
  z-index: 6;

  .municipalities-selector {
    width: 100%;

    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .title {
        color: var(--unnamed-color-222222);
        text-align: right;
        font: normal normal bold 15px/28px Noto Sans JP;
        letter-spacing: 0px;

        .sub-text {
          color: var(---666666);
          text-align: right;
          font: normal normal normal 15px/28px Noto Sans JP;
          letter-spacing: 0px;
        }
      }

      .reset-filter-button {
        color: var(---4d99d0（年代2）);
        text-align: right;
        font: normal normal normal 14px/30px Noto Sans JP;
        letter-spacing: 0px;
        color: #4d99d0;
      }
    }

    form {
      .prefecture {
        margin-top: 39px;
        height: 40px;
        padding: 10px 11px;
        text-align: left;
        font: normal normal bold 14px/20px Noto Sans JP;
        letter-spacing: 0px;
        color: #222222;
        background: #f5f5f5 0% 0% no-repeat padding-box;
        opacity: 1;
      }

      .cities {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 25px;
        width: 100%;
        margin-top: 20px;
      }

      .checkbox-label {
        display: flex;
        gap: 10px;
        text-align: left;
        font: normal normal normal 14px/20px Noto Sans JP;
        letter-spacing: 0px;
        color: #000000;

        input {
          cursor: pointer;
          width: 16px;
          height: 16px;
          transform: translateY(2px);
        }

        label {
          cursor: pointer;
        }
      }
    }

    .button-wrapper {
      margin-top: 46px;
    }
  }
}

.selected {
  margin-top: 30px;
  padding: 20px;
  height: 177px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border: 1px solid #eeeeee;
  text-align: left;
  font: normal normal normal 12px/24px Noto Sans JP;
  letter-spacing: 0px;
  overflow-y: auto;
  color: #333333;
  opacity: 1;
}

.selected-bold {
  text-align: left;
  font: normal normal bold 12px/24px Noto Sans JP;
  letter-spacing: 0px;
  color: #333333;
}

.actions {
  margin-top: 23px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.reset-button {
  text-align: left;
  font: normal normal normal 14px/30px Noto Sans JP;
  letter-spacing: 0px;
  color: #4d99d0;
  opacity: 1;
}
</style>
