<template>
  <v-container class="pa-0" fluid>
    <v-row class="title-container" dense no-gutters>
      <v-col style="display: flex; align-items: center">
        <h1 class="title-text">ペルソナ特性</h1>
        <update-badge type="week" style="margin-left: 15px" />
      </v-col>
      <v-spacer />
      <date-picker
        :start-date="store.state.startDate"
        :end-date="store.state.endDate"
        :compare-start-date="store.state.compareStartDate"
        :compare-end-date="store.state.compareEndDate"
        @update-period="(period) => setPeriodParams(period)"
      />
    </v-row>
    <v-row dense no-gutters class="card-container">
      <v-col>
        <chain-selector
          :initial-chain-id="chainId"
          :initial-prefecture-ids="prefectureIds.map((id) => Number(id))"
          :start-date="store.state.startDate"
          :end-date="store.state.endDate"
          :handle-update="
            (chain, prefectureIds) =>
              setParams({
                chainId: chain.id,
                prefectureIds: prefectureIds.map((id) => id.toString())
              })
          "
        />
      </v-col>
    </v-row>
    <v-row dense no-gutters class="card-container">
      <v-col>
        <v-card :height="'100%'" class="card">
          <v-card-title
            :class="
              !chainId || emptyAgeGenderData
                ? 'pa-0 d-flex align-center age-gender-card-title'
                : 'pa-0 d-flex align-center age-gender-card-title age-gender-card-title-margin'
            "
          >
            <span>性別✕年代構成</span>
            <alert-tooltip
              v-if="hasAlertInAgeGender"
              class="ml-2"
              text="取得データボリュームが少なく、統計上の信頼性の低いデータが含まれています。該当箇所は、参考値としてご参照ください。"
            />
            <v-spacer />
            <chart-description-tooltip
              menu-key="chainAnalytics"
              sub-menu-key="persona"
              chart-key="genderAgeStructure"
              class="mr-27px"
            />
            <download-button
              label="CSVデータ"
              :disabled="!chainId"
              :get-file-id="getAgeGenderFileId"
              :csv-name="ageGenderCSVName"
            />
          </v-card-title>
          <v-row class="my-0 py-0" dense>
            <v-col class="py-0" style="position: relative">
              <div v-show="!chainId" class="unselected_card">
                チェーンを選択するとチャートが表示されます。
              </div>
              <div v-show="chainId">
                <loading-img v-if="ageGenderChartLoading" :height="'425px'" />
                <no-data-chart v-else-if="emptyAgeGenderData" />
                <div v-else>
                  <stacked-pyramid-chart
                    v-if="isCompare"
                    :chart-data="baseChartData"
                    :max-value="comparePyramidMaxValue"
                    :has-alert="
                      !ageGenderChartLoading && hasAlertInAgeGender && !approveAlertInAgeGender
                    "
                    @click-approve-alert="clickApproveAlertInAgeGender"
                  />
                  <pyramid-chart
                    v-else
                    :chart-data="chartData"
                    :max-value="maxValue"
                    :has-alert="
                      !ageGenderChartLoading && hasAlertInAgeGender && !approveAlertInAgeGender
                    "
                    @click-approve-alert="clickApproveAlertInAgeGender"
                  />
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense no-gutters class="card-container mb-0">
      <v-col>
        <BehavioralDNACard
          ref="behavioralDNACard"
          :chart-data="behavioralDnaData"
          :chart-loading="behavioralDnaChartLoading"
          :third-categories="thirdCategoryDatas"
          :is-empty="emptyBehavioralDnaData"
          :get-l2-file-id="getBehavioralDnaL2FileId"
          :get-l3-file-id="getBehavioralDnaL3FileId"
          :l2-c-s-v-name="behavioralDnaL2CSVName"
          :l3-c-s-v-name="behavioralDnaL3CSVName"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
// components
import UpdateBadge from '@/commons/components/Elements/UpdateBadge.vue'
import DatePicker from '@/commons/components/DatePicker/DatePicker.vue'
import ChainSelector from '@/commons/components/ChainSelector/ChainSelector.vue'
import NoDataChart from '@/commons/components/Chart/NoDataChart.vue'
import loadingImg from '@/commons/components/loadingImg.vue'
import PyramidChart from '@/features/ChainAnalytics/persona/components/PyramidChart.vue'
import BehavioralDNACard from '@/features/ChainAnalytics/persona/components/BehavioralDNACard.vue'
import StackedPyramidChart from '@/features/ChainAnalytics/persona/components/StackedPyramidChart.vue'
// interface
import { AxiosResponse } from 'axios'
import { SelectionChart } from '@/features/ChainAnalytics/persona/interfaces/component'
import {
  AgeGenderResponse,
  BehavioralDnaResponse,
  CompareBehavioralDnaResponse,
  CompareThirdCategory,
  ThirdCategory
} from '@/features/ChainAnalytics/persona/interfaces/response'
import { Chain } from '@/commons/interfaces'
// axios
import {
  downloadAgeGenderChart,
  downloadAgeGenderCompareChart,
  getAgeGenderChart,
  getBehavioralDnaChart,
  downloadBehavioralDnaChart,
  downloadBehavioralDnaCompareChart,
  getBehavioralDnaCompareChart
} from '@/features/ChainAnalytics/persona/axios'
// utill
import * as notify from '@/plugins/notification'
import { convertSlashDelimiter } from '@/commons/utils/dateUtil'
import {
  getAgeGenderChartMaxValue,
  processAgeGenderChartData,
  processBehavioralDnaChartData,
  processBehavioralDnaThirdCategoryData,
  processAgeGenderCompareChartData,
  getAgeGenderCompareChartMaxValue,
  processBehavioralDnaCompareChartData,
  hasAlertInAgeGenderChart,
  hasAlertInAgeGenderCompareChart
} from '@/features/ChainAnalytics/persona/utills'
// hook
import useChainQueryParams from '@/commons/hooks/use-chain-query-params'
const { chainId, prefectureIds, setParams } = useChainQueryParams()
import usePeriodQueryParams from '@/commons/hooks/use-period-query-params'
const { setPeriodParams } = usePeriodQueryParams()

import { useRoute, useRouter } from 'vue-router'
const route = useRoute()
const router = useRouter()

import { useStore } from 'vuex'
import { getPeriodByRouterQueryPeriod } from '@/commons/utils'
const store = useStore()
const startDate = computed<string>(() => store.state.startDate)
const endDate = computed<string>(() => store.state.endDate)
const compareStartDate = computed<string>(() => store.state.compareStartDate)
const compareEndDate = computed<string>(() => store.state.compareEndDate)

const chains = computed<Chain[]>(() => store.getters.chains)
const selectChart = ref<SelectionChart>()
const compareSelectChart = ref<SelectionChart>()
const isCompare = computed<boolean>(() => {
  return compareStartDate.value.length !== 0 && compareEndDate.value.length !== 0
})

// 性別年代別
const hasAlertInAgeGender = ref<boolean>(false)
const maxValue = ref<number>(0)
const chartData = ref<any[]>([])
const baseChartData = ref<any[]>([])
const ageGenderChartLoading = ref<boolean>(false)
const approveAlertInAgeGender = ref<boolean>(false)
const emptyAgeGenderData = computed<boolean>(() => {
  if (isCompare.value) {
    if (baseChartData.value.length === 0) return true
    // 1行目はヘッダー行なので無視
    for (let i = 1; i < baseChartData.value.length; i++) {
      if (
        baseChartData.value[i][1] !== 0 ||
        baseChartData.value[i][3] !== 0 ||
        baseChartData.value[i][5] !== 0 ||
        baseChartData.value[i][7] !== 0
      )
        return false
    }
    return true
  } else {
    if (chartData.value.length === 0) return true
    // 1行目はヘッダー行なので無視
    for (let i = 1; i < chartData.value.length; i++) {
      if (chartData.value[i][1] !== 0 || chartData.value[i][3] !== 0) return false
    }
    return true
  }
})
const getAgeGenderFileId = computed<() => Promise<AxiosResponse>>(() => {
  return () => {
    if (!isCompare.value)
      return downloadAgeGenderChart(
        chainId.value,
        startDate.value,
        endDate.value,
        prefectureIds.value.map((n) => parseInt(n))
      )
    else
      return downloadAgeGenderCompareChart(
        chainId.value,
        [
          {
            start: startDate.value,
            end: endDate.value
          },
          {
            start: compareStartDate.value,
            end: compareEndDate.value
          }
        ],
        prefectureIds.value.map((n) => parseInt(n))
      )
  }
})
const ageGenderCSVName = computed<string>(() => {
  const { start, end, compareStart, compareEnd } = {
    start: convertSlashDelimiter(startDate.value),
    end: convertSlashDelimiter(endDate.value),
    compareStart: convertSlashDelimiter(compareStartDate.value),
    compareEnd: convertSlashDelimiter(compareEndDate.value)
  }
  if (!isCompare.value) return `性別✕年代構成_${start}-${end}`
  else return `チェーン分析_性別✕年代構成_${start}-${end}_${compareStart}-${compareEnd}`
})
function clickApproveAlertInAgeGender() {
  approveAlertInAgeGender.value = true
}
function fetchChartData() {
  if (ageGenderChartLoading.value) return

  ageGenderChartLoading.value = true
  chartData.value.splice(0, 0)

  getAgeGenderChart(
    chainId.value,
    startDate.value,
    endDate.value,
    prefectureIds.value.map((n) => parseInt(n))
  )
    .then((res: AxiosResponse<AgeGenderResponse>) => {
      maxValue.value = getAgeGenderChartMaxValue(res.data)
      chartData.value = processAgeGenderChartData(res.data)
      hasAlertInAgeGender.value = hasAlertInAgeGenderChart(res.data)
    })
    .catch(() => {
      notify.notifyErrorMessage('性別年代別チャートが表示できませんでした。')
    })
    .finally(() => {
      ageGenderChartLoading.value = false
    })
}

// 行動DNA
const behavioralDNACard = ref()
const behavioralDnaChartLoading = ref<boolean>(false)
const behavioralDnaData = ref<any[]>([])
const thirdCategoryDatas = ref<
  {
    thirdCategory: ThirdCategory[] | CompareThirdCategory[]
    firstCategoryName: string
    secondCategoryName: string
  }[]
>([])
const emptyBehavioralDnaData = computed<boolean>(() => {
  return behavioralDnaData.value.length <= 1
})
const getBehavioralDnaL2FileId = computed<() => Promise<AxiosResponse>>(() => {
  return () => {
    if (!isCompare.value)
      return downloadBehavioralDnaChart(
        chainId.value,
        startDate.value,
        endDate.value,
        2,
        prefectureIds.value.map((n) => parseInt(n))
      )
    else
      return downloadBehavioralDnaCompareChart(
        chainId.value,
        [
          {
            start: startDate.value,
            end: endDate.value
          },
          {
            start: compareStartDate.value,
            end: compareEndDate.value
          }
        ],
        2,
        prefectureIds.value.map((n) => parseInt(n))
      )
  }
})
const getBehavioralDnaL3FileId = computed<() => Promise<AxiosResponse>>(() => {
  return () => {
    if (!isCompare.value)
      return downloadBehavioralDnaChart(
        chainId.value,
        startDate.value,
        endDate.value,
        3,
        prefectureIds.value.map((n) => parseInt(n))
      )
    else
      return downloadBehavioralDnaCompareChart(
        chainId.value,
        [
          {
            start: startDate.value,
            end: endDate.value
          },
          {
            start: compareStartDate.value,
            end: compareEndDate.value
          }
        ],
        3,
        prefectureIds.value.map((n) => parseInt(n))
      )
  }
})
const behavioralDnaL2CSVName = computed<string>(() => {
  const { start, end, compareStart, compareEnd } = {
    start: convertSlashDelimiter(startDate.value),
    end: convertSlashDelimiter(endDate.value),
    compareStart: convertSlashDelimiter(compareStartDate.value),
    compareEnd: convertSlashDelimiter(compareEndDate.value)
  }
  if (!isCompare.value) return `行動DNA_Level2_${start}-${end}`
  else return `チェーン分析_行動DNA_Level2_${start}-${end}_${compareStart}-${compareEnd}`
})
const behavioralDnaL3CSVName = computed<string>(() => {
  const { start, end, compareStart, compareEnd } = {
    start: convertSlashDelimiter(startDate.value),
    end: convertSlashDelimiter(endDate.value),
    compareStart: convertSlashDelimiter(compareStartDate.value),
    compareEnd: convertSlashDelimiter(compareEndDate.value)
  }
  if (!isCompare.value) return `行動DNA_Level3_${start}-${end}`
  else return `チェーン分析_行動DNA_Level3_${start}-${end}_${compareStart}-${compareEnd}`
})
function fetchBehavioralDnaData() {
  if (behavioralDnaChartLoading.value) return

  behavioralDnaChartLoading.value = true
  behavioralDnaData.value.splice(0)
  getBehavioralDnaChart(
    chainId.value,
    startDate.value,
    endDate.value,
    prefectureIds.value.map((n) => parseInt(n))
  )
    .then((res: AxiosResponse<BehavioralDnaResponse>) => {
      behavioralDnaData.value = processBehavioralDnaChartData(res.data)
      thirdCategoryDatas.value = processBehavioralDnaThirdCategoryData(res.data)
    })
    .catch(() => {
      notify.notifyErrorMessage('行動DNAが表示できませんでした。')
    })
    .finally(() => {
      behavioralDnaChartLoading.value = false
    })
}

// 期間比較
const comparePyramidMaxValue = ref<number>(0)
function fetchCompareChartData() {
  if (ageGenderChartLoading.value) return

  ageGenderChartLoading.value = true

  const baseChart = getAgeGenderChart(
    chainId.value,
    startDate.value,
    endDate.value,
    prefectureIds.value.map((n) => parseInt(n))
  )
  const comparisonChart = getAgeGenderChart(
    chainId.value,
    compareStartDate.value,
    compareEndDate.value,
    prefectureIds.value.map((n) => parseInt(n))
  )
  Promise.all([baseChart, comparisonChart])
    .then((res: AxiosResponse<AgeGenderResponse>[]) => {
      comparePyramidMaxValue.value = getAgeGenderCompareChartMaxValue(res[0].data, res[1].data)
      baseChartData.value = processAgeGenderCompareChartData(
        startDate.value,
        endDate.value,
        compareStartDate.value,
        compareEndDate.value,
        res[0].data,
        res[1].data
      )
      hasAlertInAgeGender.value = hasAlertInAgeGenderCompareChart(res[0].data, res[1].data)
    })
    .catch(() => {
      notify.notifyErrorMessage('性別年代別チャートが表示できませんでした。')
    })
    .finally(() => {
      ageGenderChartLoading.value = false
    })
}

// 期間比較行動DNA
function fetchCompareBehavioralDnaData() {
  if (behavioralDnaChartLoading.value) return

  behavioralDnaChartLoading.value = true
  behavioralDnaData.value.splice(0)

  getBehavioralDnaCompareChart(
    chainId.value,
    [
      {
        start: startDate.value,
        end: endDate.value
      },
      {
        start: compareStartDate.value,
        end: compareEndDate.value
      }
    ],
    prefectureIds.value.map((n) => parseInt(n))
  )
    .then((res: AxiosResponse<CompareBehavioralDnaResponse>) => {
      behavioralDnaData.value = processBehavioralDnaCompareChartData(
        startDate.value,
        endDate.value,
        compareStartDate.value,
        compareEndDate.value,
        res.data
      )
      thirdCategoryDatas.value = processBehavioralDnaThirdCategoryData(res.data)
    })
    .catch(() => {
      notify.notifyErrorMessage('行動DNAが表示できませんでした。')
    })
    .finally(() => {
      behavioralDnaChartLoading.value = false
    })
}

function handleCreated() {
  if (route.query.id) {
    if (!chains.value.some((chain: Chain) => chain.id === route.query.id))
      router.push({ name: 'NotFound' })
  }

  if (route.query.period) {
    const basePeriod = getPeriodByRouterQueryPeriod(route.query.period as string)
    if (!!basePeriod) store.commit('setDate', basePeriod)
  }

  if (route.query.c_period) {
    const comparePeriod = getPeriodByRouterQueryPeriod(route.query.c_period as string)
    if (!!comparePeriod) store.commit('setCompareDate', comparePeriod)
  }

  fetchAll()
}

function fetchAll() {
  if (chainId.value) {
    selectChart.value = undefined
    compareSelectChart.value = undefined
    approveAlertInAgeGender.value = false
    if (typeof behavioralDNACard.value !== 'undefined') {
      behavioralDNACard.value.resetSelectChart()
    }

    if (!isCompare.value) {
      fetchChartData()
      fetchBehavioralDnaData()
    } else {
      fetchCompareChartData()
      fetchCompareBehavioralDnaData()
    }
  }
}

// watch
watch([chainId, prefectureIds], () => {
  if (chainId.value) {
    fetchAll()
  }
})

// created
handleCreated()
</script>

<style lang="scss" scoped>
.age-gender-card-title {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  height: 30px;
  width: 100%;
}

.age-gender-card-title-margin {
  margin-bottom: 10px;
}

.age-gender-card-title span {
  font-size: 16px;
  font-weight: bold;
  line-height: 30px;
}

.age-gender-card-title a {
  font-size: 12px;
  font-weight: normal;
  color: #4d99d0;
}

.mr-27px {
  margin-right: 27px;
}
</style>
