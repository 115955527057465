<template>
  <div class="module">
    <MapSelectorDialog
      v-model="open"
      :initial-prefecture-id="
        selectedPrefectureIds.length > 0 ? selectedPrefectureIds[0] : undefined
      "
      :initial-city-ids="selectedCityIds"
      :initial-town-ids="selectedTownIds"
      :on-area-confirm="handleAreaConfirm"
      :type="props.type"
    />
    <div v-if="selectedPrefectures.length === 0" class="not-selected">
      <CustomButton width="220px" size="md" @click="open = true"> 地図を開く </CustomButton>
    </div>
    <div v-else class="selected">
      <div>
        [選択中のエリア]
        <span v-if="selectedPrefectures.length === 0" class="selected-bold">なし</span>
        <span
          v-for="prefecture in selectedPrefectures"
          :key="prefecture.prefectureId"
          class="selected-bold"
        >
          ●{{ prefecture.name }}
        </span>
      </div>
      <div v-if="selectedTownIds.length === 0">
        <span v-for="city in selectedCities" :key="city.cityId" class="selected-bold">
          ◎{{ city.name }}&nbsp;
        </span>
      </div>
      <div v-else>
        <div v-for="city in selectedCities" :key="city.cityId">
          <span class="selected-bold">◎{{ city.name }}</span>
          ：<span v-if="selectedTowns.filter((town) => town.cityId === city.cityId).length > 0">
            {{
              selectedTowns
                .filter((town) => town.cityId === city.cityId)
                .map(({ name }) => name)
                .join(', ')
            }}
          </span>
          <span v-else>なし</span>
        </div>
      </div>
    </div>
    <div class="actions">
      <button class="action-button" @click="handleResetSelectedIds">エリアの選択を解除</button>
      <div class="right-area">
        <button class="action-button" @click="open = true">地図を開く</button>
        <CustomButton
          width="220px"
          size="md"
          :disabled="selectedPrefectureIds.length === 0 || isLoading"
          @click="props.onClickAnalytics(selectedPrefectureIds, selectedCityIds, selectedTownIds)"
        >
          分析する
        </CustomButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { City, Prefecture } from '@/features/Dashboard/types'
import { Town } from '@/commons/interfaces'
import { getTowns } from '@/commons/axios/towns'
import MapSelectorDialog from './MapSelectorDialog.vue'
import CustomButton from '@/commons/components/Elements/CustomButton.vue'
import { TREND_TYPE } from '../enums'

/* --------------------------------------------------------------------------
  props
 ---------------------------------------------------------------------------*/

const props = withDefaults(
  defineProps<{
    onClickAnalytics: (prefectureIds: number[], cityIds: string[], townIds: string[]) => void
    type: (typeof TREND_TYPE)[keyof typeof TREND_TYPE]
  }>(),
  {
    onClickAnalytics: () => undefined,
    type: TREND_TYPE.STORE
  }
)

/* --------------------------------------------------------------------------
  Vuex
 ---------------------------------------------------------------------------*/

const store = useStore()
const prefectures = computed<Prefecture[]>(() => store.state.prefectures)
const cities = computed<City[]>(() => store.state.cities)
const prefectureMap = computed<Record<number, Prefecture>>(() => store.getters.prefectureMap)
const cityMap = computed<Record<string, City>>(() => store.getters.cityMap)

/* --------------------------------------------------------------------------
  core logic
 ---------------------------------------------------------------------------*/

const open = ref<boolean>(false)
const selectedPrefectureIds = ref<number[]>([])
const selectedCityIds = ref<string[]>([])
const selectedTownIds = ref<string[]>([])
const isLoading = ref<boolean>(false)

// 選択された市区町村にぶら下がる町丁目を格納する。町丁目のみサイズが大きいため Vuex で管理しないので ref で持ち回る。
const towns = ref<Town[]>([])

const selectedPrefectures = computed<Prefecture[]>(() =>
  selectedPrefectureIds.value.map((id) => prefectureMap.value[id])
)

const selectedCities = computed<City[]>(() => selectedCityIds.value.map((id) => cityMap.value[id]))

const selectedTowns = computed<Town[]>(() =>
  towns.value.filter(({ townId }) => selectedTownIds.value.includes(townId))
)

const handleAreaConfirm = async ({
  prefectureId,
  cityIds,
  townIds
}: {
  prefectureId: number
  cityIds?: string[]
  townIds?: string[]
}) => {
  try {
    isLoading.value = true

    if (cityIds && cityIds.length > 0) {
      const { data } = await getTowns(cityIds)
      towns.value = data.towns
    }

    selectedPrefectureIds.value = [prefectureId]
    selectedCityIds.value = cityIds ?? []
    selectedTownIds.value = townIds ?? []
    open.value = false
  } finally {
    isLoading.value = false
  }
}

const handleResetSelectedIds = () => {
  selectedPrefectureIds.value = []
  selectedCityIds.value = []
  selectedTownIds.value = []
}

/* --------------------------------------------------------------------------
  created
 ---------------------------------------------------------------------------*/

const handleCreated = async () => {
  // 都道府県が未取得の場合は都道府県を取得
  if (!prefectures.value.length) await store.dispatch('fetchPrefectures')
  // 市区町村が未取得の場合は都道府県を取得
  if (!cities.value.length) await store.dispatch('fetchCities')
}

handleCreated()
</script>

<style scoped>
.module {
  position: relative;
}

.selectors {
  margin-top: 22px;
  display: flex;
  width: 100%;
  align-items: center;
}

.select-button {
  display: flex;
  justify-content: space-between;
  width: calc(90% / 3);
  height: 44px;
  flex: 1;
  align-items: center;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 0 15px;
  text-align: left;
  font: normal normal normal 14px/40px Noto Sans JP;
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;
}

.select-button:disabled {
  background: #eeeeee 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-radius: 4px;
  opacity: 1;
}

.limit-text {
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chevron {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
}

.contents {
  position: absolute;
  top: 50px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  background: #ffffff 0% 0% no-repeat padding-box;
  width: 100%;
  height: 480px;
  padding: 26px 29px;
  overflow-y: scroll;
  z-index: 6;
}

.not-selected {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  padding: 20px;
  height: 177px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border: 1px solid #eeeeee;
  text-align: left;
  font: normal normal normal 12px/24px Noto Sans JP;
  letter-spacing: 0px;
  overflow-y: auto;
  color: #666666;
  opacity: 1;
}

.selected {
  margin-top: 10px;
  padding: 20px;
  height: 177px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border: 1px solid #eeeeee;
  text-align: left;
  font: normal normal normal 12px/24px Noto Sans JP;
  letter-spacing: 0px;
  overflow-y: auto;
  color: #666666;
  opacity: 1;
}

.selected-bold {
  text-align: left;
  font: normal normal bold 12px/24px Noto Sans JP;
  letter-spacing: 0px;
  color: #333333;
}

.actions {
  margin-top: 23px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.action-button {
  text-align: left;
  font: normal normal normal 14px/30px Noto Sans JP;
  letter-spacing: 0px;
  color: #4d99d0;
  opacity: 1;
}

.right-area {
  display: flex;
  gap: 30px;
}
</style>
