<template>
  <v-card class="card" tile :width="'100%'">
    <v-card-title class="pa-0 d-flex align-center card-title">
      <span>行動DNA</span>
      <v-spacer />
      <chart-description-tooltip
        menu-key="trend"
        sub-menu-key="popularStore"
        chart-key="behavioralDna"
      />
    </v-card-title>
    <div
      v-show="!props.areaId || props.areaId?.prefectureIds.length === 0"
      class="unselected_card unselected_dna_card"
    >
      エリアを選択するとチャートが表示されます。
    </div>
    <div v-show="props.areaId && props.areaId.prefectureIds.length >= 1">
      <behavioral-dna-sumarry :summary="behavioralDnaSummaryData" />
      <div class="divider" />
      <div class="pa-0 d-flex align-center">
        <div class="sub-card-title">詳細</div>
        <span class="sub-card-label">棒グラフをクリックすると詳細を閲覧できます。</span>
        <alert-tooltip
          v-if="hasAlertInBehavioralDnaData"
          class="ml-2"
          text="取得データボリュームが少なく、統計上の信頼性の低いデータが含まれています。該当箇所は、参考値としてご参照ください。"
        />
        <v-spacer />
        <chart-description-tooltip
          menu-key="trend"
          sub-menu-key="popularStore"
          chart-key="behavioralDna"
          class="mr-7"
        />
        <download-button
          label="CSVデータ"
          :disabled="behavioralDnaData.length === 0"
          :get-file-id="getBehavioralDnaL2FileId"
          :csv-name="behavioralDnaL2CSVName"
        />
      </div>
      <v-container :class="emptyBehavioralDnaData ? 'pa-0' : 'pa-0 mb-4'" fluid>
        <v-row class="my-0" dense>
          <v-col class="pa-0">
            <div>
              <LoadingImg v-if="behavioralDnaChartLoading" :height="'720px'" />
              <no-data-chart v-else-if="emptyBehavioralDnaData" />
              <div v-else>
                <BehavioralDNAChart
                  :chart-data="behavioralDnaData"
                  :height="720"
                  :min="min"
                  :max="max"
                  @select-column="onClickBarChart"
                />
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="my-0" dense>
          <v-col class="pa-0">
            <div class="my-0 explanation-of-alerts">
              <v-spacer />
              <alert-caption v-if="hasAlertInBehavioralDnaData" variants="square" />
            </div>
          </v-col>
        </v-row>
      </v-container>
      <div v-if="selectChart" class="divider" />
      <v-card-title v-show="selectChart" class="pa-0 behavioral-dna-level-three-card-title">
        <span>{{ levelThreeTitle }}</span>
        <alert-tooltip
          v-if="hasAlertInSubBehavioralDnaData"
          class="ml-2"
          text="取得データボリュームが少なく、統計上の信頼性の低いデータが含まれています。該当箇所は、参考値としてご参照ください。"
        />
        <v-spacer />
        <download-button
          label="CSVデータ"
          :disabled="props.areaId && props.areaId.prefectureIds.length === 0"
          :get-file-id="getBehavioralDnaL3FileId"
          :csv-name="behavioralDnaL3CSVName"
        />
      </v-card-title>
      <v-container class="pa-0" fluid>
        <v-row class="my-0" dense>
          <v-col class="pa-0">
            <v-row v-show="props.areaId && props.areaId.prefectureIds.length >= 1" class="ma-0">
              <BehavioralDNAChart
                v-if="selectChart"
                :chart-data="levelThree"
                :height="levelThreeBehavioralDNAChartHeight"
                :min="min"
                :max="max"
                style="margin-bottom: 10px !important"
              />
            </v-row>
          </v-col>
        </v-row>
        <v-row class="my-0" dense>
          <v-col class="pa-0">
            <div class="my-0 explanation-of-alerts">
              <v-spacer />
              <alert-caption
                v-if="hasAlertInSubBehavioralDnaData && selectChart"
                variants="square"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-card>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { AxiosResponse } from 'axios'
import NoDataChart from '@/commons/components/Chart/NoDataChart.vue'
import LoadingImg from '@/commons/components/loadingImg.vue'
import BehavioralDnaSumarry from '@/features/Trend/TrendStore/components/BehavioralDnaSumarry.vue'
import BehavioralDNAChart from '@/features/Trend/TrendStore/components/BehavioralDNAChart.vue'
import {
  getTrendBehavioralDna,
  downloadBehavioralDnaChart
} from '@/features/Trend/TrendStore/axios'
import { SelectionChart } from '@/features/ChainAnalytics/persona/interfaces/component'
import * as notify from '@/plugins/notification'
import { convertSlashDelimiter } from '@/commons/utils/dateUtil'
import {
  processBehavioralDnaChartData,
  processBehavioralDnaThirdCategoryData
} from '@/features/Trend/TrendStore/utills'
import { processBehavioralDnaThirdCategoryChartData } from '@/features/ShopAnalytics/utils/persona'
import { TrendBehavioralDnaResponse, ThirdCategory } from '@/features/Trend/TrendStore/interfaces'
import { TREND_TYPE } from '../../enums'

const props = withDefaults(
  defineProps<{
    areaId:
      | {
          prefectureIds: string[]
          cityIds: string[] | undefined
          townIds: string[] | undefined
        }
      | undefined
    period: string
    type: (typeof TREND_TYPE)[keyof typeof TREND_TYPE]
  }>(),
  {
    areaId: undefined,
    period: '',
    type: TREND_TYPE.STORE
  }
)

const behavioralDnaChartLoading = ref<boolean>(false)
const behavioralDnaSummaryData = ref()
const behavioralDnaData = ref<any[]>([])
const thirdCategoryDatas = ref<
  {
    thirdCategory: ThirdCategory[]
    firstCategoryName: string
    secondCategoryName: string
  }[]
>([])
const emptyBehavioralDnaData = computed<boolean>(() => {
  return behavioralDnaData.value.length <= 1
})
const getBehavioralDnaL2FileId = computed<() => Promise<AxiosResponse>>(() => {
  return () => {
    return downloadBehavioralDnaChart(
      props.areaId?.prefectureIds,
      props.areaId?.cityIds,
      props.areaId?.townIds,
      props.period,
      2,
      props.type
    )
  }
})
const getBehavioralDnaL3FileId = computed<() => Promise<AxiosResponse>>(() => {
  return () => {
    return downloadBehavioralDnaChart(
      props.areaId?.prefectureIds,
      props.areaId?.cityIds,
      props.areaId?.townIds,
      props.period,
      3,
      props.type
    )
  }
})
const behavioralDnaL2CSVName = computed<string>(() => {
  return `行動DNA_Level2_${convertSlashDelimiter(props.period)}`
})
const behavioralDnaL3CSVName = computed<string>(() => {
  return `行動DNA_Level3_${convertSlashDelimiter(props.period)}`
})
const selectChart = ref<null | SelectionChart>(null)
const levelThree = ref<any[][]>([])
const thirdCategoriesValues = computed<(number | boolean | null)[]>(() => {
  return thirdCategoryDatas.value.flatMap((c) => {
    return c.thirdCategory.flatMap((t) => Object.values(t.deviationValue))
  })
})
const min = computed<number>(() => {
  return Math.min(
    ...([...behavioralDnaData.value.flat(), ...thirdCategoriesValues.value].filter(
      (value) => typeof value === 'number'
    ) as number[])
  )
})
const max = computed<number>(() => {
  return Math.max(
    ...([...behavioralDnaData.value.flat(), ...thirdCategoriesValues.value].filter(
      (value) => typeof value === 'number'
    ) as number[])
  )
})
const levelThreeTitle = computed<string>(() => {
  if (!!thirdCategoryDatas.value && !!selectChart.value) {
    return `${
      thirdCategoryDatas.value[selectChart.value.row].firstCategoryName
    } > ${thirdCategoryDatas.value[selectChart.value.row].secondCategoryName}`
  } else {
    return ''
  }
})
const hasAlertInBehavioralDnaData = computed<boolean>(() => {
  if (behavioralDnaData.value.length === 0) return false

  return behavioralDnaData.value.some(
    (value) => value[3] === '#bbb' || value[6] === '#bbb' || value[9] === '#bbb'
  )
})
const hasAlertInSubBehavioralDnaData = computed<boolean>(() => {
  if (levelThree.value.length === 0) return false

  return levelThree.value.some(
    (value) => value[3] === '#bbb' || value[6] === '#bbb' || value[9] === '#bbb'
  )
})
const levelThreeBehavioralDNAChartHeight = computed<number>(() => {
  if (levelThree.value.length <= 0) return 0
  return levelThree.value.length * 34 + 80
})
function fetchBehavioralDnaData() {
  behavioralDnaChartLoading.value = true
  behavioralDnaData.value.splice(0)
  getTrendBehavioralDna(
    props.areaId?.prefectureIds,
    props.areaId?.cityIds,
    props.areaId?.townIds,
    props.period,
    props.type
  )
    .then((res: AxiosResponse<TrendBehavioralDnaResponse>) => {
      behavioralDnaSummaryData.value = res.data.summary
      behavioralDnaData.value = processBehavioralDnaChartData(res.data)
      thirdCategoryDatas.value = processBehavioralDnaThirdCategoryData(res.data)
    })
    .catch(() => {
      notify.notifyErrorMessage('行動DNAが表示できませんでした。')
    })
    .finally(() => {
      behavioralDnaChartLoading.value = false
    })
}

function onClickBarChart(e: SelectionChart) {
  // 左右中央でそれぞれ独立しているので、同じ行数のチャートが選択された場合もサブチャートを非表示にする
  if (!e || e?.row === selectChart.value?.row) {
    selectChart.value = null
    return
  }

  if (e.row === null) {
    selectChart.value = null
    return
  }

  selectChart.value = e
  levelThree.value = processBehavioralDnaThirdCategoryChartData(
    levelThreeTitle.value,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    thirdCategoryDatas.value[selectChart.value.row].thirdCategory
  )
}

watch(
  () => props,
  () => {
    if (!props.areaId || !props.areaId.prefectureIds) return
    selectChart.value = null
    fetchBehavioralDnaData()
  },
  {
    deep: true
  }
)
</script>

<style lang="scss" scoped>
.divider {
  border-bottom: 5px solid #eee;
  margin: 32.5px 0;
  margin-left: -30px;
  margin-right: -30px;
}
.sub-card-title {
  text-align: left;
  font: normal normal bold 14px/30px Noto Sans JP;
  letter-spacing: 0px;
  color: #000000;
  margin-right: 12px;
}
.sub-card-label {
  text-align: left;
  font: normal normal normal 12px/50px Noto Sans JP;
  letter-spacing: 0px;
  color: #000000;
}
.behavioral-dna-card-title {
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;

  span {
    font-size: 16px;
    font-weight: bold;
    line-height: 30px;
    margin-right: 20px;
  }
}

.behavioral-dna-card-title-margin {
  margin-bottom: 30px;
}

.behavioral-dna-level-three-card-title {
  display: flex;
  align-items: center;
  height: 30px;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 20px;

  span {
    font-size: 14px;
    font-weight: bold;
    line-height: 30px;
  }
}

.explanation-of-alerts {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #666;
}
</style>
