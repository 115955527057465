<template>
  <v-container class="pa-0" fluid>
    <v-row class="title-container" dense no-gutters>
      <v-col style="display: flex; align-items: center">
        <h1 class="title-text">商圏分析</h1>
        <UpdateBadge type="month" style="margin-left: 15px" />
      </v-col>
      <v-spacer />
      <MonthlyDatePicker
        :start-date="startMonth"
        :end-date="endMonth"
        @update-period="updatePeriod"
      />
    </v-row>
    <v-row dense no-gutters class="card-container">
      <v-col>
        <ChainSelector
          :initial-chain-id="chainId"
          :initial-prefecture-ids="prefectureIds.map((id) => Number(id))"
          :start-date="convertHyphenDelimiter(startMonth)"
          :end-date="convertHyphenDelimiter(endMonth)"
          :handle-update="
            (chain, prefectureIds) =>
              setParams({
                chainId: chain.id,
                prefectureIds: prefectureIds.map((id) => id.toString())
              })
          "
        />
      </v-col>
    </v-row>
    <v-row dense no-gutters class="card-container">
      <v-col style="position: relative">
        <v-card class="card" tile>
          <v-card-title class="pa-0 d-flex align-center card-title">
            <span>距離別カバー率</span>
            <AlertTooltip
              v-if="distanceRankingAlert"
              class="ml-2"
              text="取得データボリュームが少なく、統計上の信頼性の低いデータが含まれています。該当箇所は、参考値としてご参照ください。"
            />
            <v-spacer />
            <ChartDescriptionTooltip
              menu-key="chainAnalytics"
              sub-menu-key="bizArea"
              chart-key="distanceCoverage"
              class="mr-27px"
            />
            <DownloadButton
              label="CSVデータ"
              :disabled="!chainId"
              :get-file-id="
                () =>
                  downloadBizAreaAnalysisDistanceRankingChart(
                    chainId,
                    prefectureIds,
                    startMonth,
                    endMonth
                  )
              "
              :csv-name="`チェーン分析_距離別カバー率_
              ${convertSlashDelimiter(startMonth)}-${convertSlashDelimiter(endMonth)}`"
            />
          </v-card-title>
          <div v-show="!chainId" class="unselected_card">
            チェーンを選択するとチャートが表示されます。
          </div>
          <v-row v-show="chainId" class="justify-center">
            <LoadingImg v-if="distanceRankingLoading" :height="'330px'" />
            <NoDataChart v-else-if="isNoDataInDistanceRanking" />
            <BizAreaShareChart
              v-else
              :chart-data="distanceRankingData"
              :has-alert="distanceRankingChartAlert"
              @click-approve-alert="handleClickApproveDistanceRankingAlert"
            />
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense no-gutters class="card-container mb-0">
      <v-col style="position: relative">
        <v-card class="card" tile>
          <div class="map_title">
            <b>商圏マップ（居住エリア）</b>
            <AlertTooltip
              v-if="hasBizMapChartAlert"
              class="ml-2"
              text="取得データボリュームが少なく、統計上の信頼性の低いデータが含まれています。該当箇所は、参考値としてご参照ください。"
            />
            <v-spacer />
            <ChartDescriptionTooltip
              menu-key="chainAnalytics"
              sub-menu-key="bizArea"
              chart-key="bizAreaMap"
            />
          </div>
          <div class="mb-20px">
            <FilterForm
              :selected-prefecture-ids="prefectureIds.map((id) => Number(id))"
              :on-apply="handleApply"
              :on-reset-filter-value="resetFilterValue"
              :filteredPrefectureId="filteredPrefectureId"
              :filteredCityIds="filteredCityIds"
            />
          </div>
          <div v-if="chainId" class="bizarea-container">
            <NoDataChart v-if="!bizAreaMapLoading && isNoDataInBizAreaMap" />
            <BizAreaMap
              v-else
              ref="chainBizAreaMap"
              style="padding: 30px"
              :has-alert="hasBizMapAlert"
              :map-loading="bizAreaMapLoading"
              :feature-collection="featureCollection"
              :max-ratio="maxRatio"
              :active-area="activeAreas"
              :data-center-point="centerPoint"
              :granularity="granularity"
              :granularity-radio-button-state="granularityRadioButtonState"
              :prefecture-ids="
                filterConfig.pIds.length > 0
                  ? filterConfig.pIds.map((id) => String(id))
                  : prefectureIds
              "
              :stores="filteredStores"
              :on-zoom-start="() => (isZooming = true)"
              :on-zoom-end="() => (isZooming = false)"
              @click-layer="handleUpdateActiveAreaByAreaId"
              @click-approve-alert="handleApproveAlertMap"
              @reset-active="handleResetActiveArea"
            />
          </div>
          <div v-else class="unselected_card">チェーンを選択するとマップが表示されます。</div>
          <div class="divider" />
          <div class="map_title">
            <b>来店者の居住地ランキング</b>
            <v-spacer />
            <ChartDescriptionTooltip
              menu-key="chainAnalytics"
              sub-menu-key="bizArea"
              chart-key="ranking"
              class="mr-27px"
            />
            <DownloadButton
              label="CSVデータ"
              :disabled="!chainId"
              :get-file-id="clikdownloadBizAreaAnalysisDistanceRankingChart"
              :csv-name="`チェーン分析_商圏ランキング_${convertSlashDelimiter(
                startMonth
              )}-${convertSlashDelimiter(endMonth)}`"
            />
          </div>
          <div v-show="!chainId" class="unselected_card">
            チェーンを選択するとチャートが表示されます。
          </div>
          <div v-show="chainId" class="bizarea-container">
            <LoadingImg v-if="bizAreaRankingLoading" :height="'550px'" />
            <NoDataChart v-else-if="bizAreaRankingData.length <= 1 || isNoDataInBizAreaRanking" />
            <BizAreaRankingChart
              v-else
              :chart-data="bizAreaRankingData"
              :has-alert="bizAreaRankingAlert"
              @click-chart="handleUpdateActiveAreaByAreaName"
              @click-approve-alert="handleApproveBizAreaRanking"
            />
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import ChainSelector from '@/commons/components/ChainSelector/ChainSelector.vue'
import MonthlyDatePicker from '@/commons/components/DatePicker/MonthlyDatePicker.vue'
import AlertTooltip from '@/commons/components/Elements/AlertTooltip.vue'
import ChartDescriptionTooltip from '@/commons/components/Elements/ChartDescriptionTooltip.vue'
import DownloadButton from '@/commons/components/Elements/DownloadButton.vue'
import UpdateBadge from '@/commons/components/Elements/UpdateBadge.vue'
import LoadingImg from '@/commons/components/loadingImg.vue'
import useChainQueryParams from '@/commons/hooks/use-chain-query-params'
import { GoogleChartData } from '@/commons/types/GoogleChart'
import BizAreaShareChart from '@/features/ShopAnalytics/components/BizArea/bizAreaShareChart.vue'
import NoDataChart from '@/features/ShopAnalytics/components/Common/noDataChart.vue'
import {
  BizAreaAnalysisMapResponse,
  BizAreaAnalysisResponse,
  DistanceRankingResponse,
  MapBoxFeatures
} from '@/features/ShopAnalytics/interfaces/response'
import {
  getBizAreaMaxRate,
  getMapAreaList,
  processBizAreaShareChartData
} from '@/features/ShopAnalytics/utils/bizArea'
import { ref, computed, watch, toRaw } from 'vue'
import { useStore } from 'vuex'
import * as notify from '@/plugins/notification'
import {
  downloadBizAreaAnalysisDistanceRankingChart,
  downloadBizAreaAnalysisChart,
  getBizAreaAnalysisChart,
  getBizAreaAnalysisMap,
  getDistanceRankingChart
} from './axios'
import { convertSlashDelimiter } from '@/commons/utils/dateUtil'
import { GRANULARITY } from '@/commons/enums'
import BizAreaRankingChart from '@/features/ShopAnalytics/components/BizArea/bizAreaRankingChart.vue'
import { AreaListItem } from '@/features/ShopAnalytics/interfaces/component'
import BizAreaMap from '@/features/ChainAnalytics/bizArea/components/BizAreaMap.vue'
import { Point } from '@/commons/types/Mapbox'
import { Chain } from '@/commons/interfaces'
import { processBizAreaRankingData, getCenterPoint } from './utils'
import usePeriodQueryParams from '@/commons/hooks/use-period-query-params'
import FilterForm from '@/commons/components/BizArea/FilterForm.vue'
import { getPeriodByRouterQueryMonthPeriod } from '@/commons/utils'
import { useRoute } from 'vue-router'
import { Store } from '@/commons/interfaces/responses/store'
import { convertHyphenDelimiter } from '@/commons/utils/dateUtil'

/* --------------------------------------------------------------------------
  Vuex
 -------------------------------------------------------------------------- */

const store = useStore()
const chains = computed<Chain[]>(() => store.getters.chains)
const startMonth = computed<string>(() => store.state.startMonth)
const endMonth = computed<string>(() => store.state.endMonth)

/* --------------------------------------------------------------------------
  Vue Router
 -------------------------------------------------------------------------- */

const route = useRoute()

/* --------------------------------------------------------------------------
  選択済みチェーン・都道府県
 -------------------------------------------------------------------------- */

const { chainId, prefectureIds, setParams } = useChainQueryParams()
const { setPeriodParams } = usePeriodQueryParams()
const chain = computed<Chain | undefined>(() =>
  chains.value.find((chain) => chain.id === chainId.value)
)

const updatePeriod = ({ startMonth, endMonth }: { startMonth: string; endMonth: string }) => {
  setPeriodParams({ startMonth, endMonth })
}

/* --------------------------------------------------------------------------
  距離別カバー率
 -------------------------------------------------------------------------- */

const distanceRankingLoading = ref<boolean>(false)
const distanceRankingApproval = ref<boolean>(false)
const distanceRankingResponse = ref<DistanceRankingResponse | undefined>(undefined)
const distanceRankingData = computed<GoogleChartData>(() => {
  if (!distanceRankingResponse.value) return []
  return processBizAreaShareChartData(distanceRankingResponse.value, chain.value?.name || '')
})
const distanceRankingAlert = computed<boolean>(
  () => distanceRankingResponse.value?.isAlert || false
)
const distanceRankingChartAlert = computed<boolean>(
  () => (distanceRankingResponse.value?.isAlert && !distanceRankingApproval.value) || false
)
const isNoDataInDistanceRanking = computed<boolean>(
  () => distanceRankingResponse.value?.areas.every(({ visitRate }) => !visitRate) ?? true
)

const fetchDistanceRanking = async () => {
  distanceRankingApproval.value = false
  if (distanceRankingLoading.value) return

  try {
    distanceRankingLoading.value = true
    distanceRankingResponse.value = await getDistanceRankingChart(
      chainId.value,
      prefectureIds.value,
      startMonth.value,
      endMonth.value
    ).then((res) => res.data)
  } catch {
    notify.notifyErrorMessage('距離別カバー率が取得できませんでした。')
  } finally {
    distanceRankingLoading.value = false
  }
}

const handleClickApproveDistanceRankingAlert = () => {
  distanceRankingApproval.value = true
}

/* --------------------------------------------------------------------------
  商圏マップ（居住エリア）
 -------------------------------------------------------------------------- */

const bizAreaMapLoading = ref<boolean>(false)
const bizAreaMapApproval = ref<boolean>(false)
const bizAreaMapResponse = ref<BizAreaAnalysisMapResponse | undefined>(undefined)
const hasBizMapAlert = computed<boolean>(() => {
  const isAlert = bizAreaRankingResponse.value?.isAlert ?? false
  return !bizAreaMapLoading.value && isAlert && !bizAreaMapApproval.value
})
const hasBizMapChartAlert = computed<boolean>(() => bizAreaRankingResponse.value?.isAlert || false)
const isNoDataInBizAreaMap = computed<boolean>(() => {
  if (!bizAreaRankingResponse.value) return true
  return bizAreaRankingResponse.value.areas.every(({ visitRatio }) => visitRatio === 0)
})

// map 関連
// マップに適用されている表示単位
const granularity = ref<(typeof GRANULARITY)[keyof typeof GRANULARITY]>(GRANULARITY.PREFECTURE)
// ラジオボタンの表示単位
const granularityRadioButtonState = ref<(typeof GRANULARITY)[keyof typeof GRANULARITY]>(
  GRANULARITY.PREFECTURE
)
// mapの中心座標
// NOTE: データの取得までの間に一時的に表示されるため、仮の座標(東京の座標)を設定した(0,0だと大西洋になってしまう)
let centerPoint = ref<Point>({ lat: 35.6811673, lng: 139.7648629 })

// 「エリアを絞り込み」で選択されたprefectureId
const filteredPrefectureId = ref<number | undefined>(undefined)

// 「エリアを絞り込み」で選択されたcityIds
const filteredCityIds = ref<string[] | undefined>(undefined)

const isZooming = ref<boolean>(false)
const chainBizAreaMap = ref()

const featureCollection = computed<MapBoxFeatures[]>(() => [
  ...(bizAreaMapResponse.value?.features ?? [])
])
const maxRatio = computed<number>(() => {
  const max = bizAreaMapResponse.value ? getBizAreaMaxRate(bizAreaMapResponse.value) : 0
  return max > 0 ? max : 1
})

const areaList = computed<AreaListItem[]>(() => {
  if (!bizAreaMapResponse.value) return []
  return getMapAreaList(bizAreaMapResponse.value)
})
const activeAreas = ref<AreaListItem[]>([])

// マップに表示する店舗一覧（選択している都道府県で絞り込み）
const filteredStores = computed<Store[]>(
  () =>
    chain.value?.stores.filter((store) =>
      prefectureIds.value.map((id) => Number(id)).includes(store.prefectureId)
    ) ?? []
)

// フィルタ条件
const filterConfig = ref<{
  pIds: number[]
  cityIds?: string[]
  granularity: (typeof GRANULARITY)[keyof typeof GRANULARITY]
}>({
  pIds: [],
  cityIds: undefined,
  granularity: GRANULARITY.PREFECTURE
})

// Filterで選択されている都道府県・市区町村・粒度の値をセット・リセット
const setFilterValue = (
  prefectureId?: number,
  cityIds?: string[],
  newGranularity: (typeof GRANULARITY)[keyof typeof GRANULARITY] = GRANULARITY.PREFECTURE
) => {
  filteredPrefectureId.value = prefectureId
  filteredCityIds.value = cityIds
  granularity.value = newGranularity
}

const fetchBizAreaMap = async ({ isApply = false } = {}) => {
  bizAreaMapApproval.value = false
  if (bizAreaMapLoading.value || !chainId.value) return

  try {
    bizAreaMapLoading.value = true

    if (!filteredPrefectureId.value && !isApply) {
      filterConfig.value = {
        pIds: prefectureIds.value.map((id) => Number(id)),
        cityIds: undefined,
        granularity: GRANULARITY.PREFECTURE
      }
    }

    if (!prefectureIds.value.some((id) => filterConfig.value.pIds.includes(Number(id)))) {
      filterConfig.value = {
        pIds: prefectureIds.value.map((id) => Number(id)),
        cityIds: undefined,
        granularity: GRANULARITY.PREFECTURE
      }
    }

    const { pIds, cityIds, granularity } = (() => {
      if (filterConfig.value.granularity === GRANULARITY.PREFECTURE) {
        return {
          pIds: prefectureIds.value.map((id) => Number(id)),
          cityIds: undefined,
          granularity: GRANULARITY.PREFECTURE
        }
      } else {
        return filterConfig.value
      }
    })()

    bizAreaMapResponse.value = await getBizAreaAnalysisMap({
      chainId: chainId.value,
      prefectureIds: pIds,
      cityIds,
      start: startMonth.value,
      end: endMonth.value,
      mapOptions: {
        granularity
      }
    }).then((res) => {
      if (chainBizAreaMap.value?.viewPoint) {
        // 地図の表示後は、中心を変えないようにする
        centerPoint.value = chainBizAreaMap.value?.viewPoint
      } else {
        // 地図の初期表示時、表示対象の座標の平均を中心とする
        centerPoint.value = getCenterPoint(res.data.features)
      }
      return res.data
    })
  } catch {
    notify.notifyErrorMessage('商圏マップが表示できませんでした。')
  } finally {
    bizAreaMapLoading.value = false
  }
}

const handleUpdateActiveAreaByAreaId = (areaId: string) => {
  const targetArea = areaList.value.find((item: AreaListItem) => item.areaId === areaId)
  if (!targetArea) return
  if (activeAreas.value.includes(targetArea)) {
    // ハイライト済みのエリアであれば除外
    activeAreas.value = toRaw(activeAreas.value).filter((item) => item.areaId !== targetArea.areaId)
  } else {
    activeAreas.value = [...toRaw(activeAreas.value), targetArea]
  }
}

const handleResetActiveArea = () => {
  activeAreas.value = []
}

const handleApproveAlertMap = () => {
  bizAreaMapApproval.value = true
}

// 適用ボタンで発火するコールバック関数
const handleApply = (args: { prefectureId?: number; cityIds?: string[] }) => {
  const { prefectureId, cityIds } = args

  // 絞り込みを行っていない状態で絞り込み解除を行った場合は何もしない
  if (!prefectureId && !cityIds && filterConfig.value.granularity === GRANULARITY.PREFECTURE) return

  const newGranularity =
    cityIds && cityIds.length > 0 ? GRANULARITY.TOWN_AND_AREA : GRANULARITY.MUNICIPALITIES

  filterConfig.value = {
    pIds: prefectureId ? [prefectureId] : [],
    cityIds: cityIds && cityIds.length > 0 ? cityIds : undefined,
    granularity: newGranularity
  }

  setFilterValue(prefectureId, cityIds, newGranularity)

  fetchAll({ isApply: true })
}

const resetFilterValue = () => {
  setFilterValue(undefined, undefined, GRANULARITY.PREFECTURE)
}

/* --------------------------------------------------------------------------
  来店者の居住地ランキング
 -------------------------------------------------------------------------- */

const bizAreaRankingLoading = ref<boolean>(false)
const bizAreaRankingApproval = ref<boolean>(false)
const bizAreaRankingResponse = ref<BizAreaAnalysisResponse | undefined>(undefined)
const bizAreaRankingData = computed<GoogleChartData>(() => {
  if (!bizAreaRankingResponse.value || !chain.value) return []
  if (activeAreas.value.length == 0)
    return processBizAreaRankingData(bizAreaRankingResponse.value, chain.value, areaList.value)
  return processBizAreaRankingData(bizAreaRankingResponse.value, chain.value, activeAreas.value)
})
const bizAreaRankingAlert = computed<boolean>(
  () => (bizAreaRankingResponse.value?.isAlert && !bizAreaRankingApproval.value) || false
)
const isNoDataInBizAreaRanking = computed<boolean>(
  () => bizAreaRankingResponse.value?.areas.every(({ visitRatio }) => !visitRatio) ?? true
)

const fetchBizAreaRanking = async () => {
  bizAreaRankingApproval.value = false
  if (bizAreaRankingLoading.value) return

  const { selectOrFileredPrefectureIds, resolveGranularity } = resolvePrefectureIdsAndGranularity()

  try {
    bizAreaRankingLoading.value = true
    bizAreaRankingResponse.value = await getBizAreaAnalysisChart(
      chainId.value,
      selectOrFileredPrefectureIds,
      startMonth.value,
      endMonth.value,
      resolveGranularity,
      filteredCityIds.value
    ).then((res) => res.data)
  } catch {
    notify.notifyErrorMessage('商圏ランキングが表示できませんでした。')
  } finally {
    bizAreaRankingLoading.value = false
  }
}

const handleUpdateActiveAreaByAreaName = (areaName: string) => {
  const targetArea = areaList.value.find(
    (item: AreaListItem) => item.areaName === areaName.replace(/^\d+．/, '')
  )
  if (!targetArea) return
  if (activeAreas.value.includes(targetArea)) {
    // ハイライト済みのエリアであれば除外
    activeAreas.value = toRaw(activeAreas.value).filter((item) => item.areaId !== targetArea.areaId)
  } else {
    activeAreas.value = [...toRaw(activeAreas.value), targetArea]
  }
}

const handleApproveBizAreaRanking = () => {
  bizAreaRankingApproval.value = true
}

const clikdownloadBizAreaAnalysisDistanceRankingChart = () => {
  const { selectOrFileredPrefectureIds, resolveGranularity } = resolvePrefectureIdsAndGranularity()

  return downloadBizAreaAnalysisChart(
    chainId.value?.toString(),
    selectOrFileredPrefectureIds,
    startMonth.value,
    endMonth.value,
    resolveGranularity,
    filteredCityIds.value
  )
}

// 「エリアを絞り込み」が適応された場合は、結果を選択されたprefectureIdに,granularityをPREFECTURE(2)に限定する
const resolvePrefectureIdsAndGranularity = () => {
  const selectOrFileredPrefectureIds = filteredPrefectureId.value
    ? [filteredPrefectureId.value.toString()]
    : prefectureIds.value.map((id) => id.toString())

  const resolveGranularity = filteredPrefectureId.value ? granularity.value : GRANULARITY.PREFECTURE

  return { selectOrFileredPrefectureIds, resolveGranularity }
}

/* --------------------------------------------------------------------------
  データ取得処理
 -------------------------------------------------------------------------- */

const fetchAll = ({ isApply = false } = {}) =>
  Promise.all([
    fetchDistanceRanking(),
    fetchBizAreaMap({ isApply: isApply }),
    fetchBizAreaRanking()
  ])

/* --------------------------------------------------------------------------
  created
 -------------------------------------------------------------------------- */

if (route.query.period) {
  const period = getPeriodByRouterQueryMonthPeriod(route.query.period as string)
  if (!!period) store.commit('setMonth', period)
}

if (chainId.value) {
  fetchAll()
}

/* --------------------------------------------------------------------------
  watch
 -------------------------------------------------------------------------- */

watch(
  () => [chainId.value, prefectureIds.value, startMonth.value, endMonth.value],
  () => {
    filteredPrefectureId.value = undefined
    filteredCityIds.value = undefined
    if (chainId.value) {
      fetchAll()
    }
  },
  { deep: true }
)
</script>

<style scoped>
.map_title {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.biz_area_sub_setting {
  height: 48px;
  background-color: #f5f5f5;
  opacity: 0.9;
  padding: 13px 15px 14px 16px;
  display: flex;
  align-items: center;
  margin-left: 42px;
  font-size: 14px;
}
.form {
  height: 30px;
  width: 50px;
  outline: 1px solid #cccccc;
  border-radius: 4px;
  opacity: 1;
  text-align: center;
  margin-left: 3px;
  margin-right: 3px;
  background: #ffffff;
}
.button {
  margin-left: 10px;
  width: 50px;
  height: 28px;
  background: #222222 0% 0% no-repeat padding-box;
  box-shadow: 1px 1px 0px #00000029;
  border-radius: 4px;
  opacity: 1;
  color: #ffffff;
  font-weight: bold;
}

.button:disabled {
  background: #cccccc 0% 0% no-repeat padding-box;
}

.reset_button {
  font-size: 12px;
  font-weight: normal;
  color: #4d99d0;
}
.card-in-column-2 {
  padding-left: 20px;
}
.divider {
  border-bottom: 5px solid #eee;
  margin: 40px -30px;
}
.bizarea-container {
  position: relative;
}
.mr-27px {
  margin-right: 27px;
}
.mb-20px {
  margin-bottom: 20px;
}
</style>
