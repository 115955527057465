<template>
  <div class="store-select-map">
    <v-overlay
      :absolute="true"
      :value="loading"
      style="z-index: 3"
    >
      <LoadingImg />
    </v-overlay>
    <SearchAutoComplete
      type="emit"
      class="map_search_bar"
      width="480px"
      list-width="150%"
      @set-store="setStore($event)"
    />
    <div
      v-if="!baseStore"
      class="map"
    >
      <div class="map-no-store-overlay">
        <span>検索窓から1つ店舗を選択すると、<br>MAPが表示されます。</span>
      </div>
    </div> 
    <MglMap
      v-else
      class="map"
      :access-token="mapAccessToken"
      :map-style="mapStyle"
      :center="center"
      :zoom="zoom"
      :attribution-control="false"
      :logo-position="'bottom-right'"
      :drag-rotate="false"
      :touch-zoom-rotate="false"
      :pitch-with-rotate="false"
      @idle="idleEvent"
      @load="initMap"
    >
      <!-- 店舗一覧 -->
      <MglGeojsonLayer
        v-if="storesSource"
        ref="stores"
        source-id="storesSource"
        :source="storesSource"
        layer-id="storesLayer"
        :layer="storesLayer"
        :clear-source="true"
      />
      <!-- 選択店舗一覧 -->
      <MglMarker
        v-for="store in selectedStores"
        :key="store.storeId"
        :coordinates="[store.longitude, store.latitude]"
      >
        <template #marker>
          <img
            v-if="store.storeType === STORE_TYPE.SUPER_MARKET['value']"
            src="@/assets/svg/checkbox-red-select.svg"
            class="cursor-pointer"
          >
          <img
            v-else-if="store.storeType === STORE_TYPE.DRUG_STORE['value']"
            src="@/assets/svg/checkbox-green-select.svg"
            class="cursor-pointer"
          >
          <img
            v-else-if="store.storeType === STORE_TYPE.HOME_CENTER['value']"
            src="@/assets/svg/checkbox-yellow-select.svg"
            class="cursor-pointer"
          >
          <img
            v-else-if="store.storeType === STORE_TYPE.CVS_STORE['value']"
            src="@/assets/svg/checkbox-purple-select.svg"
            class="cursor-pointer"
          >
        </template>
      </MglMarker>
      <!-- クラスタ化店舗 -->
      <MglGeojsonLayer
        v-if="storesSource"
        ref="storeClusterCircle"
        source-id="storesSource"
        :source="storesSource"
        layer-id="storesClusterLayer"
        :layer="storesClusterLayer"
        :clear-source="true"
      />
      <!-- クラスタ数テキスト -->
      <MglGeojsonLayer
        v-if="storesSource"
        ref="storeClusterText"
        source-id="storesSource"
        :source="storesSource"
        layer-id="storesClusterText"
        :layer="storesClusterText"
        :clear-source="true"
      />
      <!-- 基準店舗を示す円 -->
      <MglGeojsonLayer
        v-if="circleLayer"
        ref="circle"
        :source-id="circleSource?.data.id"
        :source="circleSource"
        :layer-id="circleLayer.id"
        :layer="circleLayer"
        :clear-source="true"
        before="storesCheckBoxes"
      />
      <!-- 基準店舗 -->
      <MglGeojsonLayer
        v-if="baseStoreLayer"
        ref="storeClusterCircle"
        :source-id="circleSource?.data.id"
        :source="circleSource"
        :layer-id="baseStoreLayer.id"
        :layer="baseStoreLayer"
        :clear-source="true"
      />
      <!-- 店舗詳細表示用ポップアップ -->
      <MglPopup
        :showed="showedPopup"
        :coordinates="popupCoordinates"
        :close-button="false"
        :offset="10"
      >
        <MapPopup
          :name="popupName"
          :store-type="popupStoreType"
        />
      </MglPopup>
      <MglAttributionControl v-if="center" />
      <MglNavigationControl
        v-if="center"
        :show-compass="false"
        position="top-right"
      />
      <MglScaleControl position="bottom-left" />
    </MglMap>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, nextTick, computed, onBeforeUnmount, onUpdated, watch} from "vue";
import {
  MglMap,
  MglGeojsonLayer,
  MglMarker,
  MglAttributionControl,
  MglNavigationControl,
  MglScaleControl,
  MglPopup,
} from "@/vendor/vue-mapbox/main";
import LoadingImg from "@/commons/components/loadingImg.vue";
import SearchAutoComplete from "@/commons/components/StoreSelector/SearchAutoComplete.vue";
import MapPopup from "@/features/Dashboard/components/MapPopup.vue";
import { Store } from "@/commons/interfaces/responses/store";
import checkboxRedNoSelectSVG from "@/assets/svg/checkbox-red-no-select.svg";
import checkboxRedSelectSVG from "@/assets/svg/checkbox-red-select.svg";
import checkboxGreenNoSelectSVG from "@/assets/svg/checkbox-green-no-select.svg";
import checkboxGreenSelectSVG from "@/assets/svg/checkbox-green-select.svg";
import checkboxYellowNoSelectSVG from "@/assets/svg/checkbox-yellow-no-select.svg";
import checkboxYellowSelectSVG from "@/assets/svg/checkbox-yellow-select.svg";
import checkboxPurpleNoSelectSVG from "@/assets/svg/checkbox-purple-no-select.svg";
import checkboxPurpleSelectSVG from "@/assets/svg/checkbox-purple-select.svg";
import checkboxDisabledSVG from "@/assets/svg/checkbox-disabled.svg";
import { STORE_TYPE } from "@/commons/enums";
import { MAP_ACCESS_TOKEN, MAP_STYLE } from "@/config";
import { debounce } from "lodash";
import { useStore } from "vuex";

type Source = {
  id?: string;
  type: string;
  data: {
    id?: string;
    type: string;
    features: {
      type: string;
      geometry: {
        type: string;
        coordinates: number[] | undefined;
      };
      properties?: {
        icon: string;
        store: Store;
        storeType: number;
      };
    }[];
  };
  cluster?: boolean;
  clusterMaxZoom?: number;
  clusterRadius?: number;
};

type Layer = {
  id: string | undefined;
  type: string;
  source: Source | undefined;
  layout?: any;
  paint?: any;
  filter?: any;
};

export default defineComponent({
  name: "StoreSelectMap",
  components: {
    MglMap,
    MglGeojsonLayer,
    MglMarker,
    MglAttributionControl,
    MglNavigationControl,
    MglScaleControl,
    MglPopup,
    LoadingImg,
    SearchAutoComplete,
    MapPopup,
  },
  props: {
    loading: { type: Boolean, default: false },
    storeTypes: {
      type: Array as PropType<{ id: number; name: string }[]>,
      default: () =>{return []},
    },
    selectedStores: {
      type: Array as PropType<Store[]>, required: true,
    },
  },
  emits: ["updateSelectedStore", "mb-add"],
  setup(props, { emit }) {
    let map: any = undefined;
    let initializedMap = false;

    const store = useStore();
    const baseStore = ref<Store>();
    const zoom = ref<number>(14);
    const storesSource = ref<Source>();
    const storesLayer = ref<Layer>();
    const storesClusterLayer = ref<Layer>();
    const storesClusterText = ref<Layer>();
    const circleSource = ref<Source>();
    const baseStoreLayer = ref<Layer>();
    const circleLayer = ref<Layer>();
    const popupName = ref<string>("");
    const popupStoreType = ref<number>(0);
    const popupCoordinates = ref<number[]>([0, 0]);
    const showedPopup = ref<boolean>(true);
    const stores = ref();
    const circle = ref();

    const center = computed((): number[] | undefined => {
      if (!baseStore.value) return undefined;
      return [baseStore.value.longitude, baseStore.value.latitude];
    });
    const computedStoreTypes = computed((): { id: number; name: string }[] => {
      return JSON.parse(JSON.stringify(props.storeTypes));
    });
    const created = ()=> {
      updateStoresLayer();

      if (!baseStore.value && props.selectedStores.length >= 1)
        baseStore.value = props.selectedStores[0];
    };

    const idleEvent = (event: any) => {
      event.map.resize();
    };
    const setStore = (event: { store: Store })  => {
      baseStore.value = event.store;
    };
    const  updateStoresLayer  = async () => {
      if (stores.value) {
        resetStoresLayer();
        (async () => {
          await new Promise((resolve) => setTimeout(resolve, 0));
          createStoresLayer();
        })();
      } else createStoresLayer();
    };
    const createstoresSource = () => {
      let stores: Store[] = [];
      const storeTypes: number[] = computedStoreTypes.value.map(
        (item) => item.id
      );
      if (map) {
        let bounds = map.getBounds();
        stores = store.state.stores.filter((store: Store) => {
          // boundsがある場合は、bounds内の店舗のみ表示する
          return (
            storeTypes.includes(store.storeType) &&
            bounds.contains([store.longitude, store.latitude])
          );
        });
      }
      return {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: stores.map((store: Store) => {
            let storeIcon = "checkbox-red-no-select";
            if (store.storeType === STORE_TYPE.DRUG_STORE.value)
              storeIcon = "checkbox-green-no-select";
            else if (store.storeType === STORE_TYPE.HOME_CENTER.value)
              storeIcon = "checkbox-yellow-no-select";
            else if (store.storeType === STORE_TYPE.CVS_STORE.value)
              storeIcon = "checkbox-purple-no-select";

            return {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [store.longitude, store.latitude],
              },
              properties: {
                icon: storeIcon,
                store: store,
                storeType: store.storeType,
              },
            };
          }),
        },
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 30,
      };
    };

    const createStoresLayer = () => {
      storesSource.value = createstoresSource();
      storesLayer.value = {
        id: "storesCheckBoxes",
        type: "symbol",
        source: storesSource.value,
        layout: {
          visibility: "visible",
          "icon-image": "{icon}",
          "icon-allow-overlap": true,
        },
        filter: ["!", ["has", "point_count"]],
      };
      storesClusterLayer.value = {
        id: "storeCluster",
        type: "circle",
        source: storesSource.value,
        paint: {
          "circle-radius": [
            "step",
            ["get", "point_count"],
            25,
            30,
            32,
            50,
            43,
            100,
            54,
            300,
            64,
          ],
          "circle-color": "#fff",
          "circle-stroke-color": "#aaa",
          "circle-stroke-width": 2,
          "circle-opacity": 0.3,
        },
        filter: ["has", "point_count"],
      };
      storesClusterText.value = {
        id: "storeClusterLabel",
        type: "symbol",
        source: storesSource.value,
        filter: ["has", "point_count"],
        layout: {
          "text-font": ["Noto Sans JP Medium"],
          "text-field": "{point_count}",
          "text-size": [
            "step",
            ["get", "point_count"],
            13,
            30,
            14,
            50,
            19,
            100,
            24,
            300,
            28,
          ],
        },
        paint: {
          "text-color": "#222",
        },
      };
    };
    const updateStoresData = () => {
      // 毎回レイヤーを作り直していると「No cluster with the specified id」が発生するのでデータのみ更新する
      //const map = (this as any).$refs.stores.map;
      //const map = store.state.stores.map;
      storesSource.value = createstoresSource();

      if (map.getSource("storesCheckBoxes"))
        map.getSource("storesCheckBoxes").setData(storesSource.value.data);
      if (map.getSource("storeCluster"))
        map.getSource("storeCluster").setData(storesSource.value.data);
      if (map.getSource("storeClusterLabel"))
        map.getSource("storeClusterLabel").setData(storesSource.value.data);
    };
    const resetStoresLayer = () => {
      try {
        if (map === undefined) return;
        if (map.getLayer("storesCheckBoxes"))
          map.removeLayer("storesCheckBoxes");
        if (map.getSource("storesCheckBoxes"))
          map.removeSource("storesCheckBoxes");
        if (map.getLayer("storeCluster")) map.removeLayer("storeCluster");
        if (map.getSource("storeCluster")) map.removeSource("storeCluster");
        if (map.getLayer("storeClusterLabel"))
          map.removeLayer("storeClusterLabel");
        if (map.getSource("storeClusterLabel"))
          map.removeSource("storeClusterLabel");
      } catch (error) {
        console.error(error);
        throw new Error("Failed to delete superMarkets layer.");
      }

      storesSource.value = undefined;
      storesLayer.value = undefined;
      storesClusterLayer.value = undefined;
      storesClusterText.value = undefined;
    };
    const updateCircle = () => {
      if (circle.value) {
        // awaitで順番を保証する
        (async () => {
          await new Promise((resolve) => setTimeout(resolve, 0));
          await resetCircle();
          await new Promise((resolve) => setTimeout(resolve, 0));
          await createCircle();
          onDelayAction(updateStoresData);
        })();
      } else createCircle();
    };
    const createCircle = () => {
      if (!baseStore.value) return;
      let storeIcon = "checkbox-red-no-select";
      if (baseStore.value?.storeType === STORE_TYPE.DRUG_STORE.value)
        storeIcon = "checkbox-green-no-select";
      else if (baseStore.value?.storeType === STORE_TYPE.HOME_CENTER.value)
        storeIcon = "checkbox-yellow-no-select";
      else if (baseStore.value.storeType === STORE_TYPE.CVS_STORE.value)
        storeIcon = "checkbox-purple-no-select";
      
      circleSource.value = {
        type: "geojson",
        data: {
          id: "circleSource",
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: center.value,
              },
              properties: {
                icon: storeIcon,
                store: baseStore.value,
                storeType: baseStore.value.storeType,
              },
            },
          ],
        },
      };
      baseStoreLayer.value = {
        id: "baseStoreCheckBoxes",
        type: "symbol",
        source: circleSource.value,
        layout: {
          visibility: "visible",
          "icon-image": "{icon}",
          "icon-allow-overlap": true,
        },
      };
      circleLayer.value = {
        id: "circleLayer",
        type: "circle",
        source: circleSource.value,
        layout: {},
        paint: {
          "circle-radius": 20,
          "circle-color": "#519ACE",
          "circle-stroke-color": "#519ACE",
          "circle-stroke-width": 2,
          "circle-opacity": 0.3,
        },
      };
    };
    const resetCircle = () => {
      try {
        if (!circle.value || !circleSource.value || !baseStoreLayer.value || !circleLayer.value) return;

        if (map.getLayer(circleSource.value.data.id))
          map.removeLayer(circleSource.value.data.id);
        if (map.getSource(circleSource.value.data.id))
          map.removeSource(circleSource.value.data.id);

        if (map.getLayer(baseStoreLayer.value.id))
          map.removeLayer(baseStoreLayer.value.id);
        if (map.getSource(baseStoreLayer.value.id))
          map.removeSource(baseStoreLayer.value.id);

        if (map.getLayer(circleLayer.value.id)) map.removeLayer(circleLayer.value.id);
        if (map.getSource(circleLayer.value.id))
          map.removeSource(circleLayer.value.id);
      } catch (error) {
        console.error(error);
        throw new Error("Failed to delete circle layer.");
      }
      circleSource.value = undefined;
      baseStoreLayer.value = undefined;
      circleLayer.value = undefined;
    };
    const initMap = async(event: any) => {
      map = event.map;
      let count = 0;
      // NOTE: Map 配下のレイヤーが DOM としてマウントされるまで 100ms 毎に繰り返しチェック(最大10s)。マウントされた後初期化を行う。
      const interval = setInterval(() => {
        count++;
        if (stores.value && !initializedMap) {
          // アイコン画像の追加
          let checkboxImg1 = new Image(20, 20);
          checkboxImg1.onload = () => 
            map.addImage("checkbox-red-no-select", checkboxImg1);
          checkboxImg1.src = checkboxRedNoSelectSVG;
          let checkboxImg2 = new Image(20, 20);
          checkboxImg2.onload = () =>
            map.addImage("checkbox-red-select", checkboxImg2);
          checkboxImg2.src = checkboxRedSelectSVG;
          let checkboxImg3 = new Image(20, 20);
          checkboxImg3.onload = () =>
            map.addImage("checkbox-green-no-select", checkboxImg3);
          checkboxImg3.src = checkboxGreenNoSelectSVG;
          let checkboxImg4 = new Image(20, 20);
          checkboxImg4.onload = () =>
            map.addImage("checkbox-green-select", checkboxImg4);
          checkboxImg4.src = checkboxGreenSelectSVG;
          let checkboxImg5 = new Image(20, 20);
          checkboxImg5.onload = () =>
            map.addImage("checkbox-yellow-no-select", checkboxImg5);
          checkboxImg5.src = checkboxYellowNoSelectSVG;
          let checkboxImg6 = new Image(20, 20);
          checkboxImg6.onload = () =>
            map.addImage("checkbox-yellow-select", checkboxImg6);
          checkboxImg6.src = checkboxYellowSelectSVG;
          let checkboxImg7 = new Image(20, 20);
          checkboxImg7.onload = () =>
            map.addImage("checkbox-purple-no-select", checkboxImg7);
          checkboxImg7.src = checkboxPurpleNoSelectSVG;
          let checkboxImg8 = new Image(20, 20);
          checkboxImg8.onload = () =>
            map.addImage("checkbox-purple-select", checkboxImg8);
          checkboxImg8.src = checkboxPurpleSelectSVG;
          let checkboxImg9 = new Image(20, 20);
          checkboxImg9.onload = () =>
            map.addImage("checkbox-disabled", checkboxImg9);
          checkboxImg9.src = checkboxDisabledSVG;

          // クラスターの円をクリックでズーム倍率を上げる
          map.on("click", "storeCluster", (e: any) => {
            const features = map.queryRenderedFeatures(e.point, {
              layers: ["storeCluster"],
            });
            const clusterId = features[0].properties.cluster_id;
            map
              .getSource("storesSource")
              .getClusterExpansionZoom(clusterId, (err: any, zoom: any) => {
                // ズーム倍率が18以上の場合は16にする
                if (zoom >= 18) zoom = 16;
                if (err) return;
                map.easeTo({
                  center: features[0].geometry.coordinates,
                  zoom: zoom,
                });
              });
          });
          // クリックした店舗の追加or削除
          map.on("click", "storesCheckBoxes", (e: any) => {
            const store = JSON.parse(e.features[0].properties.store);
            if (baseStore.value && store.storeId == baseStore.value?.storeId)
              return;
            emit("updateSelectedStore", { store: store });
          });

          map.on("mouseenter", "storesCheckBoxes", (e: any) => {
            const store = JSON.parse(e.features[0].properties.store);
            popupCoordinates.value = e.features[0].geometry.coordinates.slice();
            popupName.value = store.name;
            popupStoreType.value = store.storeType;
            showedPopup.value = false;
          });
          map.on("mouseleave", "storesCheckBoxes", () => {
            popupCoordinates.value = [0, 0];
            popupName.value = "";
            popupStoreType.value = 0;
            showedPopup.value = true;
          });
          map.on("mouseenter", "storesCheckBoxes", () => {
            map.getCanvas().style.cursor = "pointer";
          });
          map.on("mouseleave", "storesCheckBoxes", () => {
            map.getCanvas().style.cursor = "";
          });

          map.on("click", "baseStoreCheckBoxes", (e: any) => {
            const store = JSON.parse(e.features[0].properties.store);
            emit("updateSelectedStore", { store: store });
          });
          map.on("mouseenter", "baseStoreCheckBoxes", (e: any) => {
            const store = JSON.parse(e.features[0].properties.store);
            popupCoordinates.value = e.features[0].geometry.coordinates.slice();
            popupName.value = store.name;
            popupStoreType.value = store.storeType;
            showedPopup.value = false;
          });
          map.on("mouseleave", "baseStoreCheckBoxes", () => {
            popupCoordinates.value = [0, 0];
            popupName.value = "";
            popupStoreType.value = 0;
            showedPopup.value = true;
          });
          map.on("mouseenter", "baseStoreCheckBoxes", () => {
            map.getCanvas().style.cursor = "pointer";
          });
          map.on("mouseleave", "baseStoreCheckBoxes", () => {
            map.getCanvas().style.cursor = "";
          });

          map.on("mouseenter", "storeCluster", () => {
            map.getCanvas().style.cursor = "pointer";
          });
          map.on("mouseleave", "storeCluster", () => {
            map.getCanvas().style.cursor = "";
          });

          map.on("moveend", () => {
            // zoom倍率が10以上かつ20以下の場合は店舗を表示する
            if (map.getZoom() >= 10 && map.getZoom() <= 20) {
              onDelayAction(updateStoresData);
            }
          });
          onDelayAction(updateStoresData);
          initializedMap = true;
        }
        if (count === 100 || stores.value)
          clearInterval(interval);
      }, 100);
    };
    const onDelayAction =  debounce((fn: any) => {
      fn.apply(null);
    }, 1000);
      
    onBeforeUnmount(() => {
      resetStoresLayer();
      resetCircle();
    });
    onUpdated(() => {
      nextTick(() => {
        // 編集時の基準店舗初期化
        if (!baseStore.value && props.selectedStores.length >= 1)
          baseStore.value = props.selectedStores[0];
      });
    });
    watch([baseStore, computedStoreTypes], ([newBaseStore, newComputedStoreTypes]) => {
      if (newBaseStore) updateCircle();
      if (newComputedStoreTypes) updateStoresLayer();          
    });

    created();
    
    return {
      baseStore,
      mapAccessToken: MAP_ACCESS_TOKEN,
      mapStyle: MAP_STYLE,
      zoom,
      storesSource,
      storesLayer,
      storesClusterLayer,
      storesClusterText,
      circleSource,
      baseStoreLayer,
      circleLayer,
      popupName,
      popupStoreType,
      popupCoordinates,
      showedPopup,      
      STORE_TYPE: STORE_TYPE,
      center,
      idleEvent,
      setStore,
      initMap,
      stores,
      circle,
    };
  },
});
</script>

<style lang="scss" scoped>
.store-select-map {
  width: 100%;
  position: relative;

  .map_search_bar {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 2;
  }

  .map-no-store-overlay {
    position: absolute;
    background: #eeeeee;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .map {
    height: 580px;
  }
}

.cursor-pointer {
  cursor: pointer;
}
</style>
