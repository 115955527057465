import { ComparisonMapBoxFeatures } from '../types'

export function processMapData(
  features: ComparisonMapBoxFeatures[],
  chainIdToColorMap: Map<string, string>
): ComparisonMapBoxFeatures[] {
  if (features.length === 0) return []

  return features.map((feature) => {
    // 来店者の割合を算出する際に使用した分子(numerator) が最大のチェーン
    // 同率の場合はランク(index)順
    const topChain = feature.properties.chains.reduce((prev, current) => {
      if (prev.numerator > current.numerator) {
        return prev
      }

      if (prev.numerator === current.numerator) {
        return prev.index < current.index ? prev : current
      }

      return current
    })

    return {
      type: feature.type,
      properties: {
        chains: feature.properties.chains,
        areaId: feature.properties.areaId,
        areaName: feature.properties.areaName,
        latitude: feature.properties.latitude,
        longitude: feature.properties.longitude,
        isPrivacyAlert: feature.properties.isPrivacyAlert,
        maxColor: chainIdToColorMap.get(topChain.chainId) ?? '',
        maxChainName: topChain.chainName,
        maxVisitRatio: topChain.visitRatio
      },
      geometry: feature.geometry
    }
  })
}

export function areaPrivacyAlerts(alertAreaNames: string[]): string {
  const alertAreaTen = alertAreaNames.slice(0, 10).join(', ')
  return alertAreaNames.length > 10
    ? `${alertAreaTen} などを含む${alertAreaNames.length}エリア`
    : alertAreaTen
}
