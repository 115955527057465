<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row
      class="title-container"
      dense
      no-gutters
    >
      <v-col style="display: flex; align-items: center">
        <h1 class="title-text">
          来店特性
        </h1>
        <update-badge
          type="month"
          style="margin-left: 15px"
        />
      </v-col>
      <v-spacer />
      <monthly-date-picker
        :start-date="$store.state.startMonth"
        :end-date="$store.state.endMonth"
        @update-period="updatePeriod"
      />
    </v-row>
    <v-row
      dense
      no-gutters
      class="card-container"
    >
      <v-col>
        <store-selector :loading="storeLoading" />
      </v-col>
    </v-row>
    <v-row
      dense
      no-gutters
      class="card-container"
    >
      <v-col>
        <chart-card
          title="来店頻度"
          :visit-pattern-data="frequencyData"
          :chart-loading="frequencyChartLoading"
          :legends="frequencyChartLegends()"
          :get-file-id="getVisitFrequencyChartFileId"
          chart-key="visitFrequency"
          :csv-name="visitFrequencyChartCSVName"
        />
      </v-col>
    </v-row>
    <v-row
      dense
      no-gutters
      class="card-container mb-0"
    >
      <v-col>
        <chart-card
          title="エンゲージメント"
          :visit-pattern-data="engagementData"
          :chart-loading="engagementChartLoading"
          :legends="engagementChartLegends()"
          :get-file-id="getEngagementChartFileId"
          chart-key="engagement"
          :csv-name="engagementChartCSVName"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { getPeriodByRouterQueryMonthPeriod } from "@/commons/utils";
import {
  getEndMonthOf13Month,
  getStartMonthOf13Month,
} from "@/commons/utils/dateUtil";
import {
  getEngagementChart,
  getVisitFrequencyChart,
} from "@/features/ShopAnalytics/axios/visitEngagement";
import {
  EngagementResponse,
  FrequencyResponse,
} from "@/features/ShopAnalytics/interfaces/response";
import {
  ComparisonGroupStore,
  StoreComparisonChartResponse,
} from "@/features/StoreCompare/types";
import {
  downloadEngagementChart,
  downloadVisitFrequencyChart,
} from "@/features/StoreCompare/visitPattern/axios";
// TODO: commons に寄せる
import StoreSelector from "@/features/StoreCompare/components/StoreSelector.vue";
import UpdateBadge from "@/features/ShopAnalytics/components/Common/updateBadge.vue";
import { ComparisonGroup } from "@/features/StoreCompare/types";
import ChartCard from "@/features/StoreCompare/visitPattern/components/ChartCard.vue";
import {
  processEngagementChart,
  processFrequencyChart,
} from "@/features/StoreCompare/visitPattern/utils";
import * as notify from "@/plugins/notification";
import { AxiosResponse } from "axios";
import { defineComponent } from "vue";
import MonthlyDatePicker from "@/commons/components/DatePicker/MonthlyDatePicker.vue";
import { convertHyphenDelimiter } from "@/commons/utils/dateUtil";
import { COLOR } from "@/commons/enums";

export default defineComponent({
  components: {
    MonthlyDatePicker,
    StoreSelector,
    ChartCard,
    UpdateBadge,
  },
  data() {
    return {
      created: false,
      storeLoading: false,
      frequencyData: [] as (string | number | boolean | unknown)[][],
      engagementData: [] as (string | number | boolean | unknown)[][],
      frequencyChartLoading: false,
      engagementChartLoading: false,
      frequencyChartController: new AbortController(),
      engagementChartController: new AbortController(),
      frequencyChartLegends(): { text: string; color: string }[] {
        return [
          { text: "月1回", color: COLOR.RED },
          { text: "月2回", color: COLOR.BLUE },
          { text: "月3回", color: COLOR.GREEN },
          { text: "月4回以上", color: COLOR.ORANGE },
          { text: "月8回以上", color: COLOR.PURPLE },
          { text: "参考値（データ不十分）", color: COLOR.GRAY },
        ];
      },
      engagementChartLegends(): { text: string; color: string }[] {
        return [
          { text: "新規", color: COLOR.RED },
          { text: "継続", color: COLOR.BLUE },
          { text: "復活", color: COLOR.GREEN },
          { text: "離反（当月）", color: COLOR.ORANGE },
          { text: "離反（先月）", color: COLOR.PURPLE },
          { text: "参考値（データ不十分）", color: COLOR.GRAY },
        ];
      },
    };
  },
  computed: {
    hasRouterQueryPeriod(): boolean {
      return this.$route.query.period !== undefined;
    },
    // NOTE: watch で監視するために定義
    selectedComparisonGroup(): ComparisonGroup {
      return this.$store.state.selectedComparisonGroup;
    },
    selectedStores(): ComparisonGroupStore[] {
      return this.$store.getters.selectedComparisonGroupVisibleStores;
    },
    legends(): { text: string; color: string }[] {
      if (!this.$store.state.selectedComparisonGroup) return [];
      return this.selectedStores.map((store) => {
        return { text: store.name, color: store.color ?? "" };
      });
    },
    getVisitFrequencyChartFileId(): () => Promise<AxiosResponse<any, any>> {
      return () =>
        downloadVisitFrequencyChart(
          this.selectedStores.map((s) => s.id),
          this.$store.state.startMonth,
          this.$store.state.endMonth
        );
    },
    getEngagementChartFileId(): () => Promise<AxiosResponse<any, any>> {
      return () =>
        downloadEngagementChart(
          this.selectedStores.map((s) => s.id),
          this.$store.state.startMonth,
          this.$store.state.endMonth
        );
    },
    visitFrequencyChartCSVName(): string {
      return `店舗比較_来店頻度_${getStartMonthOf13Month()}-${getEndMonthOf13Month()}`;
    },
    engagementChartCSVName(): string {
      return `店舗比較_エンゲージメント_${getStartMonthOf13Month()}-${getEndMonthOf13Month()}`;
    },
  },
  watch: {
    async selectedComparisonGroup() {
      if (this.created) {
        this.frequencyChartController.abort();
        this.engagementChartController.abort();

        if (this.$store.state.startMonth <= this.$store.state.endMonth) {
          await this.fetchFrequencyChart();
          await this.fetchEngagementChart();
          await this.pushRoute();
        }
      }
    },
    async selectedStores() {
      if (this.created) {
        this.frequencyChartController.abort();
        this.engagementChartController.abort();

        if (this.$store.state.startMonth <= this.$store.state.endMonth) {
          await this.fetchFrequencyChart();
          await this.fetchEngagementChart();
          await this.pushRoute();
        }
      }
    },
  },
  async created() {
    // クエリパラメータからデータ更新
    this.setDateFromRouterQuery();

    this.storeLoading = true;
    // 店舗一覧
    if (!this.$store.state.stores.length)
      await this.$store.dispatch("fetchStores");

    // 比較店舗リスト一覧
    if (!this.$store.state.comparisonGroups.length)
      await this.$store.dispatch("fetchComparisonGroups");

    // id があれば選択比較店舗リストを設定,無ければ初期化
    if (this.$route.params["id"]) {
      await this.$store.dispatch(
        "specifiedComparisonGroup",
        this.$route.params["id"]
      );
      if (!this.selectedStores || this.selectedStores.length === 0)
        this.$router.push({ name: "NotFound" });
    } else this.$store.commit("initComparisonGroup");
    this.storeLoading = false;

    // チャート取得
    await this.fetchFrequencyChart();
    await this.fetchEngagementChart();

    this.created = true;
  },
  methods: {
    initFrequencyData() {
      this.frequencyData.length = 0;
    },
    initEngagementData() {
      this.engagementData.length = 0;
    },
    async updatePeriod(period: { startMonth: string; endMonth: string }) {
      this.$store.commit("setMonth", {
        startMonth: period.startMonth,
        endMonth: period.endMonth,
      });
      await this.fetchFrequencyChart();
      await this.fetchEngagementChart();
      await this.pushRoute();
    },
    setDateFromRouterQuery() {
      if (this.hasRouterQueryPeriod) {
        let tmp = getPeriodByRouterQueryMonthPeriod(this.$route.query.period as string);
        if (tmp !== undefined) this.$store.commit("setMonth", tmp);
      }
    },
    async fetchFrequencyChart() {
      if (!this.selectedComparisonGroup) return;
      this.frequencyChartLoading = true;
      // チャートデータの初期化
      this.initFrequencyData();

      this.frequencyChartController = new AbortController();
      // 来店頻度チャートデータ取得
      const frequencyGraphs: StoreComparisonChartResponse<FrequencyResponse>[] =
        [];
      let canceled = false;
      await Promise.all(
        this.selectedStores.map(async (store) => {
          await getVisitFrequencyChart(
            store.id,
            convertHyphenDelimiter(this.$store.state.startMonth),
            convertHyphenDelimiter(this.$store.state.endMonth),
            this.frequencyChartController.signal
          )
            .then((res: AxiosResponse<FrequencyResponse>) => {
              frequencyGraphs.push({
                orderIndex: store.orderIndex,
                storeName: store.name,
                visitCounts: res.data.visitCounts,
              });
            })
            .catch((e) => {
              if (typeof e === "undefined" || e.code !== "ERR_CANCELED") {
                notify.notifyErrorMessage("来店頻度が表示できませんでした。");
              } else if (e.code === "ERR_CANCELED") {
                canceled = true;
              }
            });
        })
      ).then((f) => {
        if (this.selectedStores.length === f.length && !canceled)
          this.frequencyChartLoading = false;
      });

      this.frequencyData = processFrequencyChart(frequencyGraphs);
    },
    async fetchEngagementChart() {
      if (!this.selectedComparisonGroup) return;
      this.engagementChartLoading = true;
      // チャートデータの初期化
      this.initEngagementData();

      this.engagementChartController = new AbortController();
      // エンゲージメントチャートデータ取得
      const engagementGraphs: StoreComparisonChartResponse<EngagementResponse>[] =
        [];
      let canceled = false;
      await Promise.all(
        this.selectedStores.map(async (store) => {
          await getEngagementChart(
            store.id,
            convertHyphenDelimiter(this.$store.state.startMonth),
            convertHyphenDelimiter(this.$store.state.endMonth),
            this.engagementChartController.signal
          )
            .then((res: AxiosResponse<EngagementResponse>) => {
              engagementGraphs.push({
                orderIndex: store.orderIndex,
                storeName: store.name,
                visitCounts: res.data.visitCounts,
              });
            })
            .catch((e) => {
              if (typeof e === "undefined" || e.code !== "ERR_CANCELED") {
                notify.notifyErrorMessage(
                  "エンゲージメントが表示できませんでした。"
                );
              } else if (e.code === "ERR_CANCELED") {
                canceled = true;
              }
            });
        })
      ).then((f) => {
        if (this.selectedStores.length === f.length && !canceled)
          this.engagementChartLoading = false;
      });
      this.engagementData = processEngagementChart(engagementGraphs);
    },
    async pushRoute() {
      if (!this.selectedComparisonGroup) return;
      const tmp = {
        start: convertHyphenDelimiter(this.$store.state.startMonth),
        end: convertHyphenDelimiter(this.$store.state.endMonth),
      };

      // NOTE: 他ページに遷移後にreplaceが呼び出されると戻される対策
      if (this.$route.name === "StoreCompareVisitEngagement") {
        await this.$router
          .replace({
            name: "StoreCompareVisitEngagement",
            params: { id: this.$store.state.selectedComparisonGroup.id },
            query: {
              period: `${tmp.start}_${tmp.end}`,
            },
          })
          .catch(() => undefined);
      }
    },
  },
});
</script>
