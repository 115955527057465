<template>
  <div style="padding: 5px 0">
    <v-data-table
      :items="items"
      :items-per-page-options="rowPerPage"
      density="comfortable"
    >
      <template #headers>
        <tr>
          <th
            rowspan="2"
            class="bg-gray font-bold text-black"
          />
          <th
            rowspan="2"
            class="bg-gray font-bold text-center text-black"
          >
            来店人数合計
          </th>
          <th
            colspan="2"
            class="bg-gray font-bold text-black"
          >
            性別
          </th>
          <th
            colspan="6"
            class="bg-gray font-bold text-black"
          >
            年代
          </th>
        </tr>
        <tr>
          <th class="bg-gray font-bold text-center text-black">
            男性
          </th>
          <th class="bg-gray font-bold text-center text-black">
            女性
          </th>
          <th class="bg-gray font-bold text-center text-black">
            10代
          </th>
          <th class="bg-gray font-bold text-center text-black">
            20代
          </th>
          <th class="bg-gray font-bold text-center text-black">
            30代
          </th>
          <th class="bg-gray font-bold text-center text-black">
            40代
          </th>
          <th class="bg-gray font-bold text-center text-black">
            50代
          </th>
          <th class="bg-gray font-bold text-center text-black">
            60代〜
          </th>
        </tr>
        <tr
          v-if="total"
          class="bg-light-gray"
        >
          <th class="bg-light-gray text-center">
            <span class="font-bold black--text">
              {{ total.granularity }}
            </span>
          </th>
          <th class="bg-light-gray text-right">
            <span class="font-bold black--text">
              {{ total.total.toLocaleString() }}
            </span>
          </th>
          <th class="bg-light-gray text-right">
            <span class="font-bold black--text">
              {{ total.male.toLocaleString() }}
            </span>
            <span class="font-normal text-gray">
              <br>({{ calculatePer(total.male, total.total) }})
            </span>
          </th>
          <th class="bg-light-gray text-right">
            <span class="font-bold black--text">
              {{ total.female.toLocaleString() }}
            </span>
            <span class="font-normal text-gray">
              <br>({{ calculatePer(total.female, total.total) }})
            </span>
          </th>
          <th class="bg-light-gray text-right">
            <span class="font-bold black--text">
              {{ total.teen.toLocaleString() }}
            </span>
            <span class="font-normal text-gray">
              <br>({{ calculatePer(total.teen, total.total) }})
            </span>
          </th>
          <th class="bg-light-gray text-right">
            <span class="font-bold black--text">
              {{ total.twenties.toLocaleString() }}
            </span>
            <span class="font-normal text-gray">
              <br>({{ calculatePer(total.twenties, total.total) }})
            </span>
          </th>
          <th class="bg-light-gray text-right">
            <span class="font-bold black--text">
              {{ total.thirties.toLocaleString() }}
            </span>
            <span class="font-normal text-gray">
              <br>({{ calculatePer(total.thirties, total.total) }})
            </span>
          </th>
          <th class="bg-light-gray text-right">
            <span class="font-bold black--text">
              {{ total.forties.toLocaleString() }}
            </span>
            <span class="font-normal text-gray">
              <br>({{ calculatePer(total.forties, total.total) }})
            </span>
          </th>
          <th class="bg-light-gray text-right">
            <span class="font-bold black--text">
              {{ total.fifties.toLocaleString() }}
            </span>
            <span class="font-normal text-gray">
              <br>({{ calculatePer(total.fifties, total.total) }})
            </span>
          </th>
          <th class="bg-light-gray text-right">
            <span class="font-bold black--text">
              {{ total.sixties.toLocaleString() }}
            </span>
            <span class="font-normal text-gray">
              <br>({{ calculatePer(total.sixties, total.total) }})
            </span>
          </th>
        </tr>
      </template>
      <template #body="props">
        <tr
          v-for="item in props.items"
          :key="item.granularity"
        >
          <th
            :class="{
              'text-red': includesSunday(item.granularity),
              'text-blue': includesSaturday(item.granularity),
            }"
            class="text-left font-normal white-space-nowrap"
            style="width: 13em"
          >
            <span>{{ item.granularity }}</span>
          </th>
          <th class="text-right">
            <span class="font-normal">
              {{ item.total.toLocaleString() }}
            </span>
          </th>
          <th class="text-right">
            <span class="font-normal">
              {{ item.male.toLocaleString() }}
            </span>
          </th>
          <th class="text-right">
            <span class="font-normal">
              {{ item.female.toLocaleString() }}
            </span>
          </th>
          <th class="text-right">
            <span class="font-normal">
              {{ item.teen.toLocaleString() }}
            </span>
          </th>
          <th class="text-right">
            <span class="font-normal">
              {{ item.twenties.toLocaleString() }}
            </span>
          </th>
          <th class="text-right">
            <span class="font-normal">
              {{ item.thirties.toLocaleString() }}
            </span>
          </th>
          <th class="text-right">
            <span class="font-normal">
              {{ item.forties.toLocaleString() }}
            </span>
          </th>
          <th class="text-right">
            <span class="font-normal">
              {{ item.fifties.toLocaleString() }}
            </span>
          </th>
          <th class="text-right">
            <span class="font-normal">
              {{ item.sixties.toLocaleString() }}
            </span>
          </th>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { VisitorTableItem } from "@/features/ShopAnalytics/interfaces/response";

const props = withDefaults(
  defineProps<{
    total: VisitorTableItem,
    items: VisitorTableItem[],
  }>(),
  {
    total: undefined,
    items: () => []
  }
)
const rowPerPage = ref<number[]>([10, 25, 50, 100]);

function includesSunday(text: string) {
  const stringObj = new String(text);
  return stringObj.endsWith("（日）");
};
function includesSaturday(text: string) {
  const stringObj = new String(text);
  return stringObj.includes("土");
};
function calculatePer(top: number, bottom: number) {
  return ((top / bottom) * 100).toFixed(1) + "%";
}
</script>

<style scoped>
.v-data-table :deep(table) {
  border-collapse: collapse !important;
  border: 1px solid #ccc !important;
}
.v-data-table :deep(td) {
  border-collapse: collapse !important;
  border: 1px solid #ccc !important;
}
.v-data-table :deep(th) {
  border-collapse: collapse !important;
  border: 1px solid #ccc !important;
  font-size: 13px !important;
  user-select: text !important;
}
.bg-gray {
  background: #eee !important;
}
.bg-light-gray {
  background: #f5f5f5 !important;
}
.bg-light-gray:hover {
  background: #f5f5f5;
}
.text-red {
  color: #be0000;
}
.text-blue {
  color: #4d99d0;
}
.text-black {
  color: #333333 !important;
}
.text-gray {
  color: #666666;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.font-bold {
  font-weight: bold !important;
}
.font-normal {
  font-weight: normal !important;
}
.white-space-nowrap {
  white-space: nowrap;
}
</style>
