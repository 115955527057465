import { axiosClient } from '@/plugins/axios'
import { AxiosResponse } from 'axios'
import { convertHyphenDelimiter } from '@/commons/utils/dateUtil'
import { DownloadResponse } from '@/commons/interfaces/responses/download'
import qs from 'qs'
import { BizAreaAnalysisComparisonMapResponse, ComparisonTrendAreaAnalysisResponse } from '../types'
import { GRANULARITY } from '@/commons/enums'

type GetBizAreaComparisonMapParams = {
  baseChainId: string | undefined
  chainIds: string[]
  prefectureIds: number[]
  cityIds?: string[]
  start: string
  end: string
  mapOptions: {
    granularity: (typeof GRANULARITY)[keyof typeof GRANULARITY]
  }
}

/**
 * チェーン比較の商圏マップを取得
 * @param baseChainId 基準チェーンID
 * @param chainIds チェーンIDリスト(基準チェーンを除く)
 * @param prefectureIds 都道府県IDリスト
 * @param start 期間開始日(yyyy-MM-dd)
 * @param end 期間終了日(yyyy-MM-dd)
 * @param granularity 表示粒度(0: 市区町村, 1: 町丁目, 2: 都道府県)
 * @param latitude 地図上で表示されている中心点の緯度
 * @param longitude 地図上で表示されている中心点の経度
 */
export function getBizAreaComparisonMap({
  baseChainId,
  chainIds,
  prefectureIds,
  cityIds,
  start,
  end,
  mapOptions
}: GetBizAreaComparisonMapParams): Promise<AxiosResponse<BizAreaAnalysisComparisonMapResponse>> {
  if (!baseChainId) return Promise.reject()
  return axiosClient.post<BizAreaAnalysisComparisonMapResponse>(
    '/chains/chart/trend-area-analysis/chain-comparison/geo/',
    {
      baseChainId,
      chainIds,
      prefectureIds,
      cityIds,
      start: convertHyphenDelimiter(start),
      end: convertHyphenDelimiter(end),
      mapOptions
    },
    { cancelPreviousRequests: true }
  )
}

/**
 * チェーン比較の商圏ランキングを取得
 * @param baseChainId 基準チェーンID
 * @param chainIds チェーンIDリスト(基準チェーンを除く)
 * @param prefectureIds 都道府県IDリスト
 * @param cityIds 市区町村IDリスト
 * @param start 期間開始日(yyyy-MM-dd)
 * @param end 期間終了日(yyyy-MM-dd)
 * @param granularity 表示粒度(0: 市区町村, 1: 町丁目, 2: 都道府県)
 */
export function getBizAreaComparisonTable(
  baseChainId: string | undefined,
  chainIds: string[],
  prefectureIds: string[],
  cityIds: string[] | undefined,
  start: string,
  end: string,
  granularity: (typeof GRANULARITY)[keyof typeof GRANULARITY]
): Promise<AxiosResponse<ComparisonTrendAreaAnalysisResponse>> {
  if (!baseChainId) return Promise.reject()
  return axiosClient.get<ComparisonTrendAreaAnalysisResponse>(
    '/chains/chart/trend-area-analysis/chain-comparison/',
    {
      params: {
        baseChainId,
        chainIds,
        prefectureIds,
        cityIds,
        start: convertHyphenDelimiter(start),
        end: convertHyphenDelimiter(end),
        granularity
      },
      cancelPreviousRequests: true,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
  )
}

/**
 * チェーン比較の商圏ランキングDL用URLを取得
 * @param baseChainId 基準チェーンID
 * @param chainIds チェーンIDリスト(基準チェーンを除く)
 * @param prefectureIds 都道府県IDリスト
 * @param cityIds 市区町村IDリスト
 * @param start 期間開始日(yyyy-MM-dd)
 * @param end 期間終了日(yyyy-MM-dd)
 * @param granularity 表示粒度(0: 市区町村, 1: 町丁目, 2: 都道府県)
 */
export function downloadBizAreaComparisonTable(
  baseChainId: string | undefined,
  chainIds: string[],
  prefectureIds: string[],
  cityIds: string[] | undefined,
  start: string,
  end: string,
  granularity: (typeof GRANULARITY)[keyof typeof GRANULARITY]
): Promise<AxiosResponse<DownloadResponse>> {
  return axiosClient.get<DownloadResponse>(
    '/chains/chart/trend-area-analysis/chain-comparison/download/',
    {
      params: {
        baseChainId,
        chainIds,
        prefectureIds,
        cityIds,
        start: convertHyphenDelimiter(start),
        end: convertHyphenDelimiter(end),
        granularity
      },
      cancelPreviousRequests: true,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
  )
}
