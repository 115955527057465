<template>
  <v-table>
    <thead>
      <tr>
        <th class="text-center head-text">ジャンル</th>
        <th class="text-center head-text">偏差値＜高＞</th>
        <th class="text-center head-text">偏差値＜低＞</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="category">
          <img src='@/assets/svg/Trend/utensils-solid.svg' width="15" height="15" />
          {{ gourment.firstCategoryName }}
        </td>
        <td>
          <div v-if="gourment.highDeviationCategoryNames.length === 0">ー</div>
          <div v-else class="dna-summary-chip-grid">
            <v-chip
              v-for="highDeviationCategoryName in gourment.highDeviationCategoryNames"
              :key="highDeviationCategoryName"
              color="#DE5A69"
              tonal
            >
              {{ highDeviationCategoryName }}
            </v-chip>
          </div>
        </td>
        <td>
          <div v-if="gourment.lowDeviationCategoryNames.length === 0">ー</div>
          <div v-else class="dna-summary-chip-grid">
            <v-chip
              v-for="lowDeviationCategoryName in gourment.lowDeviationCategoryNames"
              :key="lowDeviationCategoryName"
              color="#4D99D0"
              tonal
            >
              {{ lowDeviationCategoryName }}
            </v-chip>
          </div>
        </td>
      </tr>
      <tr>
        <td class="category">
          <img src='@/assets/svg/Trend/shirt-solid.svg' width="15" height="15" />
          {{ fashion.firstCategoryName }}
        </td>
        <td>
          <div v-if="fashion.highDeviationCategoryNames.length === 0">ー</div>
          <div v-else class="dna-summary-chip-grid">
            <v-chip
              v-for="highDeviationCategoryName in fashion.highDeviationCategoryNames"
              :key="highDeviationCategoryName"
              color="#DE5A69"
              tonal
            >
              {{ highDeviationCategoryName }}
            </v-chip>
          </div>
        </td>
        <td>
          <div v-if="fashion.lowDeviationCategoryNames.length === 0">ー</div>
          <div v-else class="dna-summary-chip-grid">
            <v-chip
              v-for="lowDeviationCategoryName in fashion.lowDeviationCategoryNames"
              :key="lowDeviationCategoryName"
              color="#4D99D0"
              tonal
            >
              {{ lowDeviationCategoryName }}
            </v-chip>
          </div>
        </td>
      </tr>
      <tr>
        <td class="category">
          <img src='@/assets/svg/Trend/bicycle-solid.svg' width="15" height="15" />
          {{ leisure.firstCategoryName }}
        </td>
        <td>
          <div v-if="leisure.highDeviationCategoryNames.length === 0">ー</div>
          <div v-else class="dna-summary-chip-grid">
            <v-chip
              v-for="highDeviationCategoryName in leisure.highDeviationCategoryNames"
              :key="highDeviationCategoryName"
              color="#DE5A69"
              tonal
            >
              {{ highDeviationCategoryName }}
            </v-chip>
          </div>
        </td>
        <td>
          <div v-if="leisure.lowDeviationCategoryNames.length === 0">ー</div>
          <div v-else class="dna-summary-chip-grid">
            <v-chip
              v-for="lowDeviationCategoryName in leisure.lowDeviationCategoryNames"
              :key="lowDeviationCategoryName"
              color="#4D99D0"
              tonal
            >
              {{ lowDeviationCategoryName }}
            </v-chip>
          </div>
        </td>
      </tr>
      <tr>
        <td class="category">
          <img src='@/assets/svg/Trend/school-flag-solid.svg' width="15" height="15" />
          {{ lifeService.firstCategoryName }}
        </td>
        <td>
          <div v-if="lifeService.highDeviationCategoryNames.length === 0">ー</div>
          <div v-else class="dna-summary-chip-grid">
            <v-chip
              v-for="highDeviationCategoryName in lifeService.highDeviationCategoryNames"
              :key="highDeviationCategoryName"
              color="#DE5A69"
              tonal
            >
              {{ highDeviationCategoryName }}
            </v-chip>
          </div>
        </td>
        <td>
          <div v-if="lifeService.lowDeviationCategoryNames.length === 0">ー</div>
          <div v-else class="dna-summary-chip-grid">
            <v-chip
              v-for="lowDeviationCategoryName in lifeService.lowDeviationCategoryNames"
              :key="lowDeviationCategoryName"
              color="#4D99D0"
              tonal
            >
              {{ lowDeviationCategoryName }}
            </v-chip>
          </div>
        </td>
      </tr>
    </tbody>
  </v-table>
</template>

<script lang="ts">
const defaultGourmentSummary = {
  firstCategoryName: 'グルメ',
  highDeviationCategoryNames: [],
  lowDeviationCategoryNames: []
}
const defaultShoppingSummary = {
  firstCategoryName: 'ショッピング',
  highDeviationCategoryNames: [],
  lowDeviationCategoryNames: []
}
const defaultLeisureSummary = {
  firstCategoryName: 'レジャー',
  highDeviationCategoryNames: [],
  lowDeviationCategoryNames: []
}
const defaultLifeserviceSummary = {
  firstCategoryName: '生活サービス',
  highDeviationCategoryNames: [],
  lowDeviationCategoryNames: []
}
</script>
<script setup lang="ts">
import { BehavioralDnaSumarry, BehavioralDnaSumarryValue } from '@/features/Trend/TrendStore/interfaces'
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    summary: BehavioralDnaSumarry
  }>(), {
    summary: () => {
      return {
        categories: [
          defaultGourmentSummary,
          defaultShoppingSummary,
          defaultLeisureSummary,
          defaultLifeserviceSummary,
        ]
      }
    }
  }
)

const gourment = computed<BehavioralDnaSumarryValue>(() => {
  const summary = props.summary.categories.find(
    (category: BehavioralDnaSumarryValue) => category.firstCategoryName === 'グルメ'
  )
  if (summary) return summary
  else return defaultGourmentSummary
})
const fashion = computed<BehavioralDnaSumarryValue>(() => {
  const summary = props.summary.categories.find(
    (category: BehavioralDnaSumarryValue) => category.firstCategoryName === 'ショッピング'
  )
  if (summary) return summary
  else return defaultShoppingSummary
})
const leisure = computed<BehavioralDnaSumarryValue>(() => {
  const summary = props.summary.categories.find(
    (category: BehavioralDnaSumarryValue) => category.firstCategoryName === 'レジャー'
  )
  if (summary) return summary
  else return defaultLeisureSummary
})
const lifeService = computed<BehavioralDnaSumarryValue>(() => {
  const summary = props.summary.categories.find(
    (category: BehavioralDnaSumarryValue) => category.firstCategoryName === '生活サービス'
  )
  if (summary) return summary
  else return defaultLifeserviceSummary
})
</script>

<style scoped lang="scss">
.v-table :deep(table) {
  border-collapse: collapse !important;
  border: 1px solid #ccc !important;
}
.v-table :deep(td) {
  border-collapse: collapse !important;
  border: 1px solid #ccc !important;
}
.v-table :deep(th) {
  border-collapse: collapse !important;
  border: 1px solid #ccc !important;
  user-select: text !important;
}
th {
  background: #F5F5F5 0% 0% no-repeat padding-box;
  
  .head-text {
    font: normal normal bold 12px/50px Noto Sans JP;
  }
}
.category {
  background: #F4FBFF 0% 0% no-repeat padding-box;
  font: normal normal normal 13px/50px Noto Sans JP;
  
  img {
    vertical-align: middle;
  }
}
.dna-summary-chip-grid {
  display: flex;
  justify-content: center;
  gap: 5px;
}
</style>
