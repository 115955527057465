<template>
  <v-dialog v-model="open" class="dialog">
    <section>
      <div class="dialog-contents">
        <header class="dialog-head">
          <div class="dialog-head-left-content">
            <h1 class="dialog-head-title">地図からエリアを選択</h1>
            <span v-if="type == TREND_TYPE.STORE" class="dialog-head-caption"> （市区町村単位でも分析可） </span>
            <span v-else class="dialog-head-caption"> （都道府県・市区町村単位でも分析可） </span>
          </div>
          <button
            v-if="currentGranularity === GRANULARITY.MUNICIPALITIES"
            class="go-previous-button"
            @click="() => {
              currentGranularity = GRANULARITY.PREFECTURE
              isCityOrTownSelectView = false
            }
            "
          >
            都道府県選択に戻る
          </button>
          <button
            v-if="currentGranularity === GRANULARITY.TOWN_AND_AREA"
            class="go-previous-button"
            @click="currentGranularity = GRANULARITY.MUNICIPALITIES"
          >
            市区町村選択に戻る
          </button>
        </header>
        <div class="dialog-map">
          <div class="map-selector-card">
            <div class="map">
              <AreaSelectorMap
                :is-loading="isLoading"
                :granularity="currentGranularity"
                :geo-json="geoJson"
                :selected-prefecture-id="selectedPrefectureId"
                :selected-city-ids="selectedCityIds"
                :selected-town-ids="selectedTownIds"
                :information-text="hoveredAreaName"
                :on-area-select="handleAreaSelect"
                :on-area-hover="handleAreaHover"
                :on-center-change="handleCenterChange"
              />
            </div>
            <div class="form">
              <form class="map-selector-form">
                <div class="map-selector-form-contents">
                  <div class="map-selector-form-granularity">
                    <div class="map-selector-form-title">1. 都道府県を選択</div>
                    <div class="map-selector-form-content">
                      <CustomSelector
                        v-if="currentGranularity === GRANULARITY.PREFECTURE"
                        v-model="selectedPrefecture"
                        :items="prefectureItems"
                        placeholder="都道府県を選択"
                        size="sm"
                        width="100%"
                      />
                      <div v-else class="chips">
                        <SelectedChip :name="selectedPrefecture?.text ?? ''" />
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="currentGranularity !== GRANULARITY.PREFECTURE"
                    class="map-selector-form-granularity"
                  >
                    <div class="map-selector-form-title">
                      2. 市区町村を選択
                      <span v-if="selectedCityIds.length > 0" class="area-count">
                        {{ selectedCityIds.length }}エリア
                      </span>
                    </div>
                    <div
                      class="map-selector-form-content"
                      :class="{
                        'city-overflow-scroll-on-city':
                          currentGranularity === GRANULARITY.MUNICIPALITIES,
                        'city-overflow-scroll-on-town':
                          currentGranularity === GRANULARITY.TOWN_AND_AREA
                      }"
                    >
                      <div
                        v-if="
                          !selectedCityIds.length &&
                          currentGranularity === GRANULARITY.MUNICIPALITIES
                        "
                        class="no-chips"
                      >
                        地図から選択してください
                      </div>
                      <div class="chips">
                        <SelectedChip
                          v-for="id in selectedCityIds"
                          :key="id"
                          :name="cityMap[id].name"
                          :on-delete="
                            currentGranularity === GRANULARITY.MUNICIPALITIES
                              ? () => handleDeleteCityId(id)
                              : undefined
                          "
                        />
                      </div>
                    </div>
                    <div
                      v-if="currentGranularity === GRANULARITY.MUNICIPALITIES"
                      class="map-selector-form-clear"
                    >
                      <button type="button" class="clear-button" @click="handleClearCityIds">
                        全ての選択を解除
                      </button>
                    </div>
                  </div>
                  <div
                    v-if="currentGranularity === GRANULARITY.TOWN_AND_AREA"
                    class="map-selector-form-granularity"
                  >
                    <div class="map-selector-form-title">
                      3. 町丁目を選択
                      <span v-if="selectedTownIds.length > 0" class="area-count">
                        {{ selectedTownIds.length }}エリア
                      </span>
                    </div>
                    <div class="map-selector-form-content town-overflow-scroll">
                      <div
                        v-if="
                          !selectedTownIds.length &&
                          currentGranularity === GRANULARITY.TOWN_AND_AREA
                        "
                        class="no-chips"
                      >
                        地図から選択してください
                      </div>
                      <div v-else>
                        <div v-for="cityId in selectedTownCityIds" :key="cityId">
                          <div class="selected-city">●{{ cityMap[cityId].name }}</div>
                          <div class="selected-town">
                            <span
                              v-for="(id, index) in selectedTownIds.filter(
                                (id) => townMap.get(id)?.cityId === cityId
                              )"
                              :key="id"
                            >
                              {{ townMap.get(id)?.name }} [<button
                                type="button"
                                class="cross-button"
                                @click="() => handleDeleteTownId(id)"
                              >
                                ×</button
                              >]
                              <span
                                v-if="
                                  index !==
                                  selectedTownIds.filter((id) => townMap.get(id)?.cityId === cityId)
                                    .length -
                                    1
                                "
                                >,
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="currentGranularity === GRANULARITY.TOWN_AND_AREA"
                      class="map-selector-form-clear"
                    >
                      <button type="button" class="clear-button" @click="handleClearTownIds">
                        全ての選択を解除
                      </button>
                    </div>
                  </div>
                </div>
                <div class="map-selector-actions">
                  <CustomButton
                    v-if="type === TREND_TYPE.STORE && isCityOrTownSelectView"
                    type="button"
                    width="100%"
                    height="44px"
                    :disabled="selectedCityIds.length === 0"
                    @click="handleAreaConfirm"
                  >
                    エリアを決定
                  </CustomButton>
                  <CustomButton
                    v-if="type === TREND_TYPE.CHAIN"
                    type="button"
                    width="100%"
                    height="44px"
                    :disabled="!selectedPrefecture"
                    @click="handleAreaConfirm"
                  >
                    エリアを決定
                  </CustomButton>
                  <CustomButton
                    v-if="currentGranularity === GRANULARITY.PREFECTURE"
                    width="100%"
                    height="44px"
                    :disabled="!selectedPrefecture"
                    @click="handleNextClick"
                  >
                    続けて市区町村を選択
                  </CustomButton>
                  <CustomButton
                    v-if="currentGranularity === GRANULARITY.MUNICIPALITIES"
                    width="100%"
                    height="44px"
                    :disabled="!selectedCityIds.length"
                    @click="handleNextClick"
                  >
                    続けて町丁目を選択
                  </CustomButton>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </v-dialog>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { City, Prefecture } from '@/features/Dashboard/types'
import { GRANULARITY } from '@/commons/enums'
import { Feature, GeoJSON } from '@/commons/types/GeoJSON'
import { Point } from '@/commons/types/Mapbox'
import {
  filterGeoJsonById,
  getFeatureMap,
  getIdsByDist,
  getTotalBBox
} from '@/commons/utils/geojson/filter-geojson'
import { getPrefectureGeo } from '@/commons/axios/prefectures'
import { csvToGeoJson } from '@/commons/utils/geojson/csv-to-geojson'
import { getCitiesGeo } from '@/commons/axios/cities'
import { getTowns, getTownsGeo } from '@/commons/axios/towns'
import { watch } from 'vue'
import { Town, TownResponse } from '@/commons/interfaces'
import CustomSelector, { Item } from '@/commons/components/Elements/CustomSelector.vue'
import SelectedChip from './SelectedChip.vue'
import AreaSelectorMap from './AreaSelectorMap.vue'
import { TREND_TYPE } from '../enums'

/* --------------------------------------------------------------------------
  v-model
 ---------------------------------------------------------------------------*/

const open = defineModel<boolean>()

/* --------------------------------------------------------------------------
  props
 ---------------------------------------------------------------------------*/

const props = withDefaults(
  defineProps<{
    /** 選択済みの都道府県の初期値 */
    initialPrefectureId?: number
    /** 選択済みの市区町村の初期値 */
    initialCityIds?: string[]
    /** 選択済みの町丁目の初期値 */
    initialTownIds?: string[]
    /** エリア決定時のイベントハンドラ */
    onAreaConfirm?: ({
      prefectureId,
      cityIds,
      townIds
    }: {
      prefectureId: number
      cityIds?: string[]
      townIds?: string[]
    }) => void
    type: (typeof TREND_TYPE)[keyof typeof TREND_TYPE]
  }>(),
  {
    initialPrefectureId: undefined,
    initialCityIds: (): string[] => [],
    initialTownIds: (): string[] => [],
    onAreaConfirm: () => undefined,
    type: TREND_TYPE.STORE
  }
)

/* --------------------------------------------------------------------------
  Vuex
 ---------------------------------------------------------------------------*/

const store = useStore()
const prefectures = computed<Prefecture[]>(() => store.state.prefectures)
const cities = computed<City[]>(() => store.state.cities)
const prefecturesGeo = computed<GeoJSON | undefined>(() => store.state.prefecturesGeo)
const citiesGeo = computed<GeoJSON | undefined>(() => store.state.citiesGeo)
const townsGeo = computed<GeoJSON | undefined>(() => store.state.townsGeo)
const prefectureMap = computed<Record<number, Prefecture>>(() => store.getters.prefectureMap)
const cityMap = computed<Record<string, City>>(() => store.getters.cityMap)

/* --------------------------------------------------------------------------
  core logic
 ---------------------------------------------------------------------------*/

// ローディング
const isLoading = ref<boolean>(false)

// フォームで現在選択している表示単位（都道府県、市区町村、町丁目）
const currentGranularity = ref<(typeof GRANULARITY)[keyof typeof GRANULARITY]>(
  GRANULARITY.PREFECTURE
)

// 選択されている都道府県
const selectedPrefectureId = ref<number | undefined>(props.initialPrefectureId)

// 選択されている市区町村IDs
const selectedCityIds = ref<string[]>(props.initialCityIds)

// 市区町村・町丁目選択時の画面であるかどうかのフラグ
const isCityOrTownSelectView = ref<boolean>(false)

// 選択されている町丁目IDs
const selectedTownIds = ref<string[]>(props.initialTownIds)

// 町丁目ローディング
const townsLoading = ref<boolean>(false)

// 町丁目一覧のレスポンス
const townsRes = ref<TownResponse | undefined>()

// 町丁目検索用マップオブジェクト
const townMap = computed<Map<string, Town>>(() => {
  const map = new Map<string, Town>()
  townsRes.value?.towns.forEach((town) => map.set(town.townId, town))
  return map
})

// (町丁目) 40km 制限する元となる GeoJSON
const townGeoJson = ref<GeoJSON | undefined>()

// マップに表示する GeoJSON
const geoJson = ref<GeoJSON | undefined>()

// feature 検索用の Map
const featureMap = ref<Map<string, Feature> | undefined>()

// 中心座標
const center = ref<Point>({ lat: 0, lng: 0 })

// ホバー対象のID（都道府県・市区町村・町丁目）
const hoveredId = ref<string | undefined>()

// ホバー対象のIDから表示する住所
const hoveredAreaName = computed<string | undefined>(() => {
  if (!hoveredId.value) return undefined

  if (currentGranularity.value === GRANULARITY.PREFECTURE) {
    const prefecture = prefectureMap.value[Number(hoveredId.value)]
    return prefecture ? prefecture.name : undefined
  }

  if (currentGranularity.value === GRANULARITY.MUNICIPALITIES) {
    const city = cityMap.value[hoveredId.value]
    return city ? prefectureMap.value[city.prefectureId]['name'] + city.name : undefined
  }

  if (currentGranularity.value === GRANULARITY.TOWN_AND_AREA) {
    if (!townMap.value || !selectedPrefectureId.value) return

    const town = townMap.value.get(hoveredId.value)
    return town
      ? prefectureMap.value[selectedPrefectureId.value]['name'] +
          cityMap.value[town.cityId]['name'] +
          townMap.value.get(town.townId)?.name
      : undefined
  }

  return undefined
})

// エリアホバー時におけるコールバック関数（ID は都道府県・市区町村・町丁目）
const handleAreaHover = (id: string | undefined) => {
  hoveredId.value = id
}

// 町丁目の取得
const fetchTowns = async () => {
  if (townsLoading.value) return
  try {
    townsLoading.value = true
    const res = await getTowns(selectedCityIds.value)
    townsRes.value = res.data
  } finally {
    townsLoading.value = false
  }
}

// エリア選択におけるイベント関数
const handleAreaSelect = (id: string) => {
  if (currentGranularity.value === GRANULARITY.MUNICIPALITIES) {
    if (selectedCityIds.value.includes(id)) {
      selectedCityIds.value = selectedCityIds.value.filter((selectedId) => selectedId !== id)
    } else {
      selectedCityIds.value = [...selectedCityIds.value, id]
    }

    if (!geoJson.value || !selectedCityIds.value.length) return

    const filteredFeatures = geoJson.value.features.filter((feature) => {
      return selectedCityIds.value[0] === feature.properties.id
    })

    const bbox = getTotalBBox(filteredFeatures)
    store.commit('setTargetTownMapBounds', {
      point: {
        southWestLat: bbox[1],
        southWestLan: bbox[0],
        northEastLat: bbox[3],
        northEastLan: bbox[2],
      }
    })
  }

  if (currentGranularity.value === GRANULARITY.TOWN_AND_AREA) {
    if (selectedTownIds.value.includes(id)) {
      selectedTownIds.value = selectedTownIds.value.filter((selectedId) => selectedId !== id)
    } else {
      selectedTownIds.value = [...selectedTownIds.value, id]
    }
  }
}

// 「続けて XX を選択」のイベント関数
const handleNextClick = () => {
  shouldResetAreaConfirmFlag()
  if (currentGranularity.value === GRANULARITY.PREFECTURE) {
    currentGranularity.value = GRANULARITY.MUNICIPALITIES
  } else if (currentGranularity.value === GRANULARITY.MUNICIPALITIES) {
    currentGranularity.value = GRANULARITY.TOWN_AND_AREA
  }
}

// (町丁目のみ)中心座標の変更に応じて GeoJson の更新を行う
const handleCenterChange = (newCenter: Point) => {
  center.value = newCenter

  if (currentGranularity.value === GRANULARITY.TOWN_AND_AREA && townGeoJson.value) {
    if (!featureMap.value) {
      featureMap.value = getFeatureMap(townGeoJson.value.features)
    }

    const filteredIds = getIdsByDist(townGeoJson.value, [newCenter.lng, newCenter.lat], 20)
    geoJson.value = filterGeoJsonById(townGeoJson.value, filteredIds, featureMap.value)
  }
}

// 都道府県の GeoJSON 取得コールバック関数
const updateGeoJsonByPrefecture = async () => {
  if (!selectedPrefectureId.value || isLoading.value) return
  try {
    isLoading.value = true

    const { data } = await getPrefectureGeo(selectedPrefectureId.value.toString())
    const geo = await csvToGeoJson(data.url)
    store.commit('setPrefecturesGeo', { geo })
    store.commit('setCitiesGeo', { geo: undefined })
    store.commit('setTownsGeo', { geo: undefined })
    featureMap.value = undefined
    townGeoJson.value = undefined
    geoJson.value = geo
  } finally {
    isLoading.value = false
  }
}

// 市区町村の GeoJSON 取得コールバック関数
const updateGeoJsonByCity = async () => {
  if (!selectedPrefectureId.value || isLoading.value) return
  try {
    isLoading.value = true

    const { data: citiesGeoData } = await getCitiesGeo(selectedPrefectureId.value.toString())
    const geo = await csvToGeoJson(citiesGeoData.url)
    store.commit('setCitiesGeo', { geo })
    geoJson.value = geo
  } finally {
    isLoading.value = false
  }
}

// 町丁目の GeoJSON 取得コールバック関数
const updateGeoJsonByTown = async () => {
  if (!selectedPrefectureId.value || isLoading.value) return
  try {
    isLoading.value = true
    const { data: townsGeoData } = await getTownsGeo(selectedPrefectureId.value.toString())
    const allTownGeoJson = await csvToGeoJson(townsGeoData.url)
    store.commit('setTownsGeo', { geo: allTownGeoJson })

    const townsRes = await getTowns(selectedCityIds.value)
    const townIds = townsRes.data.towns.map(({ townId }) => townId)

    if (!featureMap.value) {
      featureMap.value = getFeatureMap(allTownGeoJson.features)
    }

    townGeoJson.value = filterGeoJsonById(allTownGeoJson, townIds, featureMap.value)

    const filteredIds = getIdsByDist(townGeoJson.value, [center.value.lng, center.value.lat], 20)
    geoJson.value = filterGeoJsonById(townGeoJson.value, filteredIds, featureMap.value)
  } finally {
    isLoading.value = false
  }
}

// 表示単位（都道府県、市区町村、町丁目）に応じた GeoJSON の取得
const updateGeoJsonByGranularity = async () => {
  if (!selectedPrefectureId.value) return

  geoJson.value = undefined

  if (currentGranularity.value === GRANULARITY.PREFECTURE) {
    if (!prefecturesGeo.value) {
      await updateGeoJsonByPrefecture()
    } else {
      geoJson.value = prefecturesGeo.value
    }
  }

  if (currentGranularity.value === GRANULARITY.MUNICIPALITIES) {
    if (!citiesGeo.value) {
      await updateGeoJsonByCity()
    } else {
      geoJson.value = citiesGeo.value
    }
  }

  if (currentGranularity.value === GRANULARITY.TOWN_AND_AREA) {
    if (!townsGeo.value) {
      await updateGeoJsonByTown()
    } else {
      if (!featureMap.value) {
        featureMap.value = getFeatureMap(townsGeo.value.features)
      }
      const townsRes = await getTowns(selectedCityIds.value)
      const townIds = townsRes.data.towns.map(({ townId }) => townId)

      townGeoJson.value = filterGeoJsonById(townsGeo.value, townIds, featureMap.value)
      const filteredIds = getIdsByDist(townsGeo.value, [center.value.lng, center.value.lat], 20)
      geoJson.value = filterGeoJsonById(townsGeo.value, filteredIds, featureMap.value)
    }

    await fetchTowns()
  }
}

const prefectureItems = computed<Item[]>(() =>
  prefectures.value.map(({ prefectureId, name }) => ({ text: name, value: prefectureId }))
)
const selectedPrefecture = ref<Item | undefined>(
  prefectureItems.value.find((item) => item.value === props.initialPrefectureId)
)

// 選択された町丁目の親である市区町村 ID 配列
const selectedTownCityIds = computed<string[]>(() => {
  return Array.from(
    new Set(selectedTownIds.value.map((id) => townMap.value.get(id)?.cityId).filter((id) => !!id))
  ) as string[]
})

const handleDeleteCityId = (id: string) => {
  selectedCityIds.value = selectedCityIds.value.filter((selectedId) => id !== selectedId)
}

const handleClearCityIds = () => {
  selectedCityIds.value = []
}

const handleDeleteTownId = (id: string) => {
  selectedTownIds.value = selectedTownIds.value.filter((selectedId) => id !== selectedId)
}

const handleClearTownIds = () => {
  selectedTownIds.value = []
}

const handleAreaConfirm = () => {
  if (!selectedPrefecture.value) return
  shouldResetAreaConfirmFlag()
  props.onAreaConfirm({
    prefectureId: Number(selectedPrefecture.value.value),
    cityIds: selectedCityIds.value,
    townIds: selectedTownIds.value
  })
}

const shouldResetAreaConfirmFlag = () => {
  if (props.type === TREND_TYPE.STORE && selectedPrefecture.value) {
    isCityOrTownSelectView.value = true
  }
}

// 都道府県の選択に応じて GeoJSON の取得を行う
watch(selectedPrefectureId, () => {
  updateGeoJsonByPrefecture()
})

// 表示単位（市区町村、町丁目）の変更に応じて GeoJSON の取得を行う
watch(
  () => currentGranularity.value,
  async (newGranularity, oldGranularity) => {
    // 市区町村 → 都道府県 で市区町村の選択状態を解除
    if (
      newGranularity === GRANULARITY.PREFECTURE &&
      oldGranularity === GRANULARITY.MUNICIPALITIES
    ) {
      selectedCityIds.value = []
    }

    // 町丁目 → 市区町村 で町丁目の選択状態を解除
    if (
      newGranularity === GRANULARITY.MUNICIPALITIES &&
      oldGranularity === GRANULARITY.TOWN_AND_AREA
    ) {
      selectedTownIds.value = []
    }

    // GeoJson の更新
    updateGeoJsonByGranularity()
  }
)

// ダイアログを開いた際に GeoJSON の取得を行う
watch(open, () => {
  if (open.value) {
    updateGeoJsonByGranularity()
  }
})

// 都道府県のセレクターで選択した都道府県に応じて選択済み都道府県IDを更新
watch(
  selectedPrefecture,
  () => {
    if (selectedPrefecture.value) {
      selectedPrefectureId.value = Number(selectedPrefecture.value.value)
    }
  },
  { deep: true }
)

/* --------------------------------------------------------------------------
  created
 ---------------------------------------------------------------------------*/

const handleCreated = async () => {
  // 都道府県が未取得の場合は都道府県を取得
  if (!prefectures.value.length) await store.dispatch('fetchPrefectures')
  // 市区町村が未取得の場合は都道府県を取得
  if (!cities.value.length) await store.dispatch('fetchCities')
  // 町丁目が未取得の場合は取得
  if (selectedCityIds.value.length > 0) await fetchTowns()
}

handleCreated()
</script>

<style scoped>
.dialog {
  width: 95%;
}

.dialog-contents {
  display: flex;
  flex-direction: column;
  gap: 18px;
  height: 90vh;
  max-height: 840px;
  padding: 30px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
}

.dialog-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dialog-head-left-content {
  display: flex;
  align-items: center;
}

.go-previous-button {
  text-align: right;
  font: normal normal normal 15px/28px Noto Sans JP;
  letter-spacing: 0px;
  color: #4d99d0;
  opacity: 1;
}

.dialog-head-title {
  text-align: left;
  font: normal normal bold 15px/28px Noto Sans JP;
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;
}

.dialog-head-caption {
  text-align: left;
  font: normal normal normal 15px/40px Noto Sans JP;
  letter-spacing: 0px;
  color: #666666;
}

.dialog-map {
  height: 100%;
}

.map-selector-card {
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 730px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
}

.map {
  display: flex;
  flex: 1 1 0%;
  background-color: #ece1cb;
}

.form {
  display: flex;
  flex: none;
  width: 260px;
}

.map-selector-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.map-selector-form-contents {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 592px;
}

.map-selector-form-granularity {
  display: flex;
  flex-direction: column;
}

.map-selector-form-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  padding: 0 20px;
  height: 46px;
  width: 100%;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  text-align: left;
  font: normal normal bold 14px/40px Noto Sans JP;
  letter-spacing: 0px;
  color: #222222;
}

.area-count {
  font: normal normal normal 14px/26px Noto Sans JP;
  letter-spacing: 0px;
  color: #999999;
  opacity: 1;
}

.map-selector-form-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.map-selector-form-clear {
  display: flex;
  align-items: center;
  margin-bottom: -15px;
  padding: 0 20px;
}

.map-selector-label {
  text-align: left;
  font: normal normal bold 13px/26px Noto Sans JP;
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;
}

.map-selector-area {
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  padding: 20px;
  height: 100%;
  overflow-y: auto;
}

.map-selector-actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
}

.no-chips {
  display: flex;
  font: normal normal normal 13px/19px Noto Sans JP;
  letter-spacing: 0px;
  color: #999999;
  opacity: 1;
}

.chips {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.city-overflow-scroll-on-city {
  height: 35vh;
  max-height: 380px;
  overflow-y: auto;
}

.city-overflow-scroll-on-town {
  height: 100%;
  max-height: 75px;
  overflow-y: auto;
}

.town-overflow-scroll {
  height: 100%;
  max-height: 230px;
  overflow-y: auto;
}

.selected-city {
  text-align: left;
  font: normal normal bold 13px/26px Noto Sans JP;
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;
}

.selected-town {
  text-align: left;
  font: normal normal normal 12px/24px Noto Sans JP;
  letter-spacing: 0px;
  opacity: 1;
}

.cross-button {
  text-align: left;
  font: normal normal normal 12px/24px Noto Sans JP;
  letter-spacing: 0px;
  color: #4d99d0;
}

.clear-button {
  display: flex inline;
  text-align: left;
  font: normal normal normal 13px/19px Noto Sans JP;
  letter-spacing: 0px;
  color: #be0000;
  opacity: 1;
}

.ml-5px {
  margin-left: 5px;
}

.ml-15px {
  margin-left: 15px;
}

.ml-30px {
  margin-left: 30px;
}
</style>
