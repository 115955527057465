import { axiosClient } from '@/plugins/axios'
import { AxiosResponse } from 'axios'
import { convertHyphenDelimiter } from '@/commons/utils/dateUtil'
import {
  BizAreaAnalysisResponse,
  BizAreaAnalysisMapResponse,
  DistanceRankingResponse
} from '@/features/ShopAnalytics/interfaces/response'
import { DownloadResponse } from '@/commons/interfaces/responses/download'
import { GRANULARITY } from '@/commons/enums'
import { BBox } from '@/commons/types/GeoJSON'

/**
 * 商圏ランキングチャート取得
 * @param {string} chainId チェーンID
 * @param {string[]} prefectureIds 都道府県IDs
 * @param {string[]} cityIds 市区町村IDs
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 * @param {number} granularity 表示粒度(0: 市区町村, 1: 町丁目, 2: 都道府県)
 */
export function getBizAreaAnalysisChart(
  chainId: string | undefined,
  prefectureIds: string[] | undefined,
  start: string,
  end: string,
  granularity: (typeof GRANULARITY)[keyof typeof GRANULARITY],
  cityIds?: string[] | undefined
): Promise<AxiosResponse<BizAreaAnalysisResponse>> {
  if (!chainId) return Promise.reject()
  return axiosClient.get<BizAreaAnalysisResponse>('/chains/chart/trend-area-analysis/', {
    params: {
      chainId,
      prefectureIds,
      start: convertHyphenDelimiter(start),
      end: convertHyphenDelimiter(end),
      granularity: granularity,
      cityIds
    }
  })
}

type GetBizAreaComparisonMapParams = {
  chainId: string
  prefectureIds: number[]
  cityIds?: string[]
  start: string
  end: string
  mapOptions: {
    granularity: (typeof GRANULARITY)[keyof typeof GRANULARITY]
  }
}

/**
 * 商圏マップ用ポリゴンデータ取得
 * @param {string} chainId チェーンID
 * @param {string[]} prefectureIds 都道府県IDs
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 * @param {Object} mapOptions 地図の補足情報
 * @param {number} mapOptions.granularity 表示粒度(0: 市区町村, 1: 町丁目, 2: 都道府県)
 * @param {number} mapOptions.latitude 地図上で表示されている中心点の緯度
 * @param {number} mapOptions.longitude 地図上で表示されている中心点の経度
 */
export function getBizAreaAnalysisMap({
  chainId,
  prefectureIds,
  cityIds,
  start,
  end,
  mapOptions
}: GetBizAreaComparisonMapParams): Promise<AxiosResponse<BizAreaAnalysisMapResponse>> {
  if (!chainId) return Promise.reject()
  return axiosClient.post<BizAreaAnalysisMapResponse>('/chains/chart/trend-area-analysis/geo/', {
    chainId,
    prefectureIds,
    cityIds,
    start: convertHyphenDelimiter(start),
    end: convertHyphenDelimiter(end),
    mapOptions: mapOptions
  })
}

/**
 * 来店人数の距離別カバー率取得
 * @param {string} chainId チェーンID
 * @param {string[]} prefectureIds 都道府県IDs
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 */
export function getDistanceRankingChart(
  chainId: string | undefined,
  prefectureIds: string[] | undefined,
  start: string,
  end: string
): Promise<AxiosResponse<DistanceRankingResponse>> {
  if (!chainId) return Promise.reject()
  return axiosClient.get<DistanceRankingResponse>(
    '/chains/chart/trend-area-analysis/distance-ranking/',
    {
      params: {
        chainId,
        prefectureIds,
        start: convertHyphenDelimiter(start),
        end: convertHyphenDelimiter(end)
      }
    }
  )
}

/**
 * 来店人数の距離別カバー率DL用URL生成
 * @param {string} chainId チェーンID
 * @param {string[]} prefectureIds 都道府県IDs
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 */
export function downloadBizAreaAnalysisDistanceRankingChart(
  chainId: string | undefined,
  prefectureIds: string[] | undefined,
  start: string,
  end: string
): Promise<AxiosResponse<DownloadResponse>> {
  if (!chainId) return Promise.reject()
  return axiosClient.get<DownloadResponse>(
    '/chains/chart/trend-area-analysis/distance-ranking/download/',
    {
      params: {
        chainId,
        prefectureIds,
        start: convertHyphenDelimiter(start),
        end: convertHyphenDelimiter(end)
      }
    }
  )
}

/**
 * 商圏ランキングDL用URL生成
 * @param {string} chainId チェーンID
 * @param {string[]} prefectureIds 都道府県IDs
 * @param {string[]} cityIds 市区町村IDs
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 * @param {number} granularity 表示粒度(0: 市区町村, 1: 町丁目, 2: 都道府県)
 */
export function downloadBizAreaAnalysisChart(
  chainId: string | undefined,
  prefectureIds: string[] | undefined,
  start: string,
  end: string,
  granularity: (typeof GRANULARITY)[keyof typeof GRANULARITY],
  cityIds?: string[] | undefined
): Promise<AxiosResponse<DownloadResponse>> {
  if (!chainId) return Promise.reject()
  return axiosClient.get<DownloadResponse>('/chains/chart/trend-area-analysis/download/', {
    params: {
      chainId,
      prefectureIds,
      start: convertHyphenDelimiter(start),
      end: convertHyphenDelimiter(end),
      granularity: granularity,
      cityIds
    }
  })
}
