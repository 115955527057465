<template>
  <v-container class="pa-0" fluid>
    <v-row class="title-container" dense no-gutters>
      <v-col style="display: flex; align-items: center">
        <h1 class="title-text">来店人数推移</h1>
        <update-badge type="week" style="margin-left: 15px" />
        <v-spacer />
        <DatePicker
          :start-date="$store.state.startDate"
          :end-date="$store.state.endDate"
          :compare-start-date="$store.state.compareStartDate"
          :compare-end-date="$store.state.compareEndDate"
          :enable-comparison="false"
          @update-period="(period) => setPeriodParams(period)"
        />
      </v-col>
    </v-row>
    <v-row dense no-gutters class="card-container">
      <v-col>
        <MultiChainSelector
          :initial-chain-ids="chainIds"
          :initial-prefecture-ids="prefectureIds.map((id) => Number(id))"
          :start-date="$store.state.startDate"
          :end-date="$store.state.endDate"
          :handle-update="
            (chains, prefectureIds) =>
              setParams({
                chainIds: chains.map((chain) => chain.id),
                prefectureIds: prefectureIds.map((id) => id.toString())
              })
          "
        />
      </v-col>
    </v-row>
    <v-row dense no-gutters class="card-container mb-0">
      <v-col>
        <ChartCard
          :start-date="$store.state.startDate"
          :end-date="$store.state.endDate"
          :selected-chain-ids="chainIds"
          :selected-prefecture-ids="prefectureIds.map((id) => Number(id))"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import MultiChainSelector from '@/commons/components/ChainSelector/MultiChainSelector.vue'
import UpdateBadge from '@/commons/components/Elements/UpdateBadge.vue'
import DatePicker from '@/features/ShopAnalytics/components/Common/datePicker.vue'
import ChartCard from '@/features/ChainCompare/visitor/components/ChartCard.vue'
import useChainsQueryParams from '@/commons/hooks/use-chains-query-params'
import usePeriodQueryParams from '@/commons/hooks/use-period-query-params'
import { useRoute } from 'vue-router'
import { getPeriodByRouterQueryPeriod } from '@/commons/utils'
import { useStore } from 'vuex'

/* --------------------------------------------------------------------------
  選択済みチェーン・都道府県
 ---------------------------------------------------------------------------*/

const { chainIds, prefectureIds, setParams } = useChainsQueryParams()
const { setPeriodParams } = usePeriodQueryParams()

const route = useRoute()
const store = useStore()

if (route.query.period) {
  const period = getPeriodByRouterQueryPeriod(route.query.period as string)
  if (period !== undefined) store.commit('setDate', period)
}
</script>
