import { Chain } from '@/commons/interfaces'
import { Store } from '@/commons/interfaces/responses/store'
import { isEqual } from 'lodash';

type ChainMapValue = Omit<Chain, 'prefectureIds'> & {
  prefectureIds: Set<number>
}

/**
 * 店舗一覧データからチェーンを取得するための関数
 *
 * **Usage**
 *
 * ```ts
 * const chains = getChainsFromStores(stores)
 * ```
 *
 * **Details**
 *
 * この関数は、`Store[]` 型の配列を受け取り、チェーンごとに店舗情報を集約して `Chain[]` 型の配列を生成します。各チェーンの情報には、チェーンのID（`chainName`を利用）、チェーンの名前、該当チェーンに属する店舗の種類（`storeType`）、およびチェーンの店舗が存在する都道府県のIDセット（`prefectureIds`）が含まれます。チェーンごとにどのような店舗があり、それらがどの地域に分布しているかを把握するために役立ちます。
 *
 */
export function getChainsFromStores(stores: Store[]): Chain[] {
  const chainMap = stores.reduce<{ [key: string]: ChainMapValue }>((acc, store) => {
    if (!store.chainId || !store.chainName || !store.chainOrderIndex || !store.openCloseDates.length) return acc

    if (!acc[store.chainId]) {
      acc[store.chainId] = {
        id: store.chainId,
        name: store.chainName,
        storeType: store.storeType,
        stores: [],
        prefectureIds: new Set<number>(),
        orderIndex: store.chainOrderIndex
      }
    }

    acc[store.chainId].stores = [...acc[store.chainId].stores, store]
    acc[store.chainId].prefectureIds.add(store.prefectureId)
    return acc
  }, {})

  return Object.values(chainMap).map((chain) => ({
    ...chain,
    prefectureIds: Array.from(chain.prefectureIds)
  }))
}

/**
 * watchしたpropsの内容が一致しているかの判定をするための関数
 *
 * **Usage**
 *
 * ```ts
 * const isEqualSorted = isEqualSorted(newVal, oldVal)
 * ```
 *
 * **Details**
 *
 * この関数は、2つの配列を受け取り、それらの内容が等しいかどうかを判定します。配列の中に配列が含まれている場合、それらも含めてソートされ、ソート後の結果を比較します。これにより、配列の順序が異なっていても、内容が同じであれば等しいと判定されます。
 *
 * @param newValues - 比較するための新しい値の配列
 * @param oldValues - 比較するための古い値の配列
 * @returns 2つの配列が等しいかどうかを示すブール値
 */
export function isEqualSorted(newValues: any[], oldValues: any[]): Boolean {
  const sortedNewValues = newValues.map(arr => Array.isArray(arr) ? arr.slice().sort() : arr).sort();
  const sortedOldValues = oldValues.map(arr => Array.isArray(arr) ? arr.slice().sort() : arr).sort();
  return isEqual(sortedNewValues, sortedOldValues);
}