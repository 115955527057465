<template>
  <v-card height="'650px'">
    <v-container class="pa-0" fluid>
      <v-row class="d-flex justify-space-between my-0" dense no-gutters>
        <v-col class="d-flex align-center text-left" style="margin-top: 9px; margin-bottom: 30px">
          <v-btn-toggle v-model.number="particle" mandatory :divided="true" density="compact">
            <v-btn
              max-height="35px"
              min-height="35px"
              max-width="35px"
              min-width="35px"
              :value="COMPARE_PARTICLE_TYPE.DATE"
              :border="true"
            >
              <span style="font-size: 12px">日</span>
            </v-btn>
            <v-btn
              max-height="35px"
              min-height="35px"
              max-width="35px"
              min-width="35px"
              :value="COMPARE_PARTICLE_TYPE.WEEK"
              :border="true"
            >
              <span style="font-size: 12px">週</span>
            </v-btn>
            <v-btn
              max-height="35px"
              min-height="35px"
              max-width="35px"
              min-width="35px"
              :value="COMPARE_PARTICLE_TYPE.MONTH"
              :border="true"
            >
              <span style="font-size: 12px">月</span>
            </v-btn>
            <v-btn
              max-height="35px"
              min-height="35px"
              max-width="70px"
              min-width="70px"
              :value="COMPARE_PARTICLE_TYPE.MONTHLY_RATE"
              :border="true"
            >
              <span style="font-size: 12px">月別比率</span>
            </v-btn>
          </v-btn-toggle>
          <AlertTooltip
            v-if="chartAlert"
            class="ml-2"
            text="取得データボリュームが少なく統計上の信頼性が低い日が含まれています。該当日は参考値としてご参照ください。"
          />
          <v-spacer />
          <ChartDescriptionTooltip
            menu-key="chainCompare"
            sub-menu-key="visitors"
            chart-key="visitors"
          />
          <DownloadButton
            label="CSVデータ"
            :disabled="props.selectedChainIds.length === 0"
            :get-file-id="getVisitCountFileId"
            :csv-name="`チェーン比較_来店人数推移_${convertSlashDelimiter(
              start
            )}-${convertSlashDelimiter(end)}`"
            class="download-button"
          />
        </v-col>
      </v-row>
      <v-row class="my-0" dense>
        <v-col class="py-0">
          <div v-show="props.selectedChainIds.length === 0" class="unselected_card">
            チェーンを選択するとチャートが表示されます。
          </div>
          <div v-show="props.selectedChainIds.length > 0">
            <LoadingImg v-if="chartLoading" :height="'580px'" />
            <NoDataChart v-else-if="chartData.length <= 1" />
            <div v-else>
              <ColumnChart
                v-if="particle === COMPARE_PARTICLE_TYPE.MONTHLY_RATE"
                :chart-data="chartData"
                :colors="selectedChainColors"
                :legends="selectedChainsLegends"
                is-stacked="percent"
              />
              <LineChart
                v-else
                :chart-data="chartData"
                :legends="selectedChainsLegends"
                :colors="selectedChainColors"
              />
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script setup lang="ts">
import AlertTooltip from '@/commons/components/Elements/AlertTooltip.vue'
import ChartDescriptionTooltip from '@/commons/components/Elements/ChartDescriptionTooltip.vue'
import DownloadButton from '@/commons/components/Elements/DownloadButton.vue'
import LoadingImg from '@/commons/components/loadingImg.vue'
import ColumnChart from '@/features/ShopAnalytics/components/Common/columnChart.vue'
import NoDataChart from '@/features/ShopAnalytics/components/Common/noDataChart.vue'
import {
  downloadMonthlyVisitRateCSV,
  downloadVisitCountChartCSV,
  getMonthlyVisitRate
} from '@/features/ChainCompare/visitor/axios'
import LineChart from '@/features/StoreCompare/visitor/components/LineChart.vue'
import { COMPARE_PARTICLE_TYPE } from '@/features/StoreCompare/visitor/enum'
import { computed, ref, watch } from 'vue'
import { convertSlashDelimiter } from '@/commons/utils/dateUtil'
import { MonthlyVisitRateResponse } from '../types'
import { VisitCountGraph } from '@/features/ChainAnalytics/visitor/interfaces/response'
import { processChart } from '../utils/process'
import { processMonthlyVisitRateChart } from '../utils/process'
import { Chain } from '@/commons/interfaces'
import { getVisitCountGraph } from '@/features/ChainAnalytics/visitor/axios'
import { SUB_TYPE } from '@/commons/enums'
import * as notify from '@/plugins/notification'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { isEqualSorted } from '@/commons/utils/chain'

const route = useRoute()
const router = useRouter()
/* --------------------------------------------------------------------------
  props
 ---------------------------------------------------------------------------*/

const props = withDefaults(
  defineProps<{
    startDate: string,
    endDate: string,
    selectedChainIds?: string[]
    selectedPrefectureIds?: number[]
  }>(),
  {
    selectedChainIds: (): string[] => [],
    selectedPrefectureIds: (): number[] => []
  }
)

/* --------------------------------------------------------------------------
  Vuex
 ---------------------------------------------------------------------------*/

const store = useStore()
const startDate = computed<string>(() => store.state.startDate)
const startDateOfWeek = computed<string>(() => store.getters.startDateOfWeek)
const startDateOfMonth = computed<string>(() => store.getters.startDateOfMonth)
const endDate = computed<string>(() => store.state.endDate)
const endDateOfWeek = computed<string>(() => store.getters.endDateOfWeek)
const endDateOfMonth = computed<string>(() => store.getters.endDateOfMonth)
const chainMap = computed<Map<string, Chain>>(() => store.getters.chainMap)
const chainIdToColorMap = computed<Map<string, string>>(() => store.state.chainIdToColorMap)

/* --------------------------------------------------------------------------
  core
 ---------------------------------------------------------------------------*/

const QUERY_UNIT = {
  DATE: 'date',
  WEEK: 'week',
  MONTH: 'month',
  MONTHLY_RATE: 'monthly_rate'
} as const

const selectedChains = computed<(Chain & { color: string })[]>(() =>
  props.selectedChainIds.reduce(
    (acc: (Chain & { color: string })[], id: string): (Chain & { color: string })[] => {
      const chain = chainMap.value.get(id)
      if (!chain) return acc
      return [...acc, { ...chain, color: chainIdToColorMap.value.get(id) ?? '#AAA' }]
    },
    []
  )
)

const selectedChainColors = computed<string[]>(() =>
  selectedChains.value
    .slice()
    .sort((a, b) => a.orderIndex - b.orderIndex)
    .map(({ color }) => color)
)

const selectedChainsLegends = computed<{ text: string; color: string }[]>(() =>
  selectedChains.value.map((chain) => ({ text: chain.name, color: chain.color }))
)

const particle = ref<(typeof COMPARE_PARTICLE_TYPE)[keyof typeof COMPARE_PARTICLE_TYPE]>(
  COMPARE_PARTICLE_TYPE.DATE
)
const chartLoading = ref<boolean>(true)

const chartResponses = ref<
  Map<{ orderIndex: number; chainName: string; color: string }, VisitCountGraph>
>(new Map())

const monthlyRateChartResponse = ref<MonthlyVisitRateResponse | undefined>(undefined)

const chartData = computed<(string | number | object | undefined | null)[]>(() => {
  // 月別比率
  if (particle.value === COMPARE_PARTICLE_TYPE.MONTHLY_RATE) {
    if (!monthlyRateChartResponse.value) return []
    return processMonthlyVisitRateChart(
      monthlyRateChartResponse.value,
      start.value,
      end.value,
      selectedChains.value
    )
  }

  // 日・週・月
  if (chartResponses.value.size === 0) return []
  return processChart(
    Array.from(chartResponses.value).map(([{ orderIndex, chainName, color }, { visitCounts }]) => ({
      orderIndex,
      chainName,
      color,
      visitCounts
    })),
    particle.value
  )
})

const chartAlert = computed<boolean>(() => {
  if (particle.value === COMPARE_PARTICLE_TYPE.MONTHLY_RATE) {
    if (!monthlyRateChartResponse.value) return false
    return monthlyRateChartResponse.value.comparisonGroups.some(({ isAlert }) => isAlert)
  }

  if (chartResponses.value.size === 0) return false
  return Array.from(chartResponses.value.values()).some(({ visitCounts }) =>
    visitCounts.chartItems.some(({ isAlert }) => isAlert)
  )
})

const start = computed<string>(() => {
  switch (particle.value) {
    case COMPARE_PARTICLE_TYPE.DATE:
      return startDate.value
    case COMPARE_PARTICLE_TYPE.WEEK:
      return startDateOfWeek.value
    case COMPARE_PARTICLE_TYPE.MONTH:
      return startDateOfMonth.value
    case COMPARE_PARTICLE_TYPE.MONTHLY_RATE:
      return startDateOfMonth.value
    default:
      return startDate.value
  }
})

const end = computed<string>(() => {
  switch (particle.value) {
    case COMPARE_PARTICLE_TYPE.DATE:
      return endDate.value
    case COMPARE_PARTICLE_TYPE.WEEK:
      return endDateOfWeek.value
    case COMPARE_PARTICLE_TYPE.MONTH:
      return endDateOfMonth.value
    case COMPARE_PARTICLE_TYPE.MONTHLY_RATE:
      return endDateOfMonth.value
    default:
      return endDate.value
  }
})

const fetchChart = async () => {
  if (
    !props.selectedChainIds.length ||
    !props.selectedPrefectureIds.length ||
    chainIdToColorMap.value.size !== props.selectedChainIds.length
  )
    return

  if (particle.value === COMPARE_PARTICLE_TYPE.MONTHLY_RATE) {
    try {
      chartLoading.value = true
      monthlyRateChartResponse.value = await getMonthlyVisitRate(
        props.selectedChainIds,
        props.selectedPrefectureIds.map((id) => id.toString()),
        start.value,
        end.value
      ).then((res) => res.data)
    } catch {
      notify.notifyErrorMessage('来店人数推移表が表示できませんでした。')
    } finally {
      chartLoading.value = false
    }
  } else {
    const fetchings = selectedChains.value.map((chain) =>
      getVisitCountGraph(
        chain.id,
        start.value,
        end.value,
        particle.value,
        SUB_TYPE['NONE'],
        props.selectedPrefectureIds
      ).then((res) => {
        chartResponses.value.set(
          { orderIndex: chain.orderIndex, chainName: chain.name, color: chain.color },
          res.data
        )
      })
    )

    try {
      chartLoading.value = true
      chartResponses.value.clear()
      await Promise.all(fetchings)
    } catch {
      notify.notifyErrorMessage('来店人数推移表が表示できませんでした。')
    } finally {
      chartLoading.value = false
    }
  }
}

const isSelectedChainAndPrefecture = () => {
  return props.selectedChainIds.length > 0 && props.selectedPrefectureIds.length > 0
}

const getVisitCountFileId = () => {
  if (particle.value === COMPARE_PARTICLE_TYPE.MONTHLY_RATE) {
    return downloadMonthlyVisitRateCSV(
      props.selectedChainIds,
      props.selectedPrefectureIds.map((id) => id.toString()),
      start.value,
      end.value
    )
  }

  return downloadVisitCountChartCSV(
    props.selectedChainIds,
    props.selectedPrefectureIds.map((id) => id.toString()),
    particle.value,
    start.value,
    end.value
  )
}

/* --------------------------------------------------------------------------
  created
 ---------------------------------------------------------------------------*/

if (route.query.unit) {
  if (route.query.unit === QUERY_UNIT.DATE) {
    particle.value = COMPARE_PARTICLE_TYPE.DATE
  } else if (route.query.unit === QUERY_UNIT.WEEK) {
    particle.value = COMPARE_PARTICLE_TYPE.WEEK
  } else if (route.query.unit === QUERY_UNIT.MONTH) {
    particle.value = COMPARE_PARTICLE_TYPE.MONTH
  } else if (route.query.unit === QUERY_UNIT.MONTHLY_RATE) {
    particle.value = COMPARE_PARTICLE_TYPE.MONTHLY_RATE
  }
}
fetchChart()

/* --------------------------------------------------------------------------
  watch
 ---------------------------------------------------------------------------*/

watch(
  () => [props.selectedChainIds, props.selectedPrefectureIds, props.startDate, props.endDate],
  (newVal, oldVal) => {
    if (isSelectedChainAndPrefecture() && !isEqualSorted(newVal, oldVal)) {
      fetchChart()
    }
  },
  { deep: true }
)

watch(particle, () => {
  router.replace({
    name: 'ChainCompareVisitor',
    query: {
      ...route.query,
      unit: (() => {
        if (particle.value === COMPARE_PARTICLE_TYPE.WEEK) return QUERY_UNIT.WEEK
        if (particle.value === COMPARE_PARTICLE_TYPE.MONTH) return QUERY_UNIT.MONTH
        if (particle.value === COMPARE_PARTICLE_TYPE.MONTHLY_RATE) return QUERY_UNIT.MONTHLY_RATE
        return QUERY_UNIT.DATE
      })()
    }
  })
  fetchChart()
})

watch(chainIdToColorMap, () => {
  if (isSelectedChainAndPrefecture()) {
    fetchChart()
  }
})
</script>

<style lang="scss" scoped>
.v-btn {
  color: rgba(0, 0, 0, 0.87) !important;
  background: #f5f5f5 !important;
  opacity: 0.8 !important;
}
.v-btn--active {
  color: #fff !important;
  background: #0e182e !important;
  opacity: 1 !important;
}
.download-button {
  display: flex;
  align-items: center;
  margin-left: 29.4px;
}
.mr-27px {
  margin-right: 27px;
}
</style>
