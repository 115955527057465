<template>
  <v-card class="card" tile :width="'100%'">
    <v-card-title class="pa-0 d-flex align-center card-title">
      <span>選択したエリアの方がよく行く店舗 TOP10</span>
      <v-spacer />
      <chart-description-tooltip
        menu-key="trend"
        sub-menu-key="popularStore"
        chart-key="topTenChartMap"
        class="mr-7"
      />
      <download-button
        label="CSVデータ"
        :disabled="!rankingChartData || rankingChartData.length === 0"
        :get-file-id="getRankingFileId"
        :csv-name="csvName"
      />
    </v-card-title>
    <div class="type-selector">
      <label class="selector-head">業種で絞り込む：</label>
      <form>
        <div v-if="hasSuperMarket" class="selector">
          <input
            id="supermarket"
            v-model="storeTypes"
            class="checkbox"
            type="checkbox"
            :value="STORE_TYPE.SUPER_MARKET.value"
            :disabled="
              chartLoading ||
              (storeTypes.length <= 1 && storeTypes.includes(STORE_TYPE.SUPER_MARKET.value))
            "
          />
          <label for="supermarket">スーパー</label>
        </div>
        <div v-if="hasDrugStore" class="selector">
          <input
            id="drugstore"
            v-model="storeTypes"
            class="checkbox"
            type="checkbox"
            :value="STORE_TYPE.DRUG_STORE.value"
            :disabled="
              chartLoading ||
              (storeTypes.length <= 1 && storeTypes.includes(STORE_TYPE.DRUG_STORE.value))
            "
          />
          <label for="drugstore">ドラッグストア</label>
        </div>
        <div v-if="hasHomeCenter" class="selector">
          <input
            id="homecenter"
            v-model="storeTypes"
            class="checkbox"
            type="checkbox"
            :value="STORE_TYPE.HOME_CENTER.value"
            :disabled="
              chartLoading ||
              (storeTypes.length <= 1 && storeTypes.includes(STORE_TYPE.HOME_CENTER.value))
            "
          />
          <label for="homecenter">ホームセンター</label>
        </div>
        <div v-if="hasCvsStore" class="selector">
          <input
            id="cvsstore"
            v-model="storeTypes"
            class="checkbox"
            type="checkbox"
            :value="STORE_TYPE.CVS_STORE.value"
            :disabled="
              chartLoading ||
              (storeTypes.length <= 1 && storeTypes.includes(STORE_TYPE.CVS_STORE.value))
            "
          />
          <label for="cvsstore">コンビニ</label>
        </div>
      </form>
    </div>
    <v-row class="justify-center">
      <v-col class="py-0">
        <div v-if="unselect" class="unselected_card">
          「分析する」をクリックするとチャートが表示されます。
        </div>
        <div v-else>
          <LoadingImg v-if="props.areaId?.prefectureIds && chartLoading" :height="'330px'" />
          <div v-else-if="emptyChartData" class="unselected_card">
            <p>このエリアのデータが不足しているため、トップ10全てを表示することができません。</p>
          </div>
          <div v-else-if="isAlert" class="unselected_card">
            <p>
              選択した地域は世帯数が少ないため、プライバシーに配慮し非表示としています。
              分析をするには選択エリアを広げてください。
            </p>
          </div>
          <trend-ranking-chart v-else :chart-items="rankingChartData" />
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <trend-map
          :stores="rankingChartData"
          :features="mapFeatures"
          :loading="mapLoading"
          :area-id="props.areaId"
          :is-privacy-alert="isAlert"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import LoadingImg from '@/commons/components/loadingImg.vue'
import TrendRankingChart from '@/features/Trend/TrendStore/components/TrendRankingChart.vue'
import TrendMap from '@/features/Trend/TrendStore/components/TrendMap.vue'

import { STORE_TYPE } from '@/commons/enums'
import { TREND_TYPE } from '@/features/Trend/enums'
import * as notify from '@/plugins/notification'

const props = withDefaults(
  defineProps<{
    areaId?: {
      prefectureIds: string[]
      cityIds: string[] | undefined
      townIds: string[] | undefined
    }
    period?: string
  }>(),
  {
    areaId: undefined,
    period: undefined
  }
)

const unselect = computed<boolean>(() => {
  return !props.areaId || props.areaId?.prefectureIds.length === 0
})

import { useStore } from 'vuex'
const store = useStore()
type StateStoreType = { id: number; name: string }
const storeTypes = ref<number[]>(
  store.state.storeTypes.map((storetype: StateStoreType) => storetype.id)
)
const hasSuperMarket = computed<boolean>(() => {
  return store.state.storeTypes.some(
    (storeType: StateStoreType) => storeType.id === STORE_TYPE.SUPER_MARKET.value
  )
})
const hasDrugStore = computed<boolean>(() => {
  return store.state.storeTypes.some(
    (storeType: StateStoreType) => storeType.id === STORE_TYPE.DRUG_STORE.value
  )
})
const hasHomeCenter = computed<boolean>(() => {
  return store.state.storeTypes.some(
    (storeType: StateStoreType) => storeType.id === STORE_TYPE.HOME_CENTER.value
  )
})
const hasCvsStore = computed<boolean>(() => {
  return store.state.storeTypes.some(
    (storeType: StateStoreType) => storeType.id === STORE_TYPE.CVS_STORE.value
  )
})

import {
  TrendStoreChartResponse,
  ResponseItem,
  TrendStoreChartItem,
  TrendMapFeature
} from '@/features/Trend/TrendStore/interfaces'
import {
  getTrendStoresRanking,
  downloadTrendStoresRanking
} from '@/features/Trend/TrendStore/axios'
import { AxiosResponse } from 'axios'
import { convertSlashDelimiter } from '@/commons/utils/dateUtil'

// ranking
const rankingChartData = ref<TrendStoreChartItem[]>([])
const chartLoading = ref<boolean>(false)
const emptyChartData = computed<boolean>(() => {
  if (!props.areaId) return false
  return props.areaId && (!rankingChartData.value || rankingChartData.value.length === 0)
})
const isAlert = ref<boolean>(false)
const getRankingFileId = async () => {
  if (!props.areaId || !props.period) return
  return downloadTrendStoresRanking(
    props.areaId?.prefectureIds,
    props.areaId?.cityIds,
    props.areaId?.townIds,
    storeTypes.value,
    props.period,
    TREND_TYPE.STORE
  )
}
const csvName = computed<string>(() => {
  return `トレンド_よく行く店舗TOP10_${convertSlashDelimiter(props.period)}`
})
function fetchRankingData() {
  if (!props.areaId || !props.period) return
  chartLoading.value = true
  rankingChartData.value.splice(0, 0)

  getTrendStoresRanking(
    props.areaId.prefectureIds,
    props.areaId.cityIds,
    props.areaId.townIds,
    storeTypes.value,
    props.period,
    TREND_TYPE.STORE
  )
    .then((res: AxiosResponse<TrendStoreChartResponse>) => {
      rankingChartData.value = res.data.ranking.filter(
        (data: ResponseItem) => data.currentRank !== null
      ) as TrendStoreChartItem[]
      isAlert.value = res.data.isAlert
    })
    .catch(() => notify.notifyErrorMessage('よく行く店舗TOP10が表示出来ませんでした。'))
    .finally(() => (chartLoading.value = false))
}
// map
import { Feature } from '@/commons/types/GeoJSON'
const mapFeatures = ref<TrendMapFeature[]>([])
const mapLoading = computed<boolean>(() => store.state.geoLoading)
const prefecturesGeo = computed<TrendMapFeature[]>(() => {
  return store.state.prefecturesGeo.features.map((feature: Feature) => {
    return {
      id: String(feature.id),
      type: feature.type,
      geometry: feature.geometry
    }
  })
})
const citiesGeo = computed<TrendMapFeature[]>(() => {
  return store.state.citiesGeo.features
    .filter((feature: Feature) => props.areaId?.cityIds?.includes(feature.properties.id))
    .map((feature: Feature) => {
      return {
        id: String(feature.id),
        type: feature.type,
        geometry: feature.geometry
      }
    })
})
const townsGeo = computed<TrendMapFeature[]>(() => {
  return store.state.townsGeo.features
    .filter((feature: Feature) => props.areaId?.townIds?.includes(feature.properties.id))
    .map((feature: Feature) => {
      return {
        id: String(feature.id),
        type: feature.type,
        geometry: feature.geometry
      }
    })
})
async function fetchMapData() {
  if (!props.areaId || !props.period) return
  mapFeatures.value.splice(0, 0)

  if (props.areaId.townIds && props.areaId.townIds.length >= 1) {
    if (!townsGeo.value || townsGeo.value.length === 0)
      await store.dispatch('fetchTownsGeo', props.areaId.prefectureIds[0].toString())
    mapFeatures.value = townsGeo.value
  } else if (props.areaId.cityIds && props.areaId.cityIds.length >= 1) {
    if (!citiesGeo.value || citiesGeo.value.length === 0)
      await store.dispatch('fetchCitiesGeo', props.areaId.prefectureIds[0].toString())
    mapFeatures.value = citiesGeo.value
  } else if (props.areaId.prefectureIds.length >= 1) {
    if (!prefecturesGeo.value || prefecturesGeo.value.length === 0)
      await store.dispatch('fetchPrefecturesGeo', props.areaId.prefectureIds[0].toString())
    mapFeatures.value = prefecturesGeo.value
  }
}

import { useRoute, useRouter } from 'vue-router'
const route = useRoute(),
  router = useRouter()
function fetchData() {
  if (!props.areaId || !props.areaId.prefectureIds) return
  router.push({
    query: {
      ...(storeTypes.value ? { storeTypes: storeTypes.value } : {}),
      ...(props.period ? { period: props.period } : {}),
      ...(props.areaId?.prefectureIds ? { prefectureIds: props.areaId.prefectureIds } : {}),
      ...(props.areaId?.cityIds ? { cityIds: props.areaId.cityIds } : {}),
      ...(props.areaId?.townIds ? { townIds: props.areaId.townIds } : {})
    }
  })
  fetchRankingData()
  fetchMapData()
}
watch(storeTypes, () => {
  fetchData()
})
watch(
  () => props,
  () => {
    storeTypes.value = store.state.storeTypes.map((storetype: StateStoreType) => storetype.id)
    fetchData()
  },
  {
    deep: true
  }
)

if (!store.state.storeTypes.length) {
  store.dispatch('fetchStoreTypes').then(() => {
    if (typeof route.query.storeTypes === 'string') {
      storeTypes.value = [Number(route.query.storeTypes)]
    } else if (Array.isArray(route.query.storeTypes)) {
      storeTypes.value = route.query.storeTypes.map((a) => Number(a))
    } else
      storeTypes.value = store.state.storeTypes.map((storetype: StateStoreType) => storetype.id)
  })
}
</script>

<style lang="scss" scoped>
.type-selector {
  height: 44px;
  background: #f6f6f6 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  align-items: center;
  padding: 13px 20px;
  margin-bottom: 20px;

  .selector-head {
    margin-right: 12px;
    font: normal normal bold 13px/20px Noto Sans JP;
    cursor: default;
  }

  form {
    display: flex;

    .selector {
      margin-right: 25px;
      text-align: center;
      display: flex;
      align-items: center;

      input {
        height: 16px;
        width: 16px;
        margin-right: 13px;
        accent-color: #4d99d0;
        cursor: pointer;
      }
      label {
        cursor: pointer;
        font: normal normal normal 14px/20px Noto Sans JP;
        letter-spacing: 0px;
        color: #000000;
      }
    }
  }

  .checkbox {
    margin-top: 2px;
  }
}
</style>
@/commons/types/GeoJSON@/commons/types/GeoJSON
