<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row
      class="title-container"
      dense
      no-gutters
    >
      <v-col style="display: flex; align-items: center">
        <h1 class="title-text">
          曜日／時間別人数
        </h1>
        <update-badge
          type="week"
          style="margin-left: 15px"
        />
      </v-col>
      <v-spacer />
      <date-picker
        :start-date="$store.state.startDate"
        :end-date="$store.state.endDate"
        :compare-start-date="$store.state.compareStartDate"
        :compare-end-date="$store.state.compareEndDate"
        @update-period="updatePeriod"
      />
    </v-row>
    <v-row
      dense
      no-gutters
      class="card-container"
    >
      <v-col>
        <store-selector :loading="storeLoading" />
      </v-col>
    </v-row>
    <v-row
      dense
      no-gutters
      class="card-container"
    >
      <v-col class="pb-0">
        <v-card
          height="'100%'"
          class="card"
        >
          <v-card-title
            :class="
              !selectedStore || emptyHeatMapData
                ? 'card-title d-flex align-center pa-0'
                : 'card-title d-flex align-center pa-0 mb-0'
            "
          >
            <span>曜日／時間別ヒートマップ</span>
            <alert-tooltip
              v-if="
                hasAlertInHeatmapData ||
                  hasAlertInComparisonHeatmapData
              "
              class="ml-2"
              text="取得データボリュームが少なく、統計上の信頼性の低いデータが含まれています。該当箇所は、参考値としてご参照ください。"
            />
            <v-spacer />
            <chart-description-tooltip
              menu-key="analytics"
              sub-menu-key="trendVisitor"
              chart-key="timeDayHeatmap"
              class="mr-27px"
            />
            <download-button
              label="CSVデータ"
              :disabled="!selectedStore"
              :get-file-id="getHeatmapFileId"
              :csv-name="heatmapCSVName"
            />
          </v-card-title>
          <v-container
            fluid
            class="pa-0"
          >
            <div
              v-show="!selectedStore"
              class="unselected_card"
            >
              店舗を選択するとチャートが表示されます。
            </div>
            <div v-show="selectedStore">
              <loadingImg
                v-if="heatmapLoading"
                :height="'600px'"
              />
              <no-data-chart v-else-if="emptyHeatMapData" />
              <div v-else>
                <v-row
                  v-if="isComparison"
                  dense
                >
                  <v-col>
                    <heatmap-chart
                      :heatmap="heatmapData"
                      :legend="`● ${$store.state.startDate} - ${$store.state.endDate}`"
                      :is-alert="hasAlertInHeatmapData"
                    />
                  </v-col>
                  <v-col>
                    <heatmap-chart
                      :heatmap="comparisonHeatmapData"
                      :legend="`●
                      ${$store.state.compareStartDate} -
                      ${$store.state.compareEndDate}`"
                      :is-alert="hasAlertInComparisonHeatmapData"
                    />
                  </v-col>
                </v-row>
                <heatmap-chart
                  v-else
                  :heatmap="heatmapData"
                  :is-alert="hasAlertInHeatmapData"
                />
              </div>
            </div>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      dense
      no-gutters
      class="card-container"
    >
      <v-col>
        <v-card
          height="'650px'"
          class="card"
        >
          <v-card-title class="pa-0 card-title d-flex align-center">
            <span>曜日別の平均人数</span>
            <alert-tooltip
              v-if="
                hasAlertInDayChartData ||
                  hasAlertInComparisonDayChartData
              "
              class="ml-2"
              text="取得データボリュームが少なく、統計上の信頼性の低いデータが含まれています。該当箇所は、参考値としてご参照ください。"
            />
            <v-spacer />
            <chart-description-tooltip
              menu-key="analytics"
              sub-menu-key="trendVisitor"
              chart-key="dayOfWeekAverage"
              class="mr-27px"
            />
            <download-button
              label="CSVデータ"
              :disabled="!selectedStore"
              :get-file-id="getDayChartFileId"
              :csv-name="dayChartCSVName"
            />
          </v-card-title>
          <v-container
            class="pa-0"
            fluid
          >
            <v-row
              class="my-0"
              dense
            >
              <v-col class="py-0">
                <div
                  v-show="!selectedStore"
                  class="unselected_card"
                >
                  店舗を選択するとチャートが表示されます。
                </div>
                <div v-show="selectedStore">
                  <loadingImg
                    v-if="chartLoading"
                    :height="'550px'"
                  />
                  <no-data-chart v-else-if="isNoDayChartData" />
                  <div v-else>
                    <column-chart
                      v-show="!isComparison"
                      :chart-data="dayChartData"
                      :legends="dayChartLegends"
                      :is-alert="hasAlertInDayChartData"
                      :is-stacked="true"
                    />
                    <column-chart
                      v-show="isComparison"
                      :chart-data="comparisonDayChartData"
                      :legends="comparisonDayChartLegends"
                      :is-alert="hasAlertInComparisonDayChartData"
                      :is-stacked="false"
                    />
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      dense
      no-gutters
      class="card-container mb-0"
    >
      <v-col>
        <v-card
          height="'650px'"
          class="card"
        >
          <v-card-title class="pa-0 card-title d-flex align-center">
            <span>時間別の平均人数</span>
            <alert-tooltip
              v-if="
                hasAlertInTimeChartData ||
                  hasAlertInComparisonTimeChartData
              "
              class="ml-2"
              text="取得データボリュームが少なく、統計上の信頼性の低いデータが含まれています。該当箇所は、参考値としてご参照ください。"
            />
            <v-spacer />
            <chart-description-tooltip
              menu-key="analytics"
              sub-menu-key="trendVisitor"
              chart-key="timeOfDayAverage"
              class="mr-27px"
            />
            <download-button
              label="CSVデータ"
              :disabled="!selectedStore"
              :get-file-id="getTimeChartFileId"
              :csv-name="timeChartCSVName"
            />
          </v-card-title>
          <v-container
            class="pa-0"
            fluid
          >
            <v-row
              class="my-0"
              dense
            >
              <v-col class="py-0">
                <div
                  v-show="!selectedStore"
                  class="unselected_card"
                >
                  店舗を選択するとチャートが表示されます。
                </div>
                <div v-show="selectedStore">
                  <loadingImg
                    v-if="chartLoading"
                    :height="'550px'"
                  />
                  <no-data-chart
                    v-else-if="isNoTimeChartData && !chartLoading"
                  />
                  <v-row v-else-if="isComparison">
                    <v-col cols="6">
                      <column-chart
                        :chart-data="timeChartData"
                        :legends="timeChartLegends"
                        :is-alert="hasAlertInTimeChartData"
                        :is-stacked="false"
                        :colors="timeChartColors"
                      />
                    </v-col>
                    <v-col cols="6">
                      <column-chart
                        :chart-data="comparisonTimeChartData"
                        :legends="comparisonTimeChartLegends"
                        :is-alert="hasAlertInComparisonTimeChartData"
                        :is-stacked="false"
                        :colors="timeChartColors"
                      />
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col cols="12">
                      <column-chart
                        :chart-data="timeChartData"
                        :legends="timeChartLegends"
                        :is-alert="hasAlertInTimeChartData"
                        :is-stacked="false"
                        :colors="timeChartColors"
                      />
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DatePicker from "@/features/ShopAnalytics/components/Common/datePicker.vue";
import StoreSelector from "@/commons/components/StoreSelector/StoreSelector.vue";
import ColumnChart from "@/features/ShopAnalytics/components/Common/columnChart.vue";
import HeatmapChart from "@/features/ShopAnalytics/components/TrendVisitor/heatmapChart.vue";
import UpdateBadge from "@/features/ShopAnalytics/components/Common/updateBadge.vue";
import NoDataChart from "@/features/ShopAnalytics/components/Common/noDataChart.vue";
// util
import * as notify from "@/plugins/notification";
import {
  processChartData,
  processComparisonChartData,
  getLegends,
} from "@/features/ShopAnalytics/utils/trendVisitor";
import {
  convertHyphenDelimiter,
  convertSlashDelimiter,
} from "@/commons/utils/dateUtil";
import { getPeriodByRouterQueryPeriod } from "@/commons/utils";
// interface
import { AxiosResponse } from "axios";
import {
  WEEK,
  VisitCountGraph,
  WeekHourHeatMapResponse,
  WeekHourHeatMapItem,
} from "@/features/ShopAnalytics/interfaces/response";
import { Store } from "@/commons/interfaces/responses/store";
// axios
import {
  getVisitCountGraph,
  downloadVisitCountCsv,
  downloadVisitCountComparisonCsv,
} from "@/features/ShopAnalytics/axios/visitor";
import {
  getWeekHourHeatMap,
  downloadWeekHourHeatMapCsv,
  downloadComparisonWeekHourHeatMapCsv,
} from "@/features/ShopAnalytics/axios/trendVisitor";
// enum
import { COLOR, PARTICLE_TYPE } from "@/commons/enums";
import LoadingImg from "@/commons/components/loadingImg.vue";

export default defineComponent({
  name: "TrendVisitorView",
  components: {
    LoadingImg,
    DatePicker,
    StoreSelector,
    ColumnChart,
    HeatmapChart,
    UpdateBadge,
    NoDataChart,
  },
  data() {
    return {
      storeLoading: false,
      created: false,
      dayChartData: [] as (string | number)[][],
      timeChartData: [] as (string | number)[][],
      comparisonDayChartData: [] as (string | number)[][],
      comparisonTimeChartData: [] as (string | number)[][],
      particle: 0 as 1 | 2 | 3 | 4 | 5,
      chartLoading: false,
      heatmapData: [] as WeekHourHeatMapItem[],
      comparisonHeatmapData: [] as WeekHourHeatMapItem[],
      heatmapLoading: false,
      timeChartColors: [COLOR.BLUE, COLOR.RED],
    };
  },
  computed: {
    selectedStore(): Store | null {
      return this.$store.state.selectedStore;
    },
    isComparison(): boolean {
      return (
        this.$store.state.compareStartDate.length !== 0 &&
        this.$store.state.compareEndDate.length !== 0
      );
    },
    isNoDayChartData(): boolean {
      if (this.isComparison) {
        return this.comparisonDayChartData.length <= 1;
      } else {
        return this.dayChartData.length <= 1;
      }
    },
    isNoTimeChartData(): boolean {
      if (this.isComparison) {
        return this.comparisonTimeChartData.length <= 1;
      } else {
        return this.timeChartData.length <= 1;
      }
    },
    hasAlertInHeatmapData(): boolean {
      return this.heatmapData.some((value) => value.alerts?.length > 0);
    },
    hasAlertInComparisonHeatmapData(): boolean {
      return this.comparisonHeatmapData.some(
        (value) => value.alerts?.length > 0
      );
    },
    hasAlertInDayChartData(): boolean {
      if (this.dayChartData.length === 0) return false;
      return this.dayChartData.some((value) => value[3] === "#bbbbbb");
    },
    hasAlertInComparisonDayChartData(): boolean {
      if (this.comparisonDayChartData.length === 0) return false;
      return this.comparisonDayChartData.some(
        (value) => value[3] === "#bbbbbb" || value[6] === "#bbbbbb"
      );
    },
    hasAlertInTimeChartData(): boolean {
      if (this.timeChartData.length === 0) return false;
      return this.timeChartData.some(
        (value) => value[3] === "#bbbbbb" || value[6] === "#bbbbbb"
      );
    },
    hasAlertInComparisonTimeChartData(): boolean {
      if (this.comparisonTimeChartData.length === 0) return false;
      return this.comparisonTimeChartData.some(
        (value) => value[3] === "#bbbbbb" || value[6] === "#bbbbbb"
      );
    },
    hasRouterQueryPeriod(): boolean {
      return this.$route.query.period !== undefined;
    },
    hasRouterQueryComparePeriod(): boolean {
      return this.$route.query.c_period !== undefined;
    },
    dayChartLegends(): { text: string; color: string }[] {
      return getLegends(this.dayChartData, this.isComparison);
    },
    timeChartLegends(): { text: string; color: string }[] {
      return getLegends(this.timeChartData, this.isComparison, 4);
    },
    comparisonDayChartLegends(): { text: string; color: string }[] {
      return getLegends(this.comparisonDayChartData, this.isComparison);
    },
    comparisonTimeChartLegends(): { text: string; color: string }[] {
      return getLegends(this.comparisonTimeChartData, this.isComparison, 4);
    },
    emptyHeatMapData(): boolean {
      return this.heatmapData.length === 0;
    },
    getHeatmapFileId(): () => Promise<AxiosResponse<any, any>> | undefined {
      return () => {
        if (!this.isComparison)
          return downloadWeekHourHeatMapCsv(
            this.selectedStore?.storeId,
            this.$store.state.startDate,
            this.$store.state.endDate
          );
        else
          return downloadComparisonWeekHourHeatMapCsv(
            this.selectedStore?.storeId,
            [
              {
                start: this.$store.state.startDate,
                end: this.$store.state.endDate,
              },
              {
                start: this.$store.state.compareStartDate,
                end: this.$store.state.compareEndDate,
              },
            ]
          );
      };
    },
    getDayChartFileId(): () => Promise<AxiosResponse<any, any>> {
      return () => {
        if (!this.isComparison)
          return downloadVisitCountCsv(
            this.selectedStore?.storeId,
            this.$store.state.startDate,
            this.$store.state.endDate,
            PARTICLE_TYPE.DAY
          );
        else
          return downloadVisitCountComparisonCsv(
            this.selectedStore?.storeId,
            [
              {
                start: this.$store.state.startDate,
                end: this.$store.state.endDate,
              },
              {
                start: this.$store.state.compareStartDate,
                end: this.$store.state.compareEndDate,
              },
            ],
            PARTICLE_TYPE.DAY
          );
      };
    },
    getTimeChartFileId(): () => Promise<AxiosResponse<any, any>> {
      return () => {
        if (!this.isComparison)
          return downloadVisitCountCsv(
            this.selectedStore?.storeId,
            this.$store.state.startDate,
            this.$store.state.endDate,
            PARTICLE_TYPE.HOUR
          );
        else
          return downloadVisitCountComparisonCsv(
            this.selectedStore?.storeId,
            [
              {
                start: this.$store.state.startDate,
                end: this.$store.state.endDate,
              },
              {
                start: this.$store.state.compareStartDate,
                end: this.$store.state.compareEndDate,
              },
            ],
            PARTICLE_TYPE.HOUR
          );
      };
    },
    heatmapCSVName(): string {
      const { start, end, compareStart, compareEnd } = {
        start: convertSlashDelimiter(this.$store.state.startDate),
        end: convertSlashDelimiter(this.$store.state.endDate),
        compareStart: convertSlashDelimiter(this.$store.state.compareStartDate),
        compareEnd: convertSlashDelimiter(this.$store.state.compareEndDate),
      };
      if (!this.isComparison) return `曜日／時間別ヒートマップ_${start}-${end}`;
      else
        return `店舗分析_曜日／時間別ヒートマップ_${start}-${end}_${compareStart}-${compareEnd}`;
    },
    dayChartCSVName(): string {
      const { start, end, compareStart, compareEnd } = {
        start: convertSlashDelimiter(this.$store.state.startDate),
        end: convertSlashDelimiter(this.$store.state.endDate),
        compareStart: convertSlashDelimiter(this.$store.state.compareStartDate),
        compareEnd: convertSlashDelimiter(this.$store.state.compareEndDate),
      };
      if (!this.isComparison) return `曜日別の平均人数_${start}-${end}`;
      else
        return `店舗分析_曜日別の平均人数_${start}-${end}_${compareStart}-${compareEnd}`;
    },
    timeChartCSVName(): string {
      const { start, end, compareStart, compareEnd } = {
        start: convertSlashDelimiter(this.$store.state.startDate),
        end: convertSlashDelimiter(this.$store.state.endDate),
        compareStart: convertSlashDelimiter(this.$store.state.compareStartDate),
        compareEnd: convertSlashDelimiter(this.$store.state.compareEndDate),
      };
      if (!this.isComparison) return `時間別の平均人数_${start}-${end}`;
      else
        return `店舗分析_時間別の平均人数_${start}-${end}_${compareStart}-${compareEnd}`;
    },
  },
  watch: {
    async selectedStore() {
      if (this.created) {
        await this.pushRoute();
        await this.fetchAll();
      }
    },
  },
  async created() {
    // クエリパラメータからデータ更新
    this.setDateFromRouterQuery();
    this.setCompareDateFromRouterQuery();

    await this.fetchAll();
    this.created = true;
  },
  methods: {
    async fetchAll() {
      // 店舗一覧取得
      this.storeLoading = true;

      if (!this.$store.state.stores.length)
        await this.$store.dispatch("fetchStores");

      // id があれば選択店舗を設定
      if (this.$route.params["id"]) {
        await this.$store.dispatch("specifiedStore", this.$route.params["id"]);
        if (!this.selectedStore) this.$router.push({ name: "NotFound" });
      } else this.$store.commit("initStore");

      this.storeLoading = false;

      // 比較なし
      if (this.$store.state.selectedStore && !this.isComparison) {
        this.fetchChartData();
        this.fetchHeatmapData();
      }

      // 比較時
      if (this.$store.state.selectedStore && this.isComparison) {
        this.fetchComparisonChartData();
        this.fetchComparisonHeatmapData();
      }
    },
    setDateFromRouterQuery() {
      if (this.hasRouterQueryPeriod) {
        let tmp = getPeriodByRouterQueryPeriod(this.$route.query.period as string);
        if (tmp !== undefined) this.$store.commit("setDate", tmp);
      }
    },
    setCompareDateFromRouterQuery() {
      if (this.hasRouterQueryComparePeriod) {
        let tmp = getPeriodByRouterQueryPeriod(this.$route.query.c_period as string);
        if (tmp !== undefined) this.$store.commit("setCompareDate", tmp);
      }
    },
    fetchChartData() {
      this.chartLoading = true;

      // 曜日別
      getVisitCountGraph(
        this.selectedStore?.storeId,
        this.$store.state.startDate,
        this.$store.state.endDate,
        5,
        0
      )
        .then((res: AxiosResponse<VisitCountGraph>) => {
          this.dayChartData = processChartData(
            PARTICLE_TYPE["DAY"],
            res.data,
            this.$store.state.startDate,
            this.$store.state.endDate
          );
        })
        .catch(() => {
          notify.notifyErrorMessage("曜日別来店人数が表示できませんでした。");
        });
      // 時間別
      getVisitCountGraph(
        this.selectedStore?.storeId,
        this.$store.state.startDate,
        this.$store.state.endDate,
        4,
        0
      )
        .then((res: AxiosResponse<VisitCountGraph>) => {
          this.timeChartData = processChartData(
            PARTICLE_TYPE["HOUR"],
            res.data,
            this.$store.state.startDate,
            this.$store.state.endDate
          );
        })
        .catch(() => {
          notify.notifyErrorMessage("時間別来店人数が表示できませんでした。");
        });

      this.chartLoading = false;
    },
    fetchComparisonChartData() {
      this.chartLoading = true;

      // 曜日別
      const baseDayChart = getVisitCountGraph(
        this.selectedStore?.storeId,
        this.$store.state.startDate,
        this.$store.state.endDate,
        5,
        0
      );
      const comparisonDayChart = getVisitCountGraph(
        this.selectedStore?.storeId,
        this.$store.state.compareStartDate,
        this.$store.state.compareEndDate,
        5,
        0
      );
      Promise.all([baseDayChart, comparisonDayChart])
        .then((res: AxiosResponse<VisitCountGraph>[]) => {
          this.comparisonDayChartData = processComparisonChartData(
            PARTICLE_TYPE["DAY"],
            res[0].data,
            this.$store.state.startDate,
            this.$store.state.endDate,
            res[1].data,
            this.$store.state.compareStartDate,
            this.$store.state.compareEndDate
          );
        })
        .catch(() => {
          notify.notifyErrorMessage(
            "曜日別来店人数（期間比較）が表示できませんでした。"
          );
        });
      // 時間別
      const baseTimeChart = getVisitCountGraph(
        this.selectedStore?.storeId,
        this.$store.state.startDate,
        this.$store.state.endDate,
        4,
        0
      );
      const comparisonTimeChart = getVisitCountGraph(
        this.selectedStore?.storeId,
        this.$store.state.compareStartDate,
        this.$store.state.compareEndDate,
        4,
        0
      );
      Promise.all([baseTimeChart, comparisonTimeChart])
        .then((res: AxiosResponse<VisitCountGraph>[]) => {
          this.timeChartData = processChartData(
            PARTICLE_TYPE["HOUR"],
            res[0].data,
            this.$store.state.startDate,
            this.$store.state.endDate
          );
          this.comparisonTimeChartData = processChartData(
            PARTICLE_TYPE["HOUR"],
            res[1].data,
            this.$store.state.compareStartDate,
            this.$store.state.compareEndDate
          );
        })
        .catch(() => {
          notify.notifyErrorMessage(
            "時間別来店人数（期間比較）が表示できませんでした。"
          );
        });

      this.chartLoading = false;
    },
    fetchHeatmapData() {
      this.heatmapLoading = true;

      getWeekHourHeatMap(
        this.selectedStore?.storeId,
        this.$store.state.startDate,
        this.$store.state.endDate
      )
        .then((res: AxiosResponse<WeekHourHeatMapResponse>) => {
          if (this.isEmptyHeatmapData(res.data.heatmap)) {
            throw new Error("ヒートマップのデータが空です");
          }
          this.heatmapData = res.data.heatmap;
        })
        .catch(() => {
          notify.notifyErrorMessage(
            "曜日/時間別ヒートマップが表示できませんでした。"
          );
        });

      this.heatmapLoading = false;
    },
    fetchComparisonHeatmapData() {
      this.heatmapLoading = true;

      getWeekHourHeatMap(
        this.selectedStore?.storeId,
        this.$store.state.startDate,
        this.$store.state.endDate
      )
        .then((res: AxiosResponse<WeekHourHeatMapResponse>) => {
          this.heatmapData = res.data.heatmap;
        })
        .catch(() => {
          notify.notifyErrorMessage(
            "曜日/時間別ヒートマップが表示できませんでした。"
          );
        });
      getWeekHourHeatMap(
        this.selectedStore?.storeId,
        this.$store.state.compareStartDate,
        this.$store.state.compareEndDate
      )
        .then((res: AxiosResponse<WeekHourHeatMapResponse>) => {
          this.comparisonHeatmapData = res.data.heatmap;
        })
        .catch(() => {
          notify.notifyErrorMessage(
            "曜日/時間別ヒートマップが表示できませんでした。"
          );
        });

      this.heatmapLoading = false;
    },
    isEmptyHeatmapData(heatmapData: WeekHourHeatMapItem[]) {
      if (heatmapData.length === 0) return true;
      return heatmapData.some((heatmapItem) => {
        // いずれかの時間帯のデータが以下の条件に当てはまった場合、emptyとみなす
        return Object.values(WEEK).some((weekday) => {
          return (
            // 曜日のプロパティを持っていない
            Object.hasOwn(heatmapItem, weekday) === false ||
            // その曜日のaverageCountがnull
            Object(heatmapItem)[weekday]["averageCount"] === null ||
            // その曜日のaverageRatioがnull
            Object(heatmapItem)[weekday]["averageRatio"] === null
          );
        });
      });
    },

    /*
      TODO
      API が完成し次第、リアクティブにパラメータを更新した上で push するよう修正。
      また、更新するタイミングでこちらの関数を呼び出すようにすること。
    */
    async pushRoute() {
      if (!this.$store.state.selectedStore) return;

      const tmp = {
        start: convertHyphenDelimiter(this.$store.state.startDate),
        end: convertHyphenDelimiter(this.$store.state.endDate),
        compareStart: convertHyphenDelimiter(
          this.$store.state.compareStartDate
        ),
        compareEnd: convertHyphenDelimiter(this.$store.state.compareEndDate),
      };

      if (!this.$store.getters.isCompare) {
        // NOTE: 他ページに遷移後にreplaceが呼び出されると戻される対策
        if (this.$route.name === "ShopAnalyticsTrendVisitor") {
          await this.$router
            .replace({
              name: "ShopAnalyticsTrendVisitor",
              params: { id: this.$store.state.selectedStore.storeId },
              query: {
                period: `${tmp.start}_${tmp.end}`,
              },
            })
            .catch(() => undefined);
        }
      } else {
        // NOTE: 他ページに遷移後にreplaceが呼び出されると戻される対策
        if (this.$route.name === "ShopAnalyticsTrendVisitor") {
          await this.$router
            .replace({
              name: "ShopAnalyticsTrendVisitor",
              params: { id: this.$store.state.selectedStore.storeId },
              query: {
                period: `${tmp.start}_${tmp.end}`,
                c_period: `${tmp.compareStart}_${tmp.compareEnd}`,
              },
            })
            .catch(() => undefined);
        }
      }
    },

    /**
     * datePicker コンポーネントから更新する用のメソッド
     */
    async updatePeriod(period: {
      startDate: string;
      endDate: string;
      compareStartDate: string;
      compareEndDate: string;
    }) {
      this.$store.commit("setDate", {
        startDate: period.startDate,
        endDate: period.endDate,
      });
      this.$store.commit("setCompareDate", {
        startDate: period.compareStartDate,
        endDate: period.compareEndDate,
      });
      await this.pushRoute();
      await this.fetchAll();
    },
  },
});
</script>

<style lang="scss" scoped>
.mr-27px {
  margin-right: 27px;
}
</style>
