import {
  BizAreaAnalysisComparisonMapResponse,
  ComparisonMapBoxFeatures,
  ComparisonMapStore,
} from "@/features/StoreCompare/bizArea/interfaces/response";
import { ComparisonGroupStore } from "@/features/StoreCompare/types";

export function processingMapData(
  response: BizAreaAnalysisComparisonMapResponse,
  selectedStores: ComparisonGroupStore[]
): ComparisonMapBoxFeatures[] {
  if (response.features.length === 0) return [];
  return response.features.filter(
    (feature: ComparisonMapBoxFeatures) => !feature.properties.isPrivacyAlert
  ).map((feature: ComparisonMapBoxFeatures) => {
    const maxStore = feature.properties.stores.reduce(
      (previous: ComparisonMapStore, current: ComparisonMapStore) => {
        if (previous.numerator > current.numerator) return previous;
        else if (previous.numerator === current.numerator)
          return previous.index < current.index ? previous : current;
        else return current;
      }
    );
    const color = selectedStores.find(
      (store: ComparisonGroupStore) => store.id === maxStore.storeId
    )?.color;
    return {
      type: feature.type,
      properties: {
        stores: feature.properties.stores,
        areaId: feature.properties.areaId,
        areaName: feature.properties.areaName,
        latitude: feature.properties.latitude,
        longitude: feature.properties.longitude,
        isPrivacyAlert: feature.properties.isPrivacyAlert,
        maxColor: String(color),
        maxStoreName: maxStore.storeName,
        maxVisitRatio: maxStore.visitRatio,
      },
      geometry: feature.geometry,
    };
  });
}

export function getMaxRatio(
  response: BizAreaAnalysisComparisonMapResponse
): number {
  if (response.features.length === 0) return 1;
  const featuresMaxRatios = [] as number[];
  response.features.forEach((feature: ComparisonMapBoxFeatures) => {
    featuresMaxRatios.push(
      feature.properties.stores.reduce(
        (previous: ComparisonMapStore, current: ComparisonMapStore) => {
          if (previous.visitRatio > current.visitRatio) return previous;
          else return current;
        }
      ).visitRatio
    );
  });
  return featuresMaxRatios.reduce((previous: number, current: number) => {
    return Math.max(previous, current);
  });
}

export function getPrivacyAlertAreaNames(
  response: BizAreaAnalysisComparisonMapResponse
): string[] {
  if (response.features.length === 0) return [];
  const privacyAlertAreas = response.features.filter(
    (feature: ComparisonMapBoxFeatures) => feature.properties.isPrivacyAlert
  );
  return privacyAlertAreas.map(
    (feature: ComparisonMapBoxFeatures) => feature.properties.areaName
  );
}
