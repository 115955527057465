<template>
  <v-container class="pa-0" fluid>
    <v-row class="title-container" dense no-gutters>
      <v-col style="display: flex; align-items: center">
        <h1 class="title-text">ペルソナ特性</h1>
        <UpdateBadge type="week" style="margin-left: 15px" />
        <v-spacer />
        <DatePicker
          :start-date="startDate"
          :end-date="endDate"
          :compare-start-date="compareStartDate"
          :compare-end-date="compareEndDate"
          :enable-comparison="false"
          @update-period="(period) => setPeriodParams(period)"
        />
      </v-col>
    </v-row>
    <v-row dense no-gutters class="card-container">
      <v-col>
        <MultiChainSelector
          :initial-chain-ids="chainIds"
          :initial-prefecture-ids="prefectureIds.map((id) => Number(id))"
          :start-date="startDate"
          :end-date="endDate"
          :handle-update="
            (chains, prefectureIds) =>
              setParams({
                chainIds: chains.map((chain) => chain.id),
                prefectureIds: prefectureIds.map((id) => id.toString())
              })
          "
        />
      </v-col>
    </v-row>
    <v-row dense no-gutters class="card-container">
      <v-col>
        <AgeGenderCard
          :start-date="startDate"
          :end-date="endDate"
          :selected-chain-ids="chainIds"
          :selected-prefecture-ids="prefectureIds.map((id) => Number(id))"
        />
      </v-col>
    </v-row>
    <v-row dense no-gutters class="card-cotainer mb-0">
      <v-col>
        <BehavioralDNACard
          :start-date="startDate"
          :end-date="endDate"
          :selected-chain-ids="chainIds"
          :selected-prefecture-ids="prefectureIds.map((id) => Number(id))"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import MultiChainSelector from '@/commons/components/ChainSelector/MultiChainSelector.vue'
import UpdateBadge from '@/commons/components/Elements/UpdateBadge.vue'
import DatePicker from '@/features/ShopAnalytics/components/Common/datePicker.vue'
import useChainsQueryParams from '@/commons/hooks/use-chains-query-params'
import usePeriodQueryParams from '@/commons/hooks/use-period-query-params'
import AgeGenderCard from './components/AgeGenderCard.vue'
import BehavioralDNACard from './components/BehavioralDNACard.vue'
import { useStore } from 'vuex'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { getPeriodByRouterQueryPeriod } from '@/commons/utils'

/* --------------------------------------------------------------------------
  Vuex
 ---------------------------------------------------------------------------*/

const store = useStore()
const startDate = computed<string>(() => store.state.startDate)
const endDate = computed<string>(() => store.state.endDate)
const compareStartDate = computed<string>(() => store.state.compareStartDate)
const compareEndDate = computed<string>(() => store.state.compareEndDate)

/* --------------------------------------------------------------------------
  Vue Router
 ---------------------------------------------------------------------------*/

const route = useRoute()

/* --------------------------------------------------------------------------
  選択済みチェーン・都道府県
 ---------------------------------------------------------------------------*/

const { chainIds, prefectureIds, setParams } = useChainsQueryParams()
const { setPeriodParams } = usePeriodQueryParams()

/* --------------------------------------------------------------------------
  created
 ---------------------------------------------------------------------------*/

if (route.query.period) {
  const period = getPeriodByRouterQueryPeriod(route.query.period as string)
  if (period !== undefined) store.commit('setDate', period)
}
</script>
